// 지급/회수
import { useState, useEffect } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { LoginAtom } from "../Recoil/Atoms/PopupState";
import { UserAtom, LoadingAtom } from "../Recoil/Atoms/UserState";

import * as S from "../Styles/Style";
import Api from "../libs/api";
import Util from "../Store/Util";

// 이미지
import { ReactComponent as Plus } from "../Images/plus.svg";
import { ReactComponent as Search } from "../Images/search.svg";
import { ReactComponent as Trash } from "../Images/trash.svg";

interface submitData {
	[key: string]: number | string;
}

export default () => {
	const setLoading = useSetRecoilState<boolean>(LoadingAtom);
	const [searchParams, setSearchParams] = useSearchParams();
	const mmIdx = searchParams.get("mmIdx"); // 부모창에서 받아온 회원번호
	const nick = searchParams.get("nick"); // 부모창에서 받아온 닉네임

	const [division, setDivision] = useState(0); // 포인트/상자 구분
	const [action, setAction] = useState(1); // 지급 구분(회수/지급/예약 지급)
	const [submitData, setSubmitData] = useState<submitData[]>([{}]);

	useEffect(() => {
		setSubmitData([{}]);
		if (mmIdx && nick) {
			getMemberInfo("mmIdx", 0, Number(mmIdx));
		}
	}, [division]);
	// 입력한 데이터 업데이트
	const handleSubmitData = (i: number, name: string, value: string) => {
		const data = [...submitData];
		// 회원번호/닉네임/보유포인트(상자) 데이터 지우기
		if ((name == "mmIdx" || name == "nick") && value == "") {
			data[i].mmIdx = "";
			data[i].nick = "";
			data[i].userPoint = "";
			data[i].boxCnt = "";
		}
		if (name == "used") {
			// 숫자로 보내기
			data[i][name] = Util.toNumber(value);
		} else {
			data[i][name] = value;
		}
		setSubmitData(data);
	};
	// 회원번호/닉네임 으로 정보 가져오기
	const getMemberInfo = async (
		type: string,
		index: number,
		mmIdx?: number
	) => {
		let params = {};
		if (mmIdx !== undefined) {
			params = { mmIdx: mmIdx };
		} else if (
			submitData[index][type] == undefined ||
			submitData[index][type] == ""
		) {
			return;
		} else {
			params = { [type]: submitData[index][type] };
		}
		setLoading(true);
		await Api.get("member/select", params)
			.then((res: any) => {
				console.log(res);
				console.log(params);
				const datas = res.data.data[0];
				if (datas == undefined) {
					alert("해당 내용의 검색 결과가 없습니다.");
				} else {
					const data = [...submitData];
					data[index].mmIdx = datas.mmIdx;
					data[index].nick = datas.nick;
					data[index].userPoint = Util.addComma(datas.freePoint);
					data[index].boxCnt = Util.addComma(datas.boxCnt);
					setSubmitData(data);
				}
			})
			.catch((error) => {
				alert("해당 내용의 검색 결과가 없습니다.");
			});
		setLoading(false);
	};

	// 등록하기 버튼 클릭 이벤트
	const onSubmit = async (e: any) => {
		e.preventDefault();

		let url;
		let valid = true;
		let divisionText = "";

		if (division == 0) {
			url = "point/AdminPointAction";
			divisionText = "포인트";
		} else {
			url = "box/AdminBoxAction";
			divisionText = "상자";
			submitData.map((item) => {
				if (item.bmIdx == undefined) {
					valid = false;
				}
			});
		}
		if (!valid) {
			return alert("필수 항목을 모두 입력해 주세요.");
		}

		if (
			window.confirm(
				`총${submitData.length}건에 대한 ${divisionText} ${
					action == 0 ? "회수" : "지급"
				}\n\n${submitData
					.map((item) => {
						return `${item.mmIdx} / ${item.nick} / ${Util.addComma(
							Number(item.used)
						)}${division == 0 ? "P" : "개"}\n`;
					})
					.join("")}`
			)
		) {
			setLoading(true);
			let data = [...submitData];
			data.map((item) => {
				item["action"] = action;
				delete item["nick"];
				delete item["userPoint"];
				delete item["boxCnt"];
			});
			Api.post(url, { params: data })
				.then((res: any) => {
					const data = res.data;
					console.log(data);
					if (data == undefined) {
						if (res.response.data.error == -400) {
							alert(
								`회수 할 ${divisionText}가 보유 ${divisionText}를 초과합니니다.`
							);
						}
					} else if (
						data.statusCode == 200 ||
						data.statusCode == 201
					) {
						alert("완료되었습니다.");
						window.opener.location.reload();
						window.close();
					}
				})
				.catch((error) => {});
			setLoading(false);
		}
	};

	return (
		<>
			<S.popupPage>
				<h1>
					<S.headerText>지급/회수</S.headerText>
				</h1>
				<S.blank3 />
				<S.switchRadioArea style={{ margin: "0 auto" }}>
					<S.switchRadio>
						<input
							type="radio"
							name="division"
							id="division0"
							onChange={() => setDivision(0)}
							defaultChecked
						/>
						<label htmlFor="division0">포인트</label>
					</S.switchRadio>
					<S.switchRadio>
						<input
							type="radio"
							name="division"
							id="division1"
							onChange={() => setDivision(1)}
						/>
						<label htmlFor="division1">상자</label>
					</S.switchRadio>
				</S.switchRadioArea>
				{/* 테이블 영역 */}
				<S.table02>
					<tbody>
						<tr>
							<th colSpan={3}>구분</th>
						</tr>
						<tr>
							<td colSpan={3}>
								<S.normalRadioArea>
									<S.normalRadio>
										<input
											type="radio"
											name="action"
											id="action1"
											onChange={() => setAction(1)}
											defaultChecked={true}
										/>
										<label htmlFor="action1" />
									</S.normalRadio>
									<S.normalLabel htmlFor="action1">
										지급
									</S.normalLabel>
									<S.normalRadio>
										<input
											type="radio"
											name="action"
											id="action2"
											onChange={() => setAction(2)}
										/>
										<label htmlFor="action2" />
									</S.normalRadio>
									<S.normalLabel htmlFor="action2">
										예약 지급
									</S.normalLabel>
									<input
										style={{
											width: "unset",
											flexGrow: "unset",
											marginRight: "1rem",
										}}
										className="radioInput"
										type="datetime-local"
										max={"9999-12-31T12:59"}
										name=""
										id=""
									/>
									<S.normalRadio>
										<input
											type="radio"
											name="action"
											id="action0"
											onChange={() => setAction(0)}
										/>
										<label htmlFor="action0" />
									</S.normalRadio>
									<S.normalLabel htmlFor="action0">
										회수
									</S.normalLabel>
								</S.normalRadioArea>
							</td>
						</tr>
					</tbody>
				</S.table02>
				<form
					onSubmit={onSubmit}
					style={{
						flexGrow: 1,
						display: "flex",
						flexDirection: "column",
					}}>
					<div style={{ flexGrow: 1 }}>
						{submitData?.map((item, index) => {
							return (
								<S.table03 key={index}>
									<tbody>
										<tr>
											<th
												className="numbering"
												rowSpan={3}>
												{index + 1}
											</th>
											<th>회원번호*</th>
											<td>
												<div>
													<input
														type="text"
														value={
															Util.toNumber(
																String(
																	submitData[
																		index
																	].mmIdx
																)
															) ?? ""
														}
														placeholder="회원번호"
														onFocus={() =>
															handleSubmitData(
																index,
																"mmIdx",
																""
															)
														}
														onBlur={(e) =>
															getMemberInfo(
																"mmIdx",
																index
															)
														}
														onChange={(e) =>
															handleSubmitData(
																index,
																"mmIdx",
																e.target.value
															)
														}
														required
													/>
													<Search
														fill={S.color.gray03}
													/>
												</div>
											</td>
											<th>회원 닉네임*</th>
											<td>
												<div>
													<input
														type="text"
														value={
															submitData[index]
																.nick ?? ""
														}
														placeholder="회원 닉네임"
														onFocus={() =>
															handleSubmitData(
																index,
																"nick",
																""
															)
														}
														onBlur={(e) =>
															getMemberInfo(
																"nick",
																index
															)
														}
														onChange={(e) =>
															handleSubmitData(
																index,
																"nick",
																e.target.value
															)
														}
														required
													/>
													<Search
														fill={S.color.gray03}
														onClick={(e) =>
															getMemberInfo(
																"nick",
																index
															)
														}
													/>
												</div>
											</td>
											<th>
												보유{" "}
												{division == 0
													? "포인트(P)"
													: "상자(개)"}
											</th>
											<td>
												<div>
													<input
														type="text"
														value={
															division == 0
																? submitData[
																		index
																  ].userPoint ??
																  ""
																: submitData[
																		index
																  ].boxCnt
																? `골드상자 ${submitData[index].boxCnt} | 이벤트상자 0`
																: ""
														}
														placeholder={
															division == 0
																? "0"
																: "골드상자 0 | 이벤트상자 0"
														}
														readOnly
													/>
												</div>
											</td>
											<th
												className="delete"
												rowSpan={3}
												onClick={() => {
													if (submitData.length > 1) {
														let data = [
															...submitData,
														];
														data.splice(index, 1);
														setSubmitData(data);
													}
												}}>
												<Trash fill="white" />
											</th>
										</tr>
										<tr>
											{division == 1 && (
												<>
													<th>상자 종류*</th>
													<td>
														<S.normalRadioArea>
															<S.normalRadio>
																<input
																	type="radio"
																	name={`bmIdx${index}`}
																	id={`bmIdx2_${index}`}
																	value={
																		submitData[
																			index
																		]
																			.bmIdx ??
																		""
																	}
																	onChange={(
																		e
																	) =>
																		handleSubmitData(
																			index,
																			"bmIdx",
																			"2"
																		)
																	}
																/>
																<label
																	htmlFor={`bmIdx2_${index}`}
																/>
															</S.normalRadio>
															<S.normalLabel
																htmlFor={`bmIdx2_${index}`}>
																골드
															</S.normalLabel>
															<S.normalRadio>
																<input
																	type="radio"
																	name={`bmIdx${index}`}
																	id={`bmIdx1_${index}`}
																	value={
																		submitData[
																			index
																		]
																			.bmIdx ??
																		""
																	}
																	disabled
																	onChange={(
																		e
																	) =>
																		handleSubmitData(
																			index,
																			"bmIdx",
																			"1"
																		)
																	}
																/>
																<label
																	htmlFor={`bmIdx1_${index}`}
																/>
															</S.normalRadio>
															<S.normalLabel
																htmlFor={`bmIdx1_${index}`}>
																이벤트
															</S.normalLabel>
														</S.normalRadioArea>
													</td>
												</>
											)}
											<th>
												{division == 0
													? "포인트(P)"
													: "수량(개)"}
												*
											</th>
											<td>
												<div>
													<input
														type="text"
														value={
															Util.onChangeComma(
																submitData[
																	index
																].used
															) ?? ""
														}
														placeholder="0"
														onChange={(e) =>
															handleSubmitData(
																index,
																"used",
																e.target.value
															)
														}
														required
													/>
												</div>
											</td>
											<th>타입*</th>
											<td>
												<div>
													<select
														name=""
														id=""
														value={
															submitData[index]
																.type ?? ""
														}
														onChange={(e) =>
															handleSubmitData(
																index,
																"type",
																e.target.value
															)
														}
														required>
														<option value="">
															= 선택 =
														</option>
														{(action == 1 ||
															action == 2) && (
															<option value="23">
																이벤트
															</option>
														)}
														{(action == 1 ||
															action == 2) &&
															division == 0 && (
																<option value="26">
																	배송 환급
																</option>
															)}
														<option value="21">
															테스트
														</option>
														<option value="24">
															기타
														</option>
													</select>
												</div>
											</td>
										</tr>
										<tr>
											<th>사유</th>
											<td colSpan={5}>
												<div>
													<textarea
														name=""
														id=""
														cols={30}
														rows={2}
														placeholder="지급/회수 사유를 입력해 주세요.
														(관리자만 확인 가능)"
														value={
															submitData[index]
																.reason ?? ""
														}
														maxLength={100}
														onChange={(e) =>
															handleSubmitData(
																index,
																"reason",
																e.target.value
															)
														}></textarea>
												</div>
											</td>
										</tr>
									</tbody>
								</S.table03>
							);
						})}
						<S.blank2 />
						<S.plusMinusButton>
							<button
								type="button"
								onClick={() =>
									setSubmitData([...submitData, {}])
								}>
								<Plus width="0rem" height="0rem" fill="white" />
							</button>
						</S.plusMinusButton>
					</div>
					<S.blank2 />
					<S.flexRowCenter>
						<S.filledButton type="submit">
							{action == 0 ? "회수" : "지급"}하기
						</S.filledButton>
					</S.flexRowCenter>
				</form>
			</S.popupPage>
		</>
	);
};
