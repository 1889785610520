// 공지사항 등록
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { LoginAtom } from "../Recoil/Atoms/PopupState";
import { UserAtom, LoadingAtom } from "../Recoil/Atoms/UserState";

import * as S from "../Styles/Style";
import Api from "../libs/api";
import Util from "../Store/Util";

export default () => {
	const user = useRecoilValue(UserAtom);
	const setOpenLogin = useSetRecoilState<boolean>(LoginAtom);
	const setLoading = useSetRecoilState<boolean>(LoadingAtom);
	const apiUrl = process.env.REACT_APP_API_URL;
	const imgUrl = process.env.REACT_APP_IMG_URL;
	const token = localStorage.getItem("token");
	const [searchParams, setSearchParams] = useSearchParams();
	const bnIdx = searchParams.get("bnIdx");

	const [regDt, setRegDt] = useState(Util.getNowString);
	const [subject, setSubject] = useState("");
	const [contents, setContents] = useState("");
	const [image, setImage] = useState<{
		image: string;
		file: File | null;
	}>({ image: "", file: null });
	const [displayYn, setDisplayYn] = useState(1);
	const [openDt, setOpenDt] = useState<Date>();
	const [pushYn, setPushYn] = useState(1);

	useEffect(() => {
		user.isLogin && GetData();
	}, [user]);
	// 수정인 경우 보물 상세정보 가져오기
	const GetData = async () => {
		if (bnIdx == undefined) return false;
		setLoading(true);
		await Api.get(`board/notice/view/${bnIdx}`)
			.then((res: any) => {
				if (res.data == null) {
					const code = res.response.status;
					if (code == 401) {
						setOpenLogin(true);
					}
				} else {
					const data = res.data.data;
					console.log(res.data.data);
					setRegDt(data.regDt);
					setSubject(data.subject);
					setContents(data.contents);
					if (data.noticeContentsImageUrl) {
						let copiedList = image;
						copiedList.image = imgUrl + data.noticeContentsImageUrl;
						setImage(copiedList);
					}
					if (
						Util.onlyNum(Util.getNowString()) <
						data.openDt.slice(0, 12)
					) {
						setDisplayYn(2);
						setOpenDt(new Date(Util.dateFormat(data.openDt)));
					} else {
						setDisplayYn(data.displayYn);
					}
					setPushYn(data.pushYn);
				}
			})
			.catch((error) => {
				console.log(error.response.data, "wwwwww");
			});
		setLoading(false);
	};

	const uploadImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files !== null) {
			const file = e.target.files[0];
			if (file) {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => {
					setImage({
						image: reader.result as string,
						file: file,
					});
				};
			}
		}
	};

	// 등록/수정하기 버튼 클릭 이벤트
	const onSubmit = async (e: any) => {
		e.preventDefault();
		let method;
		let url;
		const formData = new FormData();

		if (bnIdx == undefined) {
			method = "post";
			url = "board/notice/create";
		} else {
			method = "patch";
			url = "board/notice/update";
			formData.append("bnIdx", bnIdx);
		}

		formData.append("subject", e.target.subject.value);
		formData.append("contents", e.target.contents.value);
		if (image.image !== "" && image.file !== null) {
			formData.append("contentsImage", image.file);
		} else if (image.image !== "" && image.file == null) {
			// 기존 이미지 그대로 사용
		} else {
			// 기존 이미지 삭제
			formData.append("delNo", "0");
		}
		formData.append(
			"displayYn",
			displayYn == 2 ? "1" : displayYn.toString()
		);
		if (displayYn == 2) {
			e.target.openDt.value &&
				formData.append("openDt", Util.onlyNum(e.target.openDt.value)); // 노출 예약인 경우만
		} else {
			// formData.append("openDt", "0"); // 노출 예약 아닌 경우
		}
		formData.append("pushYn", e.target.pushYn.value);

		for (let key of formData.keys()) {
			console.log(key, ":", formData.get(key));
		}

		if (
			window.confirm(
				`해당 내용을 ${
					bnIdx == undefined ? "등록" : "수정"
				}하시겠습니까?`
			)
		) {
			setLoading(true);
			await axios({
				method: method,
				url: apiUrl + url,
				data: formData,
				headers: {
					Authorization: token,
					"content-type": "multipart/form-data",
				},
			})
				.then((res) => {
					const data = res.data;
					console.log(data);
					if (data.statusCode == 200 || data.statusCode == 201) {
						alert("완료되었습니다.");
						window.opener.location.reload();
						window.close();
					}
				})
				.catch((error) => {
					console.log(error);
					alert("등록 실패");
				});
			setLoading(false);
		}
	};

	return (
		<>
			<S.popupPage>
				<h1>
					<S.headerText>
						공지사항 {bnIdx == undefined ? "등록" : "수정"}
					</S.headerText>
				</h1>
				{/* 테이블 영역 */}
				<form onSubmit={onSubmit}>
					<S.table02>
						<tbody>
							<tr>
								<th>등록일</th>
							</tr>
							<tr>
								<td>
									<input
										type="text"
										name="regDt"
										id="regDt"
										value={Util.dateFormat(regDt, "date")}
										readOnly
									/>
								</td>
							</tr>
							<tr>
								<th>제목*</th>
							</tr>
							<tr>
								<td>
									<input
										type="text"
										name="subject"
										id="subject"
										value={subject}
										onChange={(e) =>
											setSubject(e.target.value)
										}
										maxLength={1000}
										required
									/>
								</td>
							</tr>
							<tr>
								<th>내용*</th>
							</tr>
							<tr>
								<td>
									<S.textAreaWrap>
										<textarea
											name="contents"
											id="contents"
											cols={30}
											rows={10}
											value={contents}
											onChange={(e) =>
												setContents(e.target.value)
											}
											required></textarea>
									</S.textAreaWrap>
								</td>
							</tr>
							<tr>
								<th>이미지</th>
							</tr>
							<tr>
								<td className="imageArea">
									<S.flexRowBetween>
										<React.Fragment>
											{image.image == "" ? (
												<S.blankImageArea>
													<input
														id="image"
														name="image"
														type="file"
														accept=".png, .jpeg, .jpg"
														onChange={(e) =>
															uploadImage(e)
														}
													/>
													<label htmlFor="image">
														파일 선택
													</label>
												</S.blankImageArea>
											) : (
												<S.filledImagesArea
													onClick={() => {
														localStorage.setItem(
															"sendData",
															JSON.stringify(
																image.image
															)
														);
														window.open(
															"/PREV001001",
															"_blank",
															"width=800, height=800, left=100, top=100"
														);
													}}>
													<img
														src={
															image.image
																? image.image
																: ""
														}
														alt="image"
													/>
													<button
														onClick={(e) => {
															e.stopPropagation();
															setImage({
																image: "",
																file: null,
															});
														}}></button>
												</S.filledImagesArea>
											)}
										</React.Fragment>
									</S.flexRowBetween>
								</td>
							</tr>
							<tr>
								<th>노출 여부*</th>
							</tr>
							<tr>
								<td>
									<S.normalRadioArea>
										<S.normalRadio>
											<input
												type="radio"
												name="displayYn"
												id="displayYn1"
												checked={displayYn == 1}
												onChange={() => setDisplayYn(1)}
											/>
											<label htmlFor="displayYn1" />
										</S.normalRadio>
										<S.normalLabel htmlFor="displayYn1">
											노출
										</S.normalLabel>
										<S.normalRadio>
											<input
												type="radio"
												name="displayYn"
												id="displayYn0"
												checked={displayYn == 0}
												onChange={() => setDisplayYn(0)}
											/>
											<label htmlFor="displayYn0" />
										</S.normalRadio>
										<S.normalLabel htmlFor="displayYn0">
											미노출
										</S.normalLabel>
										<S.normalRadio>
											<input
												type="radio"
												name="displayYn"
												id="displayYn2"
												checked={displayYn == 2}
												onChange={() => setDisplayYn(2)}
											/>
											<label htmlFor="displayYn2" />
										</S.normalRadio>
										<S.normalLabel htmlFor="displayYn2">
											노출 예약
										</S.normalLabel>
										<input
											type="datetime-local"
											max={"9999-12-31T12:59"}
											name="openDt"
											id="openDt"
											value={
												openDt
													? Util.inputDateFormat(
															openDt
													  )
													: ""
											}
											onChange={(e) =>
												setOpenDt(
													new Date(e.target.value)
												)
											}
											className="radioInput"
											required={displayYn == 2}
											readOnly={displayYn != 2}
										/>
									</S.normalRadioArea>
								</td>
							</tr>
							<tr>
								<th>푸시 발송*</th>
							</tr>
							<tr>
								<td>
									<S.normalRadioArea>
										<S.normalRadio>
											<input
												type="radio"
												name="pushYn"
												id="pushYn1"
												value={1}
												checked={pushYn == 1}
												onChange={() => setPushYn(1)}
												disabled={bnIdx !== null}
											/>
											<label htmlFor="pushYn1" />
										</S.normalRadio>
										<S.normalLabel htmlFor="pushYn1">
											발송
										</S.normalLabel>
										<S.normalRadio>
											<input
												type="radio"
												name="pushYn"
												id="pushYn0"
												value={0}
												checked={pushYn == 0}
												onChange={() => setPushYn(0)}
												disabled={bnIdx !== null}
											/>
											<label htmlFor="pushYn0" />
										</S.normalRadio>
										<S.normalLabel htmlFor="pushYn0">
											미발송
										</S.normalLabel>
									</S.normalRadioArea>
								</td>
							</tr>
						</tbody>
					</S.table02>
					<S.blank2 />
					<S.flexRowCenter>
						<S.filledButton type="submit">
							{bnIdx == undefined ? "등록" : "수정"}하기
						</S.filledButton>
					</S.flexRowCenter>
				</form>
			</S.popupPage>
		</>
	);
};
