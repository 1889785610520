// 회원 상세정보
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useSearchParams, useLocation } from "react-router-dom";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { UserAtom, LoadingAtom, MenuAtom } from "../Recoil/Atoms/UserState";

import * as S from "../Styles/Style";
import Api from "../libs/api";
import Util from "../Store/Util";
import Pagination from "../Components/Pagination";

// 이미지
import rotate from "../Images/rotate.png";
import noImage from "../Images/noImage.png";
import { ReactComponent as Mail } from "../Images/mail.svg";
import { ReactComponent as Sms } from "../Images/sms.svg";
import { ReactComponent as Push } from "../Images/push.svg";

const MemberProfile = styled.div`
	width: 10rem;
	height: calc(100% - 2rem);
	position: relative;
	overflow: hidden;
	border: 1px solid ${S.color.gray02};
	margin-top: 2rem;
	flex-shrink: 0;
	img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	button {
		width: 2rem;
		height: 2.5rem;
		position: absolute;
		top: 0.5rem;
		right: 0.5rem;
		background-image: url(${rotate});
		background-size: contain;
		background-repeat: no-repeat;
	}
`;

interface memberData {
	boxCnt: number;
	email: string;
	freePoint: number;
	inventory: string;
	lastLoginDt: string;
	lastLoginIp: string;
	mailSendYn: number;
	mmIdx: string;
	name: string;
	nick: string;
	phoneNumber: string;
	platform: string;
	smsSendYn: number;
	stateCode: string;
	stateName: string;
	profileImageUrl: string;
	joinDt: string;
	loginStateDt: string;
	cancelPayment: string;
	payment: string;
	totalPayment: string;
}

export default () => {
	const imgUrl = process.env.REACT_APP_IMG_URL;
	const user = useRecoilValue(UserAtom);
	const menu = useRecoilValue(MenuAtom);
	let agmIdx: number;
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const paramsMmIdx = searchParams.get("mmIdx"); // 부모창에서 받아온 회원번호
	const page = searchParams.get("page");
	const [memberData, setMemberData] = useState<memberData>();
	const mmIdx = useRef<string>("");

	const now = new Date(Util.getNow());
	// 검색 옵션
	const searchOptionsRef = useRef<{}>({
		searchStrDt: null,
		searchEndDt: Util.getNowString().slice(0, 8),
	});
	const [searchOptions, setSearchOptions] = useState({
		searchKey: "",
		searchWord: "",
		searchStrDt: "",
		searchEndDt: "",
	});
	// 검색 일자
	const [searchStrDt, setSearchStrDt] = useState<string>("");
	const [searchEndDt, setSearchEndDt] = useState<string>(
		Util.inputDateFormat(now, "date")
	);
	// 탭 리스트 정보
	const [activeTab, setActiveTab] = useState<number>(1);
	const listSize = 10;
	const [totalItems, setTotalItems] = useState<number>(0);
	const [tabDataList, setTabDataList] = useState([]);
	const [activeData, setActiveData] = useState<{
		tab: number;
		text: string;
		searchType: number;
		url: string;
		thList: string[];
		tdList: string[];
	}>();
	const tabData = [
		{
			tab: 1,
			text: "결제 내역",
			searchType: 0, // 검색어 입력 영역 필요 여부(0:없음/1:있음)
			url: "member/detailPaymentSelect",
			thList: [
				"번호",
				"결제 번호",
				"구분",
				"내용",
				"수량(개)",
				"금액(Rp)",
				"수단",
				"일시",
				"영수증번호",
			],
			tdList: [
				"ptIdx",
				"transactionState",
				"paymentProductType",
				"quantity",
				"paymentCash",
				"payMethod",
				"regDt",
				"tXid",
			],
		},
		{
			tab: 2,
			text: "포인트 내역",
			searchType: 0,
			url: "member/detailPointSelect",
			thList: [
				"번호",
				"행위 번호",
				"구분",
				"일시",
				"변동 내역(P)",
				"이후 포인트(P)",
			],
			tdList: [
				"mphmIdx",
				"changeType",
				"regDt",
				"useFreePoint",
				"afterFreePoint",
			],
		},
		{
			tab: 3,
			text: "상자 내역",
			searchType: 0,
			url: "member/detailBoxHistorySelect",
			thList: [
				"번호",
				"행위 번호",
				"상자 종류",
				"구분",
				"타입",
				"일시",
				"변동 수량(개)",
				"이후 수량(개)",
			],
			tdList: [
				"mbqhIdx",
				"boxName",
				"quantityType",
				"actionType",
				"regDt",
				"changeBoxCount",
				"afterBoxCount",
			],
		},
		{
			tab: 4,
			text: "보물 내역",
			searchType: 1,
			url: "member/detailMemberPresentHistory",
			thList: [
				"번호",
				"유니크 번호",
				"보물번호",
				"보물명",
				"보물 등급",
				"보물 분류",
				"정가(Rp)",
				"보물 상태",
				"획득방식",
				"획득일시",
			],
			tdList: [
				"pumIdx",
				"pmIdx",
				"name",
				"gradeCode",
				"category",
				"displayPrice",
				"uniqueStateName",
				"registType",
				"regDt",
			],
		},
		{
			tab: 5,
			text: "뽑기 내역",
			searchType: 1,
			url: "member/detailMemberBoxOpenHistory",
			thList: [
				"번호",
				"유니크 번호",
				"뽑기 상자 종류",
				"보물번호",
				"보물명",
				"보물 등급",
				"보물 분류",
				"정가(Rp)",
				"일시",
			],
			tdList: [
				"pumIdx",
				"boxName",
				"pmIdx",
				"name",
				"gradeCode",
				"category",
				"displayPrice",
				"regDt",
			],
		},
		{
			tab: 6,
			text: "분해 내역",
			searchType: 1,
			url: "member/detailMemberDecomposeHistory",
			thList: [
				"번호",
				"분해번호",
				"유니크 번호",
				"보물번호",
				"보물명",
				"정가(Rp)",
				"일시",
				"변동 내역(P)",
				"이후 포인트(P)",
			],
			tdList: [
				"mdhIdx",
				"pumIdx",
				"pmIdx",
				"name",
				"displayPrice",
				"regDt",
				"beforeFreePoint",
				"afterFreePoint",
			],
		},
		{
			tab: 7,
			text: "트레이드 내역",
			searchType: 0,
			url: "",
			thList: ["번호"],
			tdList: [],
		},
		{
			tab: 8,
			text: "문의 내역",
			searchType: 0,
			url: "member/detailMemberQnaHistory",
			thList: [
				"번호",
				"문의번호",
				"분류",
				"제목",
				"등록 일시",
				"답변 상태",
				"답변 일시",
			],
			tdList: [
				"bqIdx",
				"category",
				"questionSubject",
				"regDt",
				"progress",
				"answerDt",
			],
		},
	];
	// 회원 정보 가져오기
	const getMember = async () => {
		await Api.get(`member/detailSelect/${mmIdx.current}`).then(
			(res: any) => {
				if (res.data) {
					console.log(`member/detailSelect/${mmIdx.current}`);
					setMemberData(res.data.data);
				}
			}
		);
	};
	// 탭 리스트 데이터 가져오기
	const GetList = async (tab: number) => {
		console.log(searchOptionsRef.current);
		await Api.get(`${tabData[tab - 1].url}`, {
			...searchOptionsRef.current,
			mmIdx: mmIdx.current,
			page: page,
		}).then((res: any) => {
			if (res.data) {
				let data = res.data.data;
				console.log(data);
				setTabDataList(data.data);
				setTotalItems(data.totalCnt);
			}
		});
	};
	// 회원 프로필 이미지
	const profileImage = () => {
		if (memberData?.profileImageUrl == "") {
			return noImage;
		} else if (memberData?.profileImageUrl.includes("https://")) {
			return memberData?.profileImageUrl;
		} else {
			return imgUrl && imgUrl + memberData?.profileImageUrl;
		}
	};
	// 회원 상태
	const stateCodeText = (stateCode: string) => {
		switch (stateCode) {
			case "00":
				return "가입 전";
			case "01":
				return "가입 전";
			case "02":
				return "가입 전";
			case "11":
				return "가입";
			case "12":
				return "테스터";
			case "-999":
				return "탈퇴";
			default:
				return "-";
		}
	};
	// 탭 리스트 데이터 변형
	const dataReturn = (
		tabDataListItem: any,
		item: string,
		data: number | string
	) => {
		let result: string | number = "";
		if (
			// 결제상태값 1:결제전,-2:결제실패,2:성공
			item == "transactionState"
		) {
			switch (data) {
				case 1:
					result = "결제 중";
					break;
				case 2:
					result = "결제 성공";
					break;
				case -1:
					result = "결제 취소";
					break;
				case -2:
					result = "결제 실패";
					break;
				default:
					result = "-";
			}
		} else if (
			// 결제타입 100:상자,200:배송비
			item == "paymentProductType"
		) {
			result = data == 100 ? "상자" : "배송비";
		} else if (
			// 결제 금액
			item == "paymentCash"
		) {
			result = tabDataListItem.transactionState == -1 ? "- " : "+ ";
			result = result += Util.addComma(Number(data));
		} else if (
			// 결제 금액
			item == "beforeFreePoint"
		) {
			result =
				"+ " +
				Util.addComma(tabDataListItem.afterFreePoint - Number(data));
		} else if (
			// 결제 금액
			item == "useFreePoint"
		) {
			result = tabDataListItem.updown + " " + Util.addComma(Number(data));
		} else if (item == "payMethod") {
			switch (data) {
				case "01":
					result = "신용카드";
					break;
				case "02":
					result = "가상계좌";
					break;
				case "05":
					result = "전자지갑";
					break;
				case "08":
					result = "큐리스";
					break;
				default:
					result = "-";
			}
		} else if (item == "registType") {
			switch (tabDataListItem.bmIdx) {
				case 2:
					result = "골드 ";
					break;
				case 7:
					result = "스페셜 ";
					break;
				default:
					result = "";
			}
			switch (data) {
				case "B":
					result += "상자 오픈";
					break;
				case "C":
					result = "합성";
					break;
				case "E":
					result = "이벤트";
					break;
				default:
					result = "-";
			}
		} else if (item == "boxName") {
			result = data + " 상자";
		} else if (item == "regDt" || item == "answerDt") {
			// 날짜 형식으로 변형될 데이터
			result = data && Util.dateFormat(data.toString());
		} else if (
			// 금액이나 갯수 형식으로 변형될 데이터
			item == "quantity" ||
			item == "afterFreePoint" ||
			item == "useFreePoint" ||
			item == "displayPrice"
		) {
			result = Util.addComma(Number(data));
		} else {
			result = data;
		}
		return result;
	};
	useEffect(() => {
		setActiveData(tabData[0]);
		if (paramsMmIdx !== null && paramsMmIdx !== undefined) {
			mmIdx.current = paramsMmIdx;
		}
	}, []);

	// 1페이지로 이동되면 리셋
	const pageReset = (tab?: number) => {
		setActiveData(tabData[tab ? tab - 1 : activeTab - 1]);
		if (page == "1") {
			user.isLogin && GetList(tab ? tab : activeTab);
		} else {
			setSearchParams({ mmIdx: mmIdx.current, page: "1" });
		}
	};
	useEffect(() => {
		getMember();
		window.scrollTo(0, 0); // 페이지 이동 시 스크롤 위치 맨 위로 초기화
		user.isLogin && GetList(activeTab);
	}, [page, user]);

	// 프로필 이미지 리셋
	const profileResset = async () => {
		if (window.confirm("해당 이미지를 초기화하시겠습니까?")) {
			await Api.patch(`member/memberProfileReset/${mmIdx.current}`)
				.then((res) => {
					console.log(res);
					alert("초기화되었습니다.");
					window.location.reload();
				})
				.catch((error) => console.log(error));
		}
	};

	// 공통
	// 일자
	const setDate = (value: any) => {
		const year = now.getFullYear(); // 년
		const month = now.getMonth(); // 월
		const day = now.getDate(); // 일

		if (value == "") {
			setSearchStrDt("");
		} else {
			const start = new Date(
				year,
				month,
				day - value
			).toLocaleDateString();
			const startDt = new Date(start);

			setSearchStrDt(Util.inputDateFormat(startDt, "date"));
		}
		setSearchEndDt(Util.inputDateFormat(now, "date"));
	};
	useEffect(() => {
		setSearchOptions({
			...searchOptions,
			searchStrDt: Util.onlyNum(searchStrDt),
			searchEndDt: Util.onlyNum(searchEndDt),
		});
	}, [searchStrDt, searchEndDt]);
	// 탭 변경 이벤트(검색 기간 리셋)
	const changeTab = (tab?: number) => {
		setSearchStrDt("");
		setSearchEndDt(Util.inputDateFormat(now, "date"));
		searchOptionsRef.current = {};
		setSearchOptions({
			searchKey: "",
			searchWord: "",
			searchStrDt: "",
			searchEndDt: "",
		});
		pageReset(tab);
	};
	// 검색
	const search = () => {
		searchOptionsRef.current = {
			...searchOptionsRef.current,
			...searchOptions,
		};
		pageReset();
	};
	// 권한 확인(뷰어 제한) 후 액션
	const viewerCheck = (action: () => void) => {
		menu.map((m) => {
			m.sub.map((s) => {
				// 팝업의 뷰어체크는 부모창의 정보를 가져옴
				// if (s.link == window.opener.location.pathname) {
				// 다른 페이지에서도 가져오는것이 있어서 주석
				if (s.link == "/MEMB001001") {
					agmIdx = s.agmIdx;
				}
			});
		});
		Util.viewerCheck(user.grade, agmIdx, () => action());
	};
	return (
		<S.popupPage>
			<h1>
				<S.headerText>회원 상세정보</S.headerText>
			</h1>
			{/* 회원 정보 영역 */}
			<S.flexRowBetween>
				<MemberProfile>
					<img
						style={{ height: "100%" }}
						src={profileImage()}
						alt=""
					/>
					<button onClick={() => profileResset()}></button>
				</MemberProfile>
				<S.table03>
					<tbody>
						<tr>
							<th>회원 번호</th>
							<td>{mmIdx.current}</td>
							<th>회원 닉네임</th>
							<td colSpan={3}>
								{memberData?.nick}
								<S.tableButtonText
									onClick={() => {
										window.open(
											"MEMB001003?mmIdx=" +
												mmIdx.current +
												"&nick=" +
												memberData?.nick +
												"&type=" +
												0,
											"_blank",
											"width=1000, height=800, left=100, top=100"
										);
									}}>
									이력
								</S.tableButtonText>
							</td>
						</tr>
						<tr>
							<th>이름</th>
							<td>{memberData?.name}</td>
							<th>전화번호</th>
							<td>{memberData?.phoneNumber}</td>
							<th>이메일</th>
							<td>{memberData?.email}</td>
						</tr>
						<tr>
							<th>회원 상태</th>
							<td>
								{stateCodeText(memberData?.stateCode ?? "")}
							</td>
							<th>플랫폼</th>
							<td>{memberData?.platform}</td>
							<th>마케팅 수신 동의</th>
							<td>
								<div className="iconArea">
									<Mail
										fill={
											memberData?.mailSendYn == 1
												? S.color.gray04
												: S.color.gray02
										}
									/>
									<Sms
										fill={
											memberData?.smsSendYn == 1
												? S.color.gray04
												: S.color.gray02
										}
									/>
									<Push
										fill={
											false
												? S.color.gray04
												: S.color.gray02
										}
									/>
								</div>
							</td>
						</tr>
						<tr>
							<th>가입일시</th>
							<td>
								{stateCodeText(memberData?.stateCode ?? "") ==
								"가입 전"
									? "-"
									: memberData?.loginStateDt &&
									  Util.dateFormat(memberData?.loginStateDt)}
							</td>
							<th>최근 접속일시</th>
							<td>
								{memberData?.lastLoginDt &&
									Util.dateFormat(memberData?.lastLoginDt)}
								<S.tableButtonText
									onClick={() => {
										window.open(
											"MEMB001003?mmIdx=" +
												mmIdx.current +
												"&nick=" +
												memberData?.nick +
												"&type=" +
												1,
											"_blank",
											"width=1000, height=800, left=100, top=100"
										);
									}}>
									접속 이력
								</S.tableButtonText>
							</td>
							<th>최근접속IP</th>
							<td>{memberData?.lastLoginIp}</td>
						</tr>
					</tbody>
				</S.table03>
			</S.flexRowBetween>
			{/* 회원 결제 및 보유 정보 영역 */}
			<S.table03>
				<tbody>
					<tr>
						<th>총 결제 금액(Rp)</th>
						<td colSpan={5}>
							{/* 결제{" "}
							{Util.addComma(Number(memberData?.payment)) ?? 0}
							{" /"}
							취소{" "}
							{Util.addComma(Number(memberData?.cancelPayment)) ??
								0}
							{" /"} 총 매출{" "} */}
							{Util.addComma(Number(memberData?.payment)) ?? 0}
							<S.tableButtonText
								onClick={() => {
									window.open(
										"CHPO001002?mmIdx=" +
											mmIdx.current +
											"&nick=" +
											memberData?.nick,
										"_blank",
										"width=1000, height=800, left=100, top=100"
									);
								}}>
								지급/회수
							</S.tableButtonText>
						</td>
					</tr>
					<tr>
						<th>보유 포인트(P)</th>
						<td>
							{memberData?.freePoint &&
								Util.addComma(memberData?.freePoint)}
						</td>
						<th>보유 상자(개)</th>
						<td>
							{memberData?.boxCnt &&
								Util.addComma(memberData?.boxCnt)}
						</td>
						<th>보유 보물(개)</th>
						<td>
							{memberData?.boxCnt &&
								Util.addComma(Number(memberData?.inventory))}
						</td>
					</tr>
				</tbody>
			</S.table03>
			<S.blank3 />
			{/* 탭 영역 */}
			<S.tabArea>
				{tabData.map((item, index) => {
					return (
						<button
							key={index}
							className={activeTab === item.tab ? "active" : ""}
							onClick={(e) => {
								Util.tab({ e });
								setActiveTab(item.tab);
								changeTab(item.tab);
							}}>
							{item.text}
						</button>
					);
				})}
			</S.tabArea>
			{/* 검색 영역 */}
			<S.searchArea>
				<S.searchRow>
					<input
						type="date"
						max={"9999-12-31"}
						name="searchStrDt"
						id="searchStrDt"
						value={searchStrDt}
						onChange={(e) => {
							setSearchStrDt(e.target.value);
						}}
					/>
					<p> - </p>
					<input
						type="date"
						max={"9999-12-31"}
						name="searchEndDt"
						id="searchEndDt"
						value={searchEndDt}
						onChange={(e) => {
							setSearchEndDt(e.target.value);
						}}
					/>
					{tabData.filter((item) => item.tab === activeTab)[0]
						.searchType == 1 ? (
						<>
							<input
								name="searchDtRadio"
								type="radio"
								id="searchDtRadio1"
								value={""}
								defaultChecked
								onClick={(event) =>
									setDate(
										(event.target as HTMLInputElement).value
									)
								}
							/>
							<label htmlFor="searchDtRadio1">전체</label>
							<input
								name="searchDtRadio"
								type="radio"
								id="searchDtRadio2"
								value={7}
								onClick={(event) =>
									setDate(
										(event.target as HTMLInputElement).value
									)
								}
							/>
							<label htmlFor="searchDtRadio2">1주일</label>
							<input
								name="searchDtRadio"
								type="radio"
								id="searchDtRadio3"
								value={30}
								onClick={(event) =>
									setDate(
										(event.target as HTMLInputElement).value
									)
								}
							/>
							<label htmlFor="searchDtRadio3">1개월</label>
							<input
								name="searchDtRadio"
								type="radio"
								id="searchDtRadio4"
								value={90}
								onClick={(event) =>
									setDate(
										(event.target as HTMLInputElement).value
									)
								}
							/>
							<label htmlFor="searchDtRadio4">3개월</label>
						</>
					) : (
						<>
							<S.searchButton onClick={() => search()}>
								<S.headerText>검색하기</S.headerText>
							</S.searchButton>
							<S.searchButton
								onClick={() => {
									viewerCheck(() => {
										if (
											window.confirm(
												"엑셀을 다운 받으시겠습니까?"
											)
										) {
											alert("엑셀다운로드 개발 전");
										}
									});
								}}
								color={S.color.primaryVariant}>
								<S.headerText>엑셀 다운로드</S.headerText>
							</S.searchButton>
						</>
					)}
				</S.searchRow>
				{activeData?.searchType == 1 && (
					<S.searchRow>
						<select
							name="searchKey"
							id="searchKey"
							value={searchOptions.searchKey ?? ""}
							onChange={(e) =>
								setSearchOptions({
									...searchOptions,
									searchKey: e.target.value,
								})
							}>
							<option value="">= 선 택 =</option>
							<option value="1">보물명</option>
							<option value="2">보물번호</option>
							<option value="3">유니크번호</option>
							{activeData.tab == 6 && (
								<option value="4">분해번호</option>
							)}
						</select>
						<input
							type="text"
							id="searchWord"
							value={searchOptions.searchWord ?? ""}
							onChange={(e) =>
								setSearchOptions({
									...searchOptions,
									searchWord: e.target.value,
								})
							}
						/>
						<S.searchButton onClick={() => search()}>
							<S.headerText>검색하기</S.headerText>
						</S.searchButton>
						<S.searchButton
							onClick={() => {
								viewerCheck(() => {
									if (
										window.confirm(
											"엑셀을 다운 받으시겠습니까?"
										)
									) {
										alert("엑셀다운로드 개발 전");
									}
								});
							}}
							color={S.color.primaryVariant}>
							<S.headerText>엑셀 다운로드</S.headerText>
						</S.searchButton>
					</S.searchRow>
				)}
			</S.searchArea>
			{/* 테이블 영역 */}
			<S.table01Wrap>
				<S.table01>
					<thead>
						<tr>
							{activeData?.thList?.map((item, index) => {
								return <th key={index}>{item}</th>;
							})}
						</tr>
					</thead>
					<tbody>
						{tabDataList.length > 0 ? (
							<>
								{tabDataList?.map((tabDataListItem, index) => {
									return (
										<tr key={index}>
											<td>
												{totalItems -
													(page
														? Number(page) - 1
														: 0) *
														listSize -
													index}
											</td>
											{activeData?.tdList?.map(
												(item, index) => {
													return (
														<td key={index}>
															{dataReturn(
																tabDataListItem,
																item,
																tabDataListItem[
																	item
																]
															)}
														</td>
													);
												}
											)}
										</tr>
									);
								})}
							</>
						) : (
							<tr>
								<td
									colSpan={999}
									style={{ textAlign: "center" }}>
									데이터가 없습니다.
								</td>
							</tr>
						)}
					</tbody>
				</S.table01>
			</S.table01Wrap>
			{/* 페이지네이션 영역 */}
			<Pagination
				totalItems={totalItems} // 데이터 총 개수
				currentPage={page && parseInt(page) > 0 ? parseInt(page) : 1} // 현재 페이지
				itemCountPerPage={listSize} // 페이지당 보여줄 데이터 개수
			/>
		</S.popupPage>
	);
};
