// 보물 일괄 미노출
import { useState, useRef } from "react";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import { LoginAtom } from "../Recoil/Atoms/PopupState";
import { LoadingAtom } from "../Recoil/Atoms/UserState";

import * as S from "../Styles/Style";
import Api from "../libs/api";
import Util from "../Store/Util";
import Filter from "../Components/Filter";

// 이미지
import { ReactComponent as Plus } from "../Images/plus.svg";
import { ReactComponent as Minus } from "../Images/minus.svg";

const GradeTable = styled.table``;

interface list {
	pmIdx: number;
	categoryName: string;
	displayDt: string;
	displayPrice: number;
	notifyYn: number;
	displayYn: number;
	gradeCode: string;
	gradeOrder: string;
	imageYn: string;
	inventory: string;
	limitCount: number;
	name: string;
	regDt: string;
	registType: string;
	useYn: number;
}

export default () => {
	const setOpenLogin = useSetRecoilState<boolean>(LoginAtom);
	const setLoading = useSetRecoilState<boolean>(LoadingAtom);
	const [isOpenFilter, setOpenFilter] = useState<boolean>(false);
	const [isApplied, setIsApplied] = useState<boolean>(false);
	const [list, setList] = useState<list[]>([]);
	const [selectedList, setSelectedList] = useState<list[]>([]);
	// 검색 옵션
	const searchOptionsRef = useRef({});
	const [searchOptions, setSearchOptions] = useState({});
	// 필터 옵션
	const filterOptionsRef = useRef({});
	const [gradeCode, setGradeCode] = useState<string[]>([]);
	const [categoryCode, setCategoryCode] = useState<string[]>([]);

	const filterList = [
		{
			title: "보물 등급",
			name: "gradeCode",
			items: [
				{ text: "S5등급", value: "S5" },
				{ text: "S4등급", value: "S4" },
				{ text: "S3등급", value: "S3" },
				{ text: "S2등급", value: "S2" },
				{ text: "S1등급", value: "S1" },
				{ text: "S등급", value: "S" },
				{ text: "A등급", value: "A" },
				{ text: "B등급", value: "B" },
				{ text: "C등급", value: "C" },
				{ text: "구매 5만원이상", value: 9 },
				{ text: "D등급", value: "D" },
				{ text: "E등급", value: "E" },
				{ text: "F등급", value: "F" },
			],
			option: gradeCode,
			setOption: setGradeCode,
		},
		{
			title: "보물 분류",
			name: "categoryCode",
			items: [
				{ text: "가구/인테리어", value: 1 },
				{ text: "스포츠/레저", value: 2 },
				{ text: "기프티콘", value: 3 },
				{ text: "식품", value: 4 },
				{ text: "디지털/가전", value: 5 },
				{ text: "장난감/완구", value: 6 },
				{ text: "명품", value: 7 },
				{ text: "패션잡화", value: 8 },
				{ text: "생활/건강", value: 9 },
				{ text: "화장품/미용", value: 10 },
				{ text: "지류상품권", value: 11 },
			],
			option: categoryCode,
			setOption: setCategoryCode,
		},
	];
	// 미노출 타입
	const [type, setType] = useState<string>("displayYn");

	// 1페이지로 이동되면 리셋
	const pageResset = (tab?: string) => {
		// setSearchParams({ page: "1" });
		GetList();
	};
	const GetList = async () => {
		setLoading(true);
		console.log(searchOptionsRef.current);
		await Api.get("present/list", {
			...searchOptionsRef.current,
			...filterOptionsRef.current,
			page: 1,
			listSize: 50,
			registType: "A",
		})
			.then((res: any) => {
				if (res.data == null) {
					const code = res.response.status;
					if (code == 401) {
						setOpenLogin(true);
					}
				} else {
					console.log(res.data.data);
					setList(res.data.data.datas);
				}
			})
			.catch((error) => {
				console.log(error.response.data, "wwwwww");
			});
		setLoading(false);
	};
	// 노출여부
	const displayYnText = (displayYn: number) => {
		switch (displayYn) {
			case 0:
				return "미노출";
			case 1:
				return "노출";
			default:
				return "-";
		}
	};
	// 제외 여부
	const useYnText = (useYn: number) => {
		switch (useYn) {
			case 0:
				return "제외";
			case 1:
				return "미제외";
			default:
				return "-";
		}
	};

	// 정렬
	const sort = (e: any, sortType: number) => {
		if (list.length > 0) {
			Util.sorting({
				e, // 이벤트 대상
				searchOptionsRef,
				searchOptions,
				sortType: sortType,
			});
			pageResset();
		}
	};
	// 검색
	const search = () => {
		searchOptionsRef.current = {
			...searchOptionsRef.current,
			...searchOptions,
		};
		console.log(searchOptionsRef.current);
		console.log(searchOptions);
		pageResset();
	};
	// 필터 선택 취소
	const filterRefresh = () => {
		setGradeCode([]);
		setCategoryCode([]);
	};
	// 필터 적용
	const filter = () => {
		console.log(gradeCode);
		filterOptionsRef.current = {
			gradeCode: gradeCode.join(),
			categoryCode: categoryCode,
		};
		pageResset();
		setIsApplied(gradeCode.length > 0 || categoryCode.length > 0);
	};

	// 체크박스
	// 체크된 아이템을 담을 배열
	const [addCheckItems, setAddCheckItems] = useState<list[]>([]);
	const [delCheckItems, setDelCheckItems] = useState<list[]>([]);
	// 체크박스 단일 선택
	const handleSingleCheck = (checked: boolean, item: list, type: string) => {
		if (checked) {
			// 단일 선택 시 체크된 아이템을 배열에 추가
			type == "add"
				? setAddCheckItems((prev) => [...prev, item])
				: setDelCheckItems((prev) => [...prev, item]);
		} else {
			// 단일 선택 해제 시 체크된 아이템을 제외한 배열 (필터)
			type == "add"
				? setAddCheckItems(addCheckItems.filter((el) => el !== item))
				: setDelCheckItems(delCheckItems.filter((el) => el !== item));
		}
	};
	// 전체 선택 클릭 시
	const handleAllCheck = (checked: boolean, type: string) => {
		if (checked && list && list.length > 0) {
			const newArray: list[] | null = [];
			list.forEach((el) => newArray.push(el));
			type == "add"
				? setAddCheckItems(newArray)
				: setDelCheckItems(newArray);
		} else {
			// 전체 선택 해제 시 addCheckItems 를 빈 배열로 상태 업데이트
			type == "add" ? setAddCheckItems([]) : setDelCheckItems([]);
		}
	};
	// 추가 버튼 클릭
	const addCheckedItem = () => {
		let newArr: list[] = [];

		if (selectedList.length > 0) {
			addCheckItems.map((element) => {
				let isExist: boolean = false;
				selectedList.map((el) => {
					element.pmIdx == el.pmIdx && (isExist = true);
				});
				console.log(isExist ? "중복" : element.pmIdx + "추가완료");
				!isExist && newArr.push(element);
			});
			setSelectedList([...newArr, ...selectedList]);
		} else {
			setSelectedList([...addCheckItems]);
		}
		setAddCheckItems([]);
	};
	// 삭제 버튼 클릭
	const delCheckedItem = () => {
		let newArr: list[] = selectedList;

		delCheckItems.map((element) => {
			console.log(element.pmIdx);
			newArr = newArr.filter((el) => el.pmIdx !== element.pmIdx);
		});
		setSelectedList(newArr);
		setDelCheckItems([]);
	};

	// 저장하기 버튼 클릭 이벤트
	const save = async () => {
		if (selectedList.length == 0) {
			return;
		}
		let newArr: number[] = [];

		selectedList.map((item) => {
			newArr.push(item.pmIdx);
		});

		const typeText = () => {
			switch (type) {
				case "displayYn":
					return "보물 미노출";
				case "notifyYn":
					return "뉴스룸 미노출";
				case "useYn":
					return "제외";
				default:
					return "-";
			}
		};

		if (
			window.confirm(
				"해당 " +
					selectedList.length +
					"개의 보물을 " +
					typeText() +
					" 처리하시겠습니까?"
			)
		) {
			setLoading(true);
			await Api.patch("present/PresentExcept", {
				pmIdx: newArr,
				[type]: 0,
			}).then((res: any) => {
				// window.location.reload();
				console.log(type, res);
				if (res.data) {
					if (res.data.statusCode == 200) {
						window.opener.location.reload();
						window.close();
					}
				} else {
					// TODO: 예외처리
					if (res.response.data.error == -203) {
						alert("");
					} else if (res.response.data.error == -201) {
						alert("");
					} else if (res.response.data.error == -101) {
						alert(
							"보유 중인 사람이 있어 보물을 제외하실 수 없습니다."
						);
					}
				}
			});
			setLoading(false);
		}
	};

	return (
		<>
			<S.popupPage>
				<h1>
					<S.headerText>보물 일괄 미노출</S.headerText>
				</h1>
				<S.blank3 />
				<S.switchRadioArea style={{ margin: "auto" }}>
					<S.switchRadio>
						<input
							type="radio"
							name="type"
							id="displayYn"
							onChange={() => setType("displayYn")}
							defaultChecked
						/>
						<label htmlFor="displayYn">보물 미노출</label>
					</S.switchRadio>
					<S.switchRadio>
						<input
							type="radio"
							name="type"
							id="notifyYn"
							onChange={() => setType("notifyYn")}
						/>
						<label htmlFor="notifyYn">뉴스룸 미노출</label>
					</S.switchRadio>
					<S.switchRadio>
						<input
							type="radio"
							name="type"
							id="useYn"
							onChange={() => setType("useYn")}
						/>
						<label htmlFor="useYn">보물 제외</label>
					</S.switchRadio>
				</S.switchRadioArea>
				<S.blank3 />
				{/* 검색 영역 */}
				<S.searchArea>
					<S.searchRow>
						<select
							name="searchKey"
							id="searchKey"
							onChange={(e) =>
								setSearchOptions({
									...searchOptions,
									searchKey: e.target.value,
								})
							}
						>
							<option value="">= 선 택 =</option>
							<option value="1">보물번호</option>
							<option value="2">보물명</option>
						</select>
						<input
							type="text"
							id="searchWord"
							onChange={(e) =>
								setSearchOptions({
									...searchOptions,
									searchWord: e.target.value,
								})
							}
						/>
						<S.searchButton onClick={() => search()}>
							<S.headerText>검색하기</S.headerText>
						</S.searchButton>
						<S.filterButton
							onClick={() => {
								setOpenFilter(!isOpenFilter);
							}}
							className={`${isApplied && "applied"}`}
						>
							{isOpenFilter ? (
								<Minus
									width="2rem"
									height="2rem"
									fill="white"
								/>
							) : (
								<Plus width="2rem" height="2rem" fill="white" />
							)}
						</S.filterButton>
					</S.searchRow>
					{/* 필터영역 */}
					<S.filterArea className={`${isOpenFilter && "expend"}`}>
						<S.filterWrap>
							{filterList.map((filter: any, fi: number) => {
								return (
									<li key={fi}>
										<h2>{filter.title}</h2>
										<ul>
											<Filter
												fi={fi}
												filter={filter} // 필터 아이템 리스트
												option={filter.option}
												setOption={filter.setOption}
											/>
										</ul>
									</li>
								);
							})}
						</S.filterWrap>
						<S.flexRowEnd>
							<button onClick={() => filter()}>적용</button>
							<button onClick={() => filterRefresh()}>
								전체 해제
							</button>
						</S.flexRowEnd>
					</S.filterArea>
				</S.searchArea>
				{/* 테이블 영역 */}
				<div
					style={{
						height: "1%",
						display: "flex",
						flexDirection: "column",
						flexGrow: 1,
					}}
				>
					<S.table01Wrap
						style={{
							height: "100%",
						}}
					>
						<S.table01>
							<thead>
								<tr>
									<th>
										<S.onlyCheckbox>
											<input
												type="checkbox"
												name=""
												id="addCheckAll"
												onChange={(e) =>
													handleAllCheck(
														e.target.checked,
														"add"
													)
												}
											/>
											<label htmlFor="addCheckAll" />
										</S.onlyCheckbox>
									</th>
									<th
										className="sort down"
										onClick={(e) => sort(e, 1)}
									>
										보물번호
									</th>
									<th>보물명</th>
									<th>보물 등급</th>
									<th>보물 분류</th>
									<th
										className="sort"
										onClick={(e) => sort(e, 2)}
									>
										정가(원)
									</th>
									<th>보물 노출여부</th>
									<th>뉴스룸 노출여부</th>
									<th>제외 여부</th>
									<th>보유현황</th>
									<th>등록 일시</th>
								</tr>
							</thead>
							<tbody>
								{list && list.length > 0 ? (
									<>
										{list.map((item, i) => (
											<tr key={i}>
												<td>
													<S.onlyCheckbox>
														<input
															type="checkbox"
															name=""
															checked={addCheckItems.includes(
																item
															)}
															onChange={(e) => {
																handleSingleCheck(
																	e.target
																		.checked,
																	item,
																	"add"
																);
															}}
															id={
																"add_" +
																item.pmIdx
															}
														/>
														<label
															htmlFor={
																"add_" +
																item.pmIdx
															}
														/>
													</S.onlyCheckbox>
												</td>
												<td
													className="Clickable"
													onClick={() => {
														window.open(
															"/PRST001003?pmIdx=" +
																item.pmIdx,
															"_blank",
															"width=600, height=800, left=100, top=100"
														);
													}}
												>
													{item.pmIdx}
												</td>
												<td>{item.name}</td>
												<td>{item.gradeCode}</td>
												<td>{item.categoryName}</td>
												<td>
													{Util.addComma(
														item.displayPrice
													)}
												</td>
												<td>
													{displayYnText(
														item.displayYn
													)}
												</td>
												<td>
													{displayYnText(
														item.notifyYn
													)}
												</td>
												<td>{useYnText(item.useYn)}</td>
												<td>{item.inventory}</td>
												<td>
													{Util.dateFormat(
														item.regDt
													)}
												</td>
											</tr>
										))}
									</>
								) : (
									<tr>
										<td
											colSpan={999}
											style={{ textAlign: "center" }}
										>
											데이터가 없습니다.
										</td>
									</tr>
								)}
							</tbody>
						</S.table01>
					</S.table01Wrap>
					<S.blank2 />
					<S.plusMinusButton>
						<button onClick={() => addCheckedItem()}>
							<Plus width="0rem" height="0rem" fill="white" />
							<S.bodyText>추가</S.bodyText>
						</button>
						<button onClick={() => delCheckedItem()}>
							<Minus width="0rem" height="0rem" fill="white" />
							<S.bodyText>삭제</S.bodyText>
						</button>
					</S.plusMinusButton>
					<S.blank2 />
					<S.table01Wrap style={{ height: "100%" }}>
						<S.table01>
							<thead>
								<tr>
									<th>
										<S.onlyCheckbox>
											<input
												type="checkbox"
												name=""
												id="delCheckAll"
												onChange={(e) =>
													handleAllCheck(
														e.target.checked,
														"del"
													)
												}
											/>
											<label htmlFor="delCheckAll" />
										</S.onlyCheckbox>
									</th>
									<th>보물번호</th>
									<th>보물명</th>
									<th>보물 등급</th>
									<th>보물 분류</th>
									<th>정가(원)</th>
									<th>보물 노출여부</th>
									<th>뉴스룸 노출여부</th>
									<th>제외 여부</th>
									<th>보유현황</th>
									<th>등록 일시</th>
								</tr>
							</thead>
							<tbody>
								{selectedList && selectedList.length > 0 ? (
									<>
										{selectedList.map((item, i) => (
											<tr key={i}>
												<td>
													<S.onlyCheckbox>
														<input
															type="checkbox"
															name=""
															checked={delCheckItems.includes(
																item
															)}
															onChange={(e) => {
																handleSingleCheck(
																	e.target
																		.checked,
																	item,
																	"del"
																);
															}}
															id={
																"del_" +
																item.pmIdx
															}
														/>
														<label
															htmlFor={
																"del_" +
																item.pmIdx
															}
														/>
													</S.onlyCheckbox>
												</td>
												<td>{item.pmIdx}</td>
												<td>{item.name}</td>
												<td>{item.gradeCode}</td>
												<td>{item.categoryName}</td>
												<td>
													{Util.addComma(
														item.displayPrice
													)}
												</td>
												<td>
													{displayYnText(
														item.displayYn
													)}
												</td>
												<td>
													{displayYnText(
														item.notifyYn
													)}
												</td>
												<td>{useYnText(item.useYn)}</td>
												<td>{item.inventory}</td>
												<td>
													{Util.dateFormat(
														item.displayDt
													)}
												</td>
											</tr>
										))}
									</>
								) : (
									<tr>
										<td
											colSpan={999}
											style={{ textAlign: "center" }}
										>
											데이터가 없습니다.
										</td>
									</tr>
								)}
							</tbody>
						</S.table01>
					</S.table01Wrap>
				</div>
				<S.blank2 />
				<S.flexRowCenter>
					<S.filledButton onClick={() => save()}>
						저장하기
					</S.filledButton>
				</S.flexRowCenter>
			</S.popupPage>
		</>
	);
};
