// 탈퇴 계정 목록
import { useState, useEffect, useRef } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { LoginAtom } from "../Recoil/Atoms/PopupState";
import { UserAtom, LoadingAtom, MenuAtom } from "../Recoil/Atoms/UserState";

import * as S from "../Styles/Style";
import Api from "../libs/api";
import Util from "../Store/Util";
import Page from "../Components/Page";
import Pagination from "../Components/Pagination";

interface list {
	cnt: string;
	mmIdx: string;
	nick: string;
	email: string;
	leaveReason: string;
	leaveDt: string;
	joinDt: string;
	modDt: string;
}

export default () => {
	const location = useLocation();
	const menu = useRecoilValue(MenuAtom);
	let agmIdx: number;
	const user = useRecoilValue(UserAtom);
	const setOpenLogin = useSetRecoilState<boolean>(LoginAtom);
	const setLoading = useSetRecoilState<boolean>(LoadingAtom);
	const [searchParams, setSearchParams] = useSearchParams();
	const page = searchParams.get("page");
	const listSize = 20;
	const [totalItems, setTotalItems] = useState<number>(0);
	const [list, setList] = useState<list[] | null>([]);
	// 검색 옵션
	const searchOptionsRef = useRef({});
	const [searchOptions, setSearchOptions] = useState({});

	// 1페이지로 이동되면 리셋
	const pageResset = (tab?: string) => {
		if (page == "1") {
			user.isLogin && GetList();
		} else {
			setSearchParams({ page: "1" });
		}
	};
	useEffect(() => {
		window.scrollTo(0, 0); // 페이지 이동 시 스크롤 위치 맨 위로 초기화
		user.isLogin && GetList();
	}, [page, user]);

	// 목록 불러오기
	const GetList = async () => {
		setLoading(true);
		console.log(searchOptionsRef.current);
		await Api.get("member/withOutList", {
			...searchOptionsRef.current,
			page: page,
			listSize: listSize,
		})
			.then((res: any) => {
				if (res.data == null) {
					const code = res.response.status;
					if (code == 401) {
						setOpenLogin(true);
					}
				} else {
					console.log(res.data);
					setList(res.data.data.data);
					setTotalItems(res.data.data.totalCnt);
				}
			})
			.catch((error) => {
				console.log(error.response.data, "wwwwww");
			});
		setLoading(false);
	};

	// 공통
	// 검색
	const search = () => {
		searchOptionsRef.current = {
			...searchOptionsRef.current,
			...searchOptions,
		};
		pageResset();
	};
	// 권한 확인(뷰어 제한) 후 액션
	const viewerCheck = (action: () => void) => {
		menu.map((m) => {
			m.sub.map((s) => {
				if (s.link == location.pathname) {
					agmIdx = s.agmIdx;
				}
			});
		});
		Util.viewerCheck(user.grade, agmIdx, () => action());
	};

	return (
		<Page
			title="탈퇴 계정 목록"
			contents={
				<div>
					{/* 검색 영역 */}
					<S.searchArea>
						<S.searchRow>
							<select
								name="searchKey"
								id="searchKey"
								onChange={(e) =>
									setSearchOptions({
										...searchOptions,
										searchKey: e.target.value,
									})
								}
							>
								<option value="">= 선 택 =</option>
								<option value="1">회원번호</option>
								<option value="2">닉네임</option>
								<option value="3">이메일</option>
							</select>
							<input
								type="text"
								id="searchWord"
								onChange={(e) =>
									setSearchOptions({
										...searchOptions,
										searchWord: e.target.value,
									})
								}
							/>
							<S.searchButton onClick={() => search()}>
								<S.headerText>검색하기</S.headerText>
							</S.searchButton>
						</S.searchRow>
					</S.searchArea>
					{/* 테이블 영역 */}
					<S.table01Wrap>
						<S.table01>
							<thead>
								<tr>
									<th>번호</th>
									<th>회원번호</th>
									<th>닉네임</th>
									<th>이메일</th>
									<th>탈퇴사유</th>
									<th>탈퇴 일시</th>
									<th>가입 일시</th>
									<th>최근 접속 일시</th>
								</tr>
							</thead>
							<tbody>
								{list && list.length > 0 ? (
									<>
										{list.map((item, i) => (
											<tr key={i}>
												<td>{i}</td>
												<td>{item.mmIdx}</td>
												<td
													className="Clickable"
													onClick={() => {
														window.open(
															"/MEMB001002?mmIdx=" +
																item.mmIdx,
															"_blank",
															"width=1000, height=800, left=100, top=100"
														);
													}}
												>
													{item.nick}
												</td>
												<td>{item.email}</td>
												<td>{item.leaveReason}</td>
												<td>
													{Util.dateFormat(
														item.leaveDt
													)}
												</td>
												<td>
													{Util.dateFormat(
														item.joinDt
													)}
												</td>
												<td>
													{Util.dateFormat(
														item.modDt
													)}
												</td>
											</tr>
										))}
									</>
								) : (
									<tr>
										<td
											colSpan={999}
											style={{ textAlign: "center" }}
										>
											데이터가 없습니다.
										</td>
									</tr>
								)}
							</tbody>
						</S.table01>
					</S.table01Wrap>
					{/* 페이지네이션 영역 */}
					<Pagination
						totalItems={totalItems} // 데이터 총 개수
						currentPage={
							page && parseInt(page) > 0 ? parseInt(page) : 1
						} // 현재 페이지
						itemCountPerPage={listSize} // 페이지당 보여줄 데이터 개수
					/>
				</div>
			}
		/>
	);
};
