import * as xlsx from "xlsx";

// 금액 단위 콤마
const addComma = (value: number) => {
	let returnString = value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	return returnString;
};
// input 입력되며 콤마
const onChangeComma = (value: number | string) => {
	if (value == 0) return "";
	const comma = (value: number | string) => {
		value = String(value);
		return value.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
	};
	const uncomma = (value: number | string) => {
		value = String(value);
		return value.replace(/[^\d]+/g, "");
	};
	return comma(uncomma(value));
};
// 숫자만 남기기
const onlyNum = (value: string) => {
	var regex = /[^0-9]/g;
	return value.replace(regex, "");
};
// 날짜
const getNow = () => {
	const d = new Date();
	return d.toLocaleDateString();
};
const getNowString = () => {
	const d = new Date();
	const year = d.getFullYear().toString(); // 년
	const month = (d.getMonth() + 1).toString().padStart(2, "0"); // 월
	const day = d.getDate().toString().padStart(2, "0"); // 일
	const hours = d.getHours().toString().padStart(2, "0"); // 시
	const minutes = d.getMinutes().toString().padStart(2, "0"); // 시
	return year + month + day + hours + minutes;
};
// input data 입력용 날짜 형식
const inputDateFormat = (value: Date, type?: string) => {
	var data = "";
	const year = value.getFullYear();
	const month = (value.getMonth() + 1).toString().padStart(2, "0");
	const day = value.getDate().toString().padStart(2, "0");
	const hours = value.getHours().toString().padStart(2, "0"); // 시
	const minutes = value.getMinutes().toString().padStart(2, "0"); // 시
	if (type === "date") {
		data = `${year}-${month}-${day}`;
	} else {
		data = `${year}-${month}-${day}T${hours}:${minutes}`;
	}
	return data;
};
// date로부터 value일 전 날짜
const daysAgo = (date: Date, value: number) => {
	const year = date.getFullYear(); // 년
	const month = date.getMonth(); // 월
	const day = date.getDate(); // 일

	const calc = new Date(year, month, day - value).toLocaleDateString();
	const calcDate = new Date(calc);

	const calcYear = calcDate.getFullYear().toString().padStart(2, "0");
	const calcMonth = (calcDate.getMonth() + 1).toString().padStart(2, "0");
	const calcDay = calcDate.getDate().toString().padStart(2, "0");

	return `${calcYear}${calcMonth}${calcDay}`;
};
const dateFormat = (value: string, type?: string) => {
	var data = "";
	var regex = /[^0-9]/g;
	var number = value.replace(regex, "");
	if (type === "date") {
		data = number.substr(0, 8).replace(/(\d{4})(\d{2})(\d{2})/, "$1.$2.$3");
	} else {
		data = number
			.substr(0, 12)
			.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})/, "$1.$2.$3 $4:$5");
	}
	return data;
};
// 숫자로 변형
const toNumber = (value: string) => {
	var regex = /[^0-9]/g;
	var result = value.replace(regex, "");

	return result ? Number(result) : "";
};
// 탭
const tab = ({ e }: any) => {
	const siblings = (t: any) =>
		[...t.parentElement.children].filter((e) =>
			e.classList.contains("tab")
		);
	siblings(e.target).map((x) => {
		x.setAttribute("class", "tab");
	});
	e.target.classList.add("active");
};
// 정렬
const sorting = ({ e, searchOptionsRef, searchOptions, sortType }: any) => {
	const siblings = (t: any) =>
		[...t.parentElement.children].filter((e) =>
			e.classList.contains("sort")
		);
	if (e.target.classList.contains("down")) {
		searchOptionsRef.current = {
			...searchOptions,
			sort: 1,
			sortType: sortType,
		};
		e.target.classList.remove("down");
		e.target.classList.add("up");
	} else if (e.target.classList.contains("up")) {
		searchOptionsRef.current = {
			...searchOptions,
			sort: 2,
			sortType: sortType,
		};
		e.target.classList.remove("up");
		e.target.classList.add("down");
	} else {
		siblings(e.target).map((x) => {
			x.setAttribute("class", "sort");
		});
		searchOptionsRef.current = {
			...searchOptions,
			sort: 0,
			sortType: sortType,
		};
		e.target.classList.add("down");
	}
};
// 권한 확인(뷰어 제한)
interface grade {
	agIdx: string;
	agmIdx: string;
	amIdx: number;
	grade: number;
	gradeGroup: string;
}
const viewerCheck = (
	userGrade: grade[], // 로그인된 사용자의 권한들
	agmIdx: number, // 확인하고싶은 메뉴의 agmIdx
	action: () => void // 승인된 사용자인 경우 실행될 액션
) => {
	console.log(agmIdx);
	// TODO: MenuAtom에서 agmIdx가져와서 권한 확인
	if (userGrade.filter((item) => Number(item.agmIdx) == agmIdx).length > 0) {
		if (
			userGrade.filter((item) => Number(item.agmIdx) == agmIdx)[0]
				?.grade < 2
		) {
			alert("권한이 없습니다. 마스터에게 문의해 주세요.");
		} else {
			action();
		}
	} else {
		alert("권한이 없습니다. 마스터에게 문의해 주세요.");
	}
};
// 엑셀 다운로드
const excelDownload = (list: any[]) => {
	const ws = xlsx.utils.json_to_sheet(list); // Json 배열의 내용을 엑셀의 시트로 변환
	const wb = xlsx.utils.book_new();

	// 저장
	if (window.confirm("엑셀을 다운 받으시겠습니까?")) {
		xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
		xlsx.writeFile(wb, "Test.xlsx");
	}
};

export default {
	addComma,
	onChangeComma,
	onlyNum,
	getNow,
	getNowString,
	inputDateFormat,
	daysAgo,
	dateFormat,
	toNumber,
	tab,
	sorting,
	viewerCheck,
	excelDownload,
};
