// 제재 계정 목록
import { useState, useEffect, useRef } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { LoginAtom } from "../Recoil/Atoms/PopupState";
import { UserAtom, LoadingAtom, MenuAtom } from "../Recoil/Atoms/UserState";

import * as S from "../Styles/Style";
import Api from "../libs/api";
import Util from "../Store/Util";
import Page from "../Components/Page";
import Pagination from "../Components/Pagination";
import Filter from "../Components/Filter";

// 이미지
import { ReactComponent as Plus } from "../Images/plus.svg";
import { ReactComponent as Minus } from "../Images/minus.svg";

interface list {
	cnt: string;
	mmIdx: string;
	nick: string;
	stateCode: string;
	sanctionEndDt: string;
	sanctionStDt: string;
	reason: string;
}

export default () => {
	const location = useLocation();
	const menu = useRecoilValue(MenuAtom);
	let agmIdx: number;
	const user = useRecoilValue(UserAtom);
	const setOpenLogin = useSetRecoilState<boolean>(LoginAtom);
	const setLoading = useSetRecoilState<boolean>(LoadingAtom);
	const [isOpenFilter, setOpenFilter] = useState<boolean>(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const page = searchParams.get("page");
	const listSize = 20;
	const [totalItems, setTotalItems] = useState<number>(0);
	const [list, setList] = useState<list[] | null>([]);
	// 검색 옵션
	const searchOptionsRef = useRef({});
	const [searchOptions, setSearchOptions] = useState({});
	// 필터 옵션
	const filterOptionsRef = useRef({});
	const [stateCode, setStateCode] = useState<string[]>([]);
	const [isApplied, setIsApplied] = useState<boolean>(false);
	const filterList = [
		{
			title: "제재 종류",
			name: "stateCode",
			items: [
				{ text: "임시 제한", value: "-10" },
				{ text: "영구 제한", value: "-30" },
			],
			option: stateCode,
			setOption: setStateCode,
		},
	];

	// 1페이지로 이동되면 리셋
	const pageResset = (tab?: string) => {
		if (page == "1") {
			user.isLogin && GetList();
		} else {
			setSearchParams({ page: "1" });
		}
	};
	useEffect(() => {
		window.scrollTo(0, 0); // 페이지 이동 시 스크롤 위치 맨 위로 초기화
		user.isLogin && GetList();
	}, [page, user]);

	// 목록 불러오기
	const GetList = async () => {
		setLoading(true);
		await Api.get("member/sanctionList", {
			...searchOptionsRef.current,
			...filterOptionsRef.current,
			page: page,
			listSize: listSize,
		})
			.then((res: any) => {
				if (res.data == null) {
					const code = res.response.status;
					if (code == 401) {
						setOpenLogin(true);
					}
				} else {
					console.log(res.data.data.data);
					setList(res.data.data.data);
					setTotalItems(res.data.data.cnt);
				}
			})
			.catch((error) => {
				console.log(error.response.data, "wwwwww");
			});
		setLoading(false);
	};
	// 상태
	const stateText = (state: string | null) => {
		switch (state) {
			case "-10":
				return "임시 제한";
			case "-30":
				return "영구 제한";
			default:
				return "-";
		}
	};
	// 제재 해제
	const sanctionClear = async (mmIdx: string) => {
		if (window.confirm("해당 계정의 제재를 해제하시겠습니까?")) {
			await Api.patch(`member/memberSanctionOut/${mmIdx}`).then(
				(res: any) => {
					console.log(res);
					if (res.status === 200) {
						alert("완료되었습니다.");
					}
				}
			);
		}
	};

	// 공통
	// 검색
	const search = () => {
		searchOptionsRef.current = {
			...searchOptionsRef.current,
			...searchOptions,
		};
		pageResset();
	};
	// 필터 선택 취소
	const filterRefresh = () => {
		setStateCode([]);
	};
	// 필터 적용
	const filter = () => {
		filterOptionsRef.current = { stateCode: stateCode.join() };
		pageResset();
		setIsApplied(stateCode.length > 0);
	};
	// 권한 확인(뷰어 제한) 후 액션
	const viewerCheck = (action: () => void) => {
		menu.map((m) => {
			m.sub.map((s) => {
				if (s.link == location.pathname) {
					agmIdx = s.agmIdx;
				}
			});
		});
		Util.viewerCheck(user.grade, agmIdx, () => action());
	};

	return (
		<Page
			title="제재 계정 목록"
			contents={
				<div>
					{/* 검색 영역 */}
					<S.searchArea>
						<S.searchRow>
							<select
								name="searchKey"
								id="searchKey"
								onChange={(e) =>
									setSearchOptions({
										...searchOptions,
										searchKey: e.target.value,
									})
								}
							>
								<option value="">= 선 택 =</option>
								<option value="1">회원번호</option>
								<option value="2">닉네임</option>
							</select>
							<input
								type="text"
								id="searchWord"
								onChange={(e) =>
									setSearchOptions({
										...searchOptions,
										searchWord: e.target.value,
									})
								}
							/>
							<S.searchButton onClick={() => search()}>
								<S.headerText>검색하기</S.headerText>
							</S.searchButton>
							<S.searchButton
								onClick={() => {
									viewerCheck(() => {
										if (
											window.confirm(
												"엑셀을 다운 받으시겠습니까?"
											)
										) {
											alert("엑셀다운로드 개발 전");
										}
									});
								}}
								color={S.color.primaryVariant}
							>
								<S.headerText>엑셀 다운로드</S.headerText>
							</S.searchButton>
							<S.filterButton
								onClick={() => {
									setOpenFilter(!isOpenFilter);
								}}
								className={`${isApplied && "applied"}`}
							>
								{isOpenFilter ? (
									<Minus
										width="2rem"
										height="2rem"
										fill="white"
									/>
								) : (
									<Plus
										width="2rem"
										height="2rem"
										fill="white"
									/>
								)}
							</S.filterButton>
						</S.searchRow>
						{/* 필터영역 */}
						<S.filterArea className={`${isOpenFilter && "expend"}`}>
							<S.filterWrap>
								{filterList.map((filter: any, fi: number) => {
									return (
										<li key={fi}>
											<h2>{filter.title}</h2>
											<ul>
												<Filter
													fi={fi}
													filter={filter} // 필터 아이템 리스트
													option={filter.option}
													setOption={filter.setOption}
												/>
											</ul>
										</li>
									);
								})}
							</S.filterWrap>
							<S.flexRowEnd>
								<button onClick={() => filter()}>적용</button>
								<button onClick={() => filterRefresh()}>
									전체 해제
								</button>
							</S.flexRowEnd>
						</S.filterArea>
					</S.searchArea>
					{/* 버튼 영역 */}
					<S.asideButtons>
						<button
							onClick={() => {
								viewerCheck(() =>
									window.open(
										"/MEMB002002",
										"_blank",
										"width=600, height=300, left=100, top=100"
									)
								);
							}}
						>
							제재하기
						</button>
					</S.asideButtons>
					{/* 테이블 영역 */}
					<S.table01Wrap>
						<S.table01>
							<thead>
								<tr>
									<th>번호</th>
									<th>회원번호</th>
									<th>닉네임</th>
									<th>제재 종류</th>
									<th>제재 시작 일시</th>
									<th>제재 종료 일시</th>
									<th>제재 사유</th>
									<th>제재 횟수(번)</th>
									<th>관리</th>
								</tr>
							</thead>
							<tbody>
								{list && list.length > 0 ? (
									<>
										{list.map((item, i) => (
											<tr key={i}>
												<td>{i}</td>
												<td>{item.mmIdx}</td>
												<td
													className="Clickable"
													onClick={() => {
														window.open(
															"/MEMB001002?mmIdx=" +
																item.mmIdx,
															"_blank",
															"width=1000, height=800, left=100, top=100"
														);
													}}
												>
													{item.nick}
												</td>
												<td>
													{stateText(item.stateCode)}
												</td>
												<td>
													{Util.dateFormat(
														item.sanctionStDt
													)}
												</td>
												<td>
													{Util.dateFormat(
														item.sanctionEndDt
													)}
												</td>
												<td>{item.reason}</td>
												<td>
													{Util.addComma(
														Number(item.cnt)
													)}
												</td>
												<td>
													<S.tableButton
														onClick={() => {
															sanctionClear(
																item.mmIdx
															);
														}}
													>
														<S.headerText>
															제재 해제
														</S.headerText>
													</S.tableButton>
												</td>
											</tr>
										))}
									</>
								) : (
									<tr>
										<td
											colSpan={999}
											style={{ textAlign: "center" }}
										>
											데이터가 없습니다.
										</td>
									</tr>
								)}
							</tbody>
						</S.table01>
					</S.table01Wrap>
					{/* 페이지네이션 영역 */}
					<Pagination
						totalItems={totalItems} // 데이터 총 개수
						currentPage={
							page && parseInt(page) > 0 ? parseInt(page) : 1
						} // 현재 페이지
						itemCountPerPage={listSize} // 페이지당 보여줄 데이터 개수
					/>
				</div>
			}
		/>
	);
};
