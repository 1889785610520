import axios from "axios";
import { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { LoginAtom } from "./Recoil/Atoms/PopupState";
import { UserAtom, LoadingAtom } from "./Recoil/Atoms/UserState";

import * as S from "./Styles/Style";

import Api from "./libs/api";

// 이미지
import { ReactComponent as Slash } from "./Images/slash.svg";
import { ReactComponent as Lock } from "./Images/lock.svg";
import { ReactComponent as User } from "./Images/user.svg";
// 화면
import ADMI000001 from "./Screens/ADMI000001";
import ADMI001001 from "./Screens/ADMI001001";
import ADMI001002 from "./Screens/ADMI001002";
import MEMB001001 from "./Screens/MEMB001001";
import MEMB001002 from "./Screens/MEMB001002";
import MEMB001003 from "./Screens/MEMB001003";
import MEMB002001 from "./Screens/MEMB002001";
import MEMB003001 from "./Screens/MEMB003001";
import MEMB002002 from "./Screens/MEMB002002";
import PRST001001 from "./Screens/PRST001001";
import PRST001002 from "./Screens/PRST001002";
import PRST001003 from "./Screens/PRST001003";
import PRST001004 from "./Screens/PRST001004";
import PRST001005 from "./Screens/PRST001005";
import PRST001006 from "./Screens/PRST001006";
import PRST002001 from "./Screens/PRST002001";
import PRST003001 from "./Screens/PRST003001";
import PREV001001 from "./Screens/PREV001001";
import CHPO001001 from "./Screens/CHPO001001";
import CHPO001002 from "./Screens/CHPO001002";
import CHPO002001 from "./Screens/CHPO002001";
import MANA001001 from "./Screens/MANA001001";
import MANA001002 from "./Screens/MANA001002";
import MANA002001 from "./Screens/MANA002001";
import MANA002002 from "./Screens/MANA002002";
import MANA003001 from "./Screens/MANA003001";
import MANA003002 from "./Screens/MANA003002";
import MANA005001 from "./Screens/MANA005001";
import MANA005002 from "./Screens/MANA005002";

const FullPopup = styled(S.flexRowCenter)`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	background-color: white;
	flex-direction: column;
	z-index: 9999;
	form {
		width: 90%;
		max-width: 40rem;
	}
`;
const LoginInput = styled(S.flexRowCenter)`
	border: 1px solid ${S.color.gray02};
	padding: 1.5rem;
	border-radius: 1rem;
	position: relative;
	margin-bottom: 2rem;
	svg {
		margin-right: 1rem;
	}
	select {
		min-width: 20%;
		font-size: 1.2rem;
		margin-left: 1rem;
		padding: 0 1rem;
		border-left: 1px solid ${S.color.gray02};
	}
	input {
		flex-grow: 1;
		font-size: 1.4rem;
	}
	&.pw {
		margin-bottom: 1rem;
	}
	&::after {
		content: "";
		display: block;
		position: absolute;
		top: calc(100% + 0.2rem);
		left: 0.5rem;
		color: ${S.color.gray03};
	}
	&.en::after {
		content: "영어, 특수문자, 숫자만 사용 가능";
	}
	&.pw::after {
		display: none;
		content: "비밀번호가 일치하지 않습니다.";
	}
	/* 입력 오류 시 */
	&.error {
		border-color: ${S.color.red};
	}
	&.error::after {
		display: block;
		color: ${S.color.red};
	}
	&.error.pw {
		margin-bottom: 2rem;
	}
`;

const LoginButton = styled.button`
	width: 100%;
	border-radius: 1rem;
	padding: 1rem 3rem;
	background-color: ${S.color.primary};
	span {
		color: white;
		font-size: 1.6rem;
	}
	&.disabled,
	&:disabled {
		background-color: ${S.color.gray03};
	}
`;

function App() {
	const navigate = useNavigate();
	const [isOpenWarning, setOpenWarning] = useState<boolean>(false);
	const [warningText, setWarningText] = useState<string>("권한이 없습니다.");
	const [isOpenLogin, setOpenLogin] = useRecoilState<boolean>(LoginAtom);
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);
	const [isOpenSignup, setOpenSignup] = useState<boolean>(false);
	const apiUrl = process.env.REACT_APP_API_URL;
	const [user, setUser] = useRecoilState<{
		isLogin: boolean;
		amIdx: number;
		depart: string;
		levelCode: number;
		loginDt: string;
		name: string;
		grade: {
			agIdx: string;
			agmIdx: string;
			amIdx: number;
			grade: number;
			gradeGroup: string;
		}[];
	}>(UserAtom);

	const GetUser = async () => {
		await Api.get("manager/managerGrade")
			.then((res: any) => {
				if (res.data == null) {
					setUser({ ...user, isLogin: false });
					setOpenLogin(true);
					console.log("로그인 정보 없음");
				} else {
					console.log(res.data.data);
					const data = res.data.data;
					setUser({
						...data,
						isLogin: true,
					});
					// 미승인 시 화면 노출_ 권한이 없습니다. 마스터에게 문의해 주세요.
					if (data.grantYn == 0) {
						setWarningText("권한이 없습니다.");
						setOpenWarning(true);
					} else if (data.grantYn == -1) {
						// 탈퇴 시 화면 노출_ 탈퇴된 회원입니다. 마스터에게 문의해 주세요.
						setWarningText("탈퇴된 회원입니다.");
						setOpenWarning(true);
					} else {
						// 승인 시 이동_ 홈 화면 - 대시보드로 이동
					}
				}
			})
			.catch((error) => {
				console.log(error.response.data, "wwwwww");
			});
	};
	useEffect(() => {
		loginStateReset();
		signupStateReset();
		GetUser();
	}, []);

	const [loginState, setLoginState] = useState<{
		id: string;
		pw: string;
	}>({
		id: "",
		pw: "",
	});
	const [signupState, setSignupState] = useState<{
		id: string;
		pw: string;
		name: string;
		depart: string;
	}>({
		id: "",
		pw: "",
		name: "",
		depart: "",
	});
	const [pwVerifyState, setPwVerifyState] = useState<string>("");
	const [valid, setValid] = useState<boolean>(false);

	// 폼 리셋
	const loginStateReset = () => {
		setLoginState({
			id: "",
			pw: "",
		});
	};
	const signupStateReset = () => {
		setSignupState({
			id: "",
			pw: "",
			name: "",
			depart: "",
		});
		setPwVerifyState("");
	};

	// 회원가입 인풋 입력 시 유효성 체크
	// 정규식 모음
	const inputRegexs = {
		// 아이디 : 영어+특수문자+숫자 사용 가능
		idRegex: /^[a-zA-z0-9!@#$%^*+=-]{1,20}$/,
		// 비밀번호 : 영어+특수문자+숫자 사용 가능
		pwRegex: /^[a-zA-z0-9!@#$%^*+=-]{1,20}$/,
		// 이름 : 한글 사용 가능
		nameRegex: /^[ㄱ-ㅎㅏ-ㅣ가-힣]{0,10}$/,
	};
	const handleChange = (e: any) => {
		e.preventDefault();
		const signupPwVerify = document.getElementById(
			"signupPwVerify"
		) as HTMLInputElement;
		e.target.parentElement.classList.remove("error");
		const name = e.target.name;
		if (
			e.target.value !== "" &&
			(name == "id" || name == "pw" || name == "pwVerify")
		) {
			// 유효성 체크
			// 아이디 영어+특수문자+숫자 사용 가능
			// 비밀번호 영어, 특수문자, 숫자 사용 가능
			if (
				(name == "id" && inputRegexs.idRegex.test(e.target.value)) ||
				(name == "pw" && inputRegexs.pwRegex.test(e.target.value)) ||
				(name == "pwVerify" && e.target.value == signupState.pw)
				// ||
				// (name == "name" &&
				// 	inputRegexs.nameRegex.test(e.target.value)) ||
				// (name == "depart" &&
				// 	inputRegexs.nameRegex.test(signupState.name))
			) {
				e.target.parentElement.classList.remove("error");
			} else {
				e.target.parentElement.classList.add("error");
			}
		}
		// 비밀번호 일치 여부 확인
		if (name == "pw" && pwVerifyState !== "") {
			if (e.target.value == pwVerifyState) {
				signupPwVerify.classList.remove("error");
			} else {
				signupPwVerify.classList.add("error");
			}
		}
		// state에 값 넣어주기
		if (name == "pwVerify") {
			setPwVerifyState(e.target.value);
		} else {
			setSignupState({
				...signupState,
				[e.target.name]: e.target.value,
			});
		}
	};
	// 회원가입 버튼 활성화 여부 확인
	useEffect(() => {
		if (!isOpenSignup) {
			return;
		}
		//
		const signupButton = document.getElementById(
			"signupButton"
		) as HTMLButtonElement;
		if (
			signupState.id == "" ||
			signupState.pw == "" ||
			pwVerifyState == "" ||
			signupState.pw !== pwVerifyState ||
			signupState.name == "" ||
			signupState.depart == ""
			// 각 입력값 유효해야 활성화
		) {
			signupButton.classList.add("disabled");
		} else {
			signupButton.classList.remove("disabled");
		}

		// 유효성 검사를 통과하지 못한 경우 버튼 비활성화
		const signupId = document.getElementById("signupId") as HTMLDivElement;
		const signupPw = document.getElementById("signupPw") as HTMLDivElement;
		const signupPwVerify = document.getElementById(
			"signupPwVerify"
		) as HTMLDivElement;
		const signupName = document.getElementById(
			"signupName"
		) as HTMLDivElement;
		if (
			signupId.classList.contains("error") ||
			signupPw.classList.contains("error") ||
			signupPwVerify.classList.contains("error") ||
			signupName.classList.contains("error")
		) {
			setValid(false);
		} else {
			setValid(true);
		}
	}, [isOpenSignup, signupState, pwVerifyState]);

	// 로그인 버튼 활성화 여부 확인
	useEffect(() => {
		if (!isOpenLogin) {
			return;
		}
		const loginButton = document.getElementById(
			"loginButton"
		) as HTMLButtonElement;
		if (loginState.id == "" || loginState.pw == "") {
			loginButton.classList.add("disabled");
		} else {
			loginButton.classList.remove("disabled");
		}
	}, [loginState]);

	// 비밀번호 확인 입력 영역 벗어날 시
	const blurHandler = (e: any) => {
		e.preventDefault();
		const signupPwVerify = document.getElementById(
			"signupPwVerify"
		) as HTMLInputElement;
		// 비밀번호확인 영역 비어있는 경우 리턴
		if (pwVerifyState == "") {
			return;
		}
		// 비밀번호, 비밀번호확인 일치여부 확인
		if (signupState.pw == pwVerifyState) {
			signupPwVerify.classList.remove("error");
		} else {
			signupPwVerify.classList.add("error");
		}
	};

	// 로그인 버튼 클릭
	const loginSubmit = async (e: any) => {
		e.preventDefault();
		// 입력되지 않은 값 있으면 리턴
		if (loginState.id == "" || loginState.pw == "") {
			return;
		}
		// await Api.get(`manager/login`, {
		// 	id: loginState.id,
		// 	pw: loginState.pw,
		// });
		await axios
			.get(apiUrl + "manager/login", {
				params: { id: loginState.id, pw: loginState.pw },
			})
			.then((res: any) => {
				if (res.status == 200 || res.status == 201) {
					signupStateReset();
					setOpenLogin(false);
					navigate("/");
					localStorage.setItem(
						"token",
						// JSON.stringify(res.data.result[1])
						JSON.stringify({
							accessToken: res.data.result[1].accessToken,
							refreshToken: res.data.result[1].refreshToken,
						})
					);
					GetUser();
					// 입력된 내용 지우기
					setLoginState({
						id: "",
						pw: "",
					});
				}
			})
			.catch((error) => {
				console.log(error.response.data);
				if (error.response.data.error == -402) {
					// 아이디 오기입 시
					alert("아이디를 확인해 주세요.");
					// 입력된 내용 지우기
					setLoginState({
						...loginState,
						id: "",
					});
				} else if (error.response.data.error == -412) {
					// 비밀번호 오기입 시
					alert("비밀번호를 확인해 주세요.");
					// 입력된 내용 지우기
					setLoginState({
						...loginState,
						pw: "",
					});
				}
			});
	};

	// 회원가입 버튼 클릭
	const signupSubmit = async (e: any) => {
		e.preventDefault();
		// disabled 클래스 갖고있으면 리턴
		if (e.target.className == "disabled") {
			return;
		}

		console.log(signupState);

		await axios
			.post(apiUrl + `manager/join`, {
				id: signupState.id,
				pw: signupState.pw,
				name: e.target.name.value,
				depart: e.target.depart.value,
				useYn: 1,
			})
			.then((res) => {
				console.log(res);
				if (res.status == 200 || res.status == 201) {
					signupStateReset();
					alert("회원가입이 완료되었습니다.");
					setOpenSignup(false);
					setOpenLogin(true);
				}
			})
			.catch((error) => {
				console.log(error.response.data);
				if (error.response.data.error == -412) {
					// 아이디 중복
					alert("이미 사용 중인 아이디 입니다.");
					// 입력된 내용 지우기
					setSignupState({
						...signupState,
						id: "",
					});
				}
			});
	};

	return (
		<>
			{/* TODO: 로그인여부에 따라 화면 이동 */}
			<Routes>
				{/* 대시보드 */}
				<Route path="/" element={<ADMI000001 />} />
				{/* 관리자 목록 */}
				<Route path="/ADMI001001" element={<ADMI001001 />} />
				{/* 관리자 권한 수정 */}
				<Route path="/ADMI001002" element={<ADMI001002 />} />
				{/* 회원 목록 */}
				<Route path="/MEMB001001" element={<MEMB001001 />} />
				{/* 회원 상세정보 */}
				<Route path="/MEMB001002" element={<MEMB001002 />} />
				{/* 회원 상세정보 이력 */}
				<Route path="/MEMB001003" element={<MEMB001003 />} />
				{/* 제재 계정 목록 */}
				<Route path="/MEMB002001" element={<MEMB002001 />} />
				{/* 탈퇴 계정 목록 */}
				<Route path="/MEMB003001" element={<MEMB003001 />} />
				{/* 제재하기 */}
				<Route path="/MEMB002002" element={<MEMB002002 />} />
				{/* 보물 목록 */}
				<Route path="/PRST001001" element={<PRST001001 />} />
				{/* 보물 일괄 미노출 */}
				<Route path="/PRST001002" element={<PRST001002 />} />
				{/* 보물 등록 */}
				<Route path="/PRST001003" element={<PRST001003 />} />
				{/* 보물 일괄 등록 */}
				<Route path="/PRST001004" element={<PRST001004 />} />
				{/* 유의사항 수정 */}
				<Route path="/PRST001005" element={<PRST001005 />} />
				{/* 보물 일괄 수정 */}
				<Route path="/PRST001006" element={<PRST001006 />} />
				{/* 보물 상자 */}
				<Route path="/PRST002001" element={<PRST002001 />} />
				{/* 유니크 보물 목록 */}
				<Route path="/PRST003001" element={<PRST003001 />} />
				{/* 미리보기 */}
				<Route path="/PREV001001" element={<PREV001001 />} />
				{/* 관리자 지급/회수 목록 */}
				<Route path="/CHPO001001" element={<CHPO001001 />} />
				{/* 관리자 지급/회수 */}
				<Route path="/CHPO001002" element={<CHPO001002 />} />
				{/* 결제 내역 목록 */}
				<Route path="/CHPO002001" element={<CHPO002001 />} />
				{/* 공지사항 */}
				<Route path="/MANA001001" element={<MANA001001 />} />
				{/* 공지사항 등록 */}
				<Route path="/MANA001002" element={<MANA001002 />} />
				{/* 자주 묻는 질문 */}
				<Route path="/MANA002001" element={<MANA002001 />} />
				{/* 자주 묻는 질문 등록 */}
				<Route path="/MANA002002" element={<MANA002002 />} />
				{/* 1:1 문의 */}
				<Route path="/MANA003001" element={<MANA003001 />} />
				{/* 1:1 문의 상세 */}
				<Route path="/MANA003002" element={<MANA003002 />} />
				{/* 배송 목록 */}
				<Route path="/MANA005001" element={<MANA005001 />} />
				{/* 배송 상세보기 */}
				<Route path="/MANA005002" element={<MANA005002 />} />
			</Routes>
			{/* 권한 없음 */}
			{isOpenWarning && (
				<FullPopup>
					<Slash fill={S.color.gray02} width="10rem" height="10rem" />
					<S.blank2 />
					<S.headerText fontSize="2.4rem">{warningText}</S.headerText>
					<S.blank1 />
					<S.bodyText fontSize="1.4rem">
						마스터에게 문의해 주세요.
					</S.bodyText>
					<S.blank5 />
					<S.textButton
						onClick={() => {
							localStorage.removeItem("token");
							setOpenWarning(false);
							setOpenLogin(true);
						}}
					>
						<S.headerText color={S.color.gray03}>
							Logout
						</S.headerText>
					</S.textButton>
					<S.blank5 />
				</FullPopup>
			)}
			{/* 로그인 */}
			{isOpenLogin && (
				<FullPopup>
					<S.headerText fontSize="4rem" color={S.color.primary}>
						Login
					</S.headerText>
					<S.blank3 />
					<form onSubmit={loginSubmit}>
						<LoginInput style={{ marginBottom: "1rem" }}>
							<User
								fill={S.color.gray03}
								width="2rem"
								height="2rem"
							/>
							<input
								type="text"
								name="id"
								id="id"
								value={loginState.id}
								onChange={(e) =>
									setLoginState({
										...loginState,
										id: e.target.value,
									})
								}
								placeholder="아이디를 입력해 주세요."
								required
							/>
						</LoginInput>
						<S.blank1 />
						<LoginInput>
							<Lock
								fill={S.color.gray03}
								width="2rem"
								height="2rem"
							/>
							<input
								type="password"
								id="pw"
								name="pw"
								value={loginState.pw}
								onChange={(e) =>
									setLoginState({
										...loginState,
										pw: e.target.value,
									})
								}
								placeholder="비밀번호를 입력해 주세요."
								autoComplete="off"
								required
							/>
						</LoginInput>
						<S.blank2 />
						<LoginButton
							type="submit"
							id="loginButton"
							className="disabled"
						>
							<S.headerText>로그인</S.headerText>
						</LoginButton>
					</form>
					<S.blank3 />
					<p>
						<S.bodyText>계정이 없으신가요? </S.bodyText>
						<a
							onClick={() => {
								loginStateReset();
								signupStateReset();
								setOpenLogin(false);
								setOpenSignup(true);
							}}
						>
							<S.headerText
								color={S.color.primary}
								fontSize="1.2rem"
							>
								회원가입
							</S.headerText>
						</a>
					</p>
				</FullPopup>
			)}
			{/* 회원가입 */}
			{isOpenSignup && (
				<FullPopup>
					<S.headerText fontSize="4rem" color={S.color.primary}>
						Signup
					</S.headerText>
					<S.blank3 />
					<form onSubmit={signupSubmit} id="signupForm">
						<LoginInput className="en" id="signupId">
							<User fill={S.color.gray03} width="2rem" />
							<input
								type="text"
								id="id"
								name="id"
								value={signupState.id}
								onChange={handleChange}
								placeholder="아이디를 입력해 주세요."
								required
							/>
						</LoginInput>
						<p></p>
						<LoginInput className="en " id="signupPw">
							<Lock
								fill={S.color.gray03}
								width="2rem"
								height="2rem"
							/>
							<input
								type="password"
								id="pw"
								name="pw"
								value={signupState.pw}
								onChange={handleChange}
								onBlur={blurHandler}
								placeholder="비밀번호를 입력해 주세요."
								autoComplete="off"
								required
							/>
						</LoginInput>
						<LoginInput className="pw " id="signupPwVerify">
							<Lock
								fill={S.color.gray03}
								width="2rem"
								height="2rem"
							/>
							<input
								type="password"
								id="pwVerify"
								name="pwVerify"
								value={pwVerifyState}
								onChange={handleChange}
								onBlur={blurHandler}
								placeholder="비밀번호 확인을 입력해 주세요."
								autoComplete="off"
								required
							/>
						</LoginInput>
						<LoginInput className="kr" id="signupName">
							<input
								type="text"
								id="name"
								name="name"
								value={signupState.name}
								onChange={handleChange}
								placeholder="이름"
								required
								autoComplete="on"
							/>
							<select
								name="depart"
								id="depart"
								onChange={handleChange}
								required
							>
								<option value="">부서</option>
								<option value="보물선 운영팀">
									보물선 운영팀
								</option>
								<option value="경영지원팀">경영지원팀</option>
								<option value="개발팀">개발팀</option>
								<option value="기획팀">기획팀</option>
								<option value="디자인팀">디자인팀</option>
								<option value="마케팅팀">마케팅팀</option>
								<option value=">이사로 운영팀">
									이사로 운영팀
								</option>
								<option value="인사팀">인사팀</option>
								<option value="기타">기타</option>
							</select>
						</LoginInput>
						<S.blank3 />
						<LoginButton
							type="submit"
							id="signupButton"
							className="disabled"
							disabled={!valid}
						>
							<S.headerText>회원가입</S.headerText>
						</LoginButton>
					</form>
					<S.blank3 />
					<p>
						<S.bodyText>계정이 이미 있으신가요? </S.bodyText>
						<a
							onClick={() => {
								loginStateReset();
								signupStateReset();
								setOpenSignup(false);
								setOpenLogin(true);
							}}
						>
							<S.headerText
								color={S.color.primary}
								fontSize="1.2rem"
							>
								로그인
							</S.headerText>
						</a>
					</p>
				</FullPopup>
			)}
			{/* 로딩중 */}
			{isLoading && (
				<FullPopup style={{ backgroundColor: "rgba(255,255,255,0.8)" }}>
					잠시만 기다려 주세요.
				</FullPopup>
			)}
		</>
	);
}

export default App;
