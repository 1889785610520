// 자주 묻는 질문
import { useState, useEffect, useRef } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { LoginAtom } from "../Recoil/Atoms/PopupState";
import { UserAtom, LoadingAtom, MenuAtom } from "../Recoil/Atoms/UserState";

import * as S from "../Styles/Style";
import Api from "../libs/api";
import Util from "../Store/Util";
import Page from "../Components/Page";
import Pagination from "../Components/Pagination";
import Filter from "../Components/Filter";

// 이미지
import { ReactComponent as Plus } from "../Images/plus.svg";
import { ReactComponent as Minus } from "../Images/minus.svg";

interface list {
	adminName: string;
	amIdx: string;
	answerText: string;
	bfIdx: string;
	categoryCode: string;
	categoryName: string;
	displayYn: number;
	displayYnNm: string;
	openDt: string;
	orderNumber: number;
	questionText: string;
	regDt: string;
	regIdx: number;
	useYn: number;
}

export default () => {
	const location = useLocation();
	const menu = useRecoilValue(MenuAtom);
	let agmIdx: number;
	const user = useRecoilValue(UserAtom);
	const setOpenLogin = useSetRecoilState<boolean>(LoginAtom);
	const setLoading = useSetRecoilState<boolean>(LoadingAtom);
	const [isOpenFilter, setOpenFilter] = useState<boolean>(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const page = searchParams.get("page");
	const listSize = 20;
	const [totalItems, setTotalItems] = useState<number>(0);
	const [list, setList] = useState<list[] | null>([]);
	// 검색 옵션
	const searchOptionsRef = useRef({});
	const [searchOptions, setSearchOptions] = useState({});
	// 검색 일자
	const now = new Date(Util.getNow());
	const [searchStrDt, setSearchStrDt] = useState<string>("");
	const [searchEndDt, setSearchEndDt] = useState<string>(
		Util.inputDateFormat(now, "date")
	);
	// 필터 옵션
	const filterOptionsRef = useRef({});
	const [categoryCode, setCategoryCode] = useState<string[]>([]);
	const [displayYn, setDisplayYn] = useState<string[]>([]);
	const [isApplied, setIsApplied] = useState<boolean>(false);
	const filterList = [
		{
			title: "분류",
			name: "categoryCode",
			items: [
				{ text: "이용", value: "1" },
				{ text: "결제", value: "2" },
				{ text: "배송", value: "4" },
				{ text: "회원", value: "7" },
				{ text: "기타", value: "6" },
			],
			option: categoryCode,
			setOption: setCategoryCode,
		},
		{
			title: "노출 여부",
			name: "displayYn",
			items: [
				{ text: "노출", value: "1" },
				{ text: "미노출", value: "0" },
			],
			option: displayYn,
			setOption: setDisplayYn,
		},
	];

	// 1페이지로 이동되면 리셋
	const pageResset = (tab?: string) => {
		if (page == "1") {
			user.isLogin && GetList();
		} else {
			setSearchParams({ page: "1" });
		}
	};
	useEffect(() => {
		window.scrollTo(0, 0); // 페이지 이동 시 스크롤 위치 맨 위로 초기화
		user.isLogin && GetList();
	}, [page, user]);

	// 목록 불러오기
	const GetList = async () => {
		setLoading(true);
		console.log(filterOptionsRef.current);
		await Api.get("board/faq/list", {
			...searchOptionsRef.current,
			...filterOptionsRef.current,
			page: page,
			listSize: listSize,
		})
			.then((res: any) => {
				if (res.data == null) {
					const code = res.response.status;
					if (code == 401) {
						setOpenLogin(true);
					}
				} else {
					console.log(res.data.data);
					setList(res.data.data.faqList);
					setTotalItems(res.data.data.faqListCnt);
				}
			})
			.catch((error) => {
				console.log(error.response.data, "wwwwww");
			});
		setLoading(false);
	};
	// 분류
	const categoryText = (categoryCode: string) => {
		switch (categoryCode) {
			case "1":
				return "이용";
			case "2":
				return "결제";
			case "4":
				return "배송";
			case "6":
				return "기타";
			case "7":
				return "회원";
			case "8":
				return "이벤트";
			default:
				return "-";
		}
	};
	// 노출여부
	const displayYnText = (displayYn: number) => {
		switch (displayYn) {
			case 0:
				return "미노출";
			case 1:
				return "노출";
			default:
				return "-";
		}
	};

	// 공통
	// 일자
	const setDate = (value: any) => {
		const year = now.getFullYear(); // 년
		const month = now.getMonth(); // 월
		const day = now.getDate(); // 일

		if (value == "") {
			setSearchStrDt("");
		} else {
			const start = new Date(
				year,
				month,
				day - value
			).toLocaleDateString();
			const startDt = new Date(start);

			setSearchStrDt(Util.inputDateFormat(startDt, "date"));
		}
		setSearchEndDt(Util.inputDateFormat(now, "date"));
	};
	useEffect(() => {
		setSearchOptions({
			...searchOptions,
			searchStrDt: Util.onlyNum(searchStrDt),
			searchEndDt: Util.onlyNum(searchEndDt),
		});
	}, [searchStrDt, searchEndDt]);
	// 검색
	const search = () => {
		searchOptionsRef.current = {
			...searchOptionsRef.current,
			...searchOptions,
		};
		pageResset();
	};
	// 필터 선택 취소
	const filterRefresh = () => {
		setCategoryCode([]);
		setDisplayYn([]);
	};
	// 필터 적용
	const filter = () => {
		filterOptionsRef.current = {
			displayYn: displayYn.length >= 2 ? null : displayYn,
			categoryCode: categoryCode.join(),
		};
		pageResset();
		setIsApplied(displayYn.length > 0 || categoryCode.length > 0);
	};
	// 권한 확인(뷰어 제한) 후 액션
	const viewerCheck = (action: () => void) => {
		menu.map((m) => {
			m.sub.map((s) => {
				if (s.link == location.pathname) {
					agmIdx = s.agmIdx;
				}
			});
		});
		Util.viewerCheck(user.grade, agmIdx, () => action());
	};
	// 미노출 처리
	const displayYnPatch = async (bfIdx: string) => {
		if (window.confirm("해당 내용을 미노출하시겠습니까?")) {
			console.log(bfIdx);
			await Api.patch("board/faq/update", {
				bfIdx: bfIdx,
				displayYn: "0",
			})
				.then((res: any) => {
					console.log(res);
					const data = res.data;
					if (data == undefined) {
						if (res.response.data.error == -400) {
							alert(`-400`);
						}
					} else if (
						data.statusCode == 200 ||
						data.statusCode == 201
					) {
						alert("완료되었습니다.");
						window.location.reload();
					}
				})
				.catch((error) => {});
		}
	};

	return (
		<Page
			title="자주 묻는 질문"
			contents={
				<div>
					{/* 검색 영역 */}
					<S.searchArea>
						<S.searchRow>
							<input
								type="date"
								max={"9999-12-31"}
								name="searchStrDt"
								id="searchStrDt"
								value={searchStrDt}
								onChange={(e) => {
									setSearchStrDt(e.target.value);
								}}
							/>
							<p> - </p>
							<input
								type="date"
								max={"9999-12-31"}
								name="searchEndDt"
								id="searchEndDt"
								value={searchEndDt}
								onChange={(e) => {
									setSearchEndDt(e.target.value);
								}}
							/>
							<input
								name="searchDtRadio"
								type="radio"
								id="searchDtRadio1"
								value={""}
								defaultChecked
								onClick={(event) =>
									setDate(
										(event.target as HTMLInputElement).value
									)
								}
							/>
							<label htmlFor="searchDtRadio1">전체</label>
							<input
								name="searchDtRadio"
								type="radio"
								id="searchDtRadio2"
								value={7}
								onClick={(event) =>
									setDate(
										(event.target as HTMLInputElement).value
									)
								}
							/>
							<label htmlFor="searchDtRadio2">1주일</label>
							<input
								name="searchDtRadio"
								type="radio"
								id="searchDtRadio3"
								value={30}
								onClick={(event) =>
									setDate(
										(event.target as HTMLInputElement).value
									)
								}
							/>
							<label htmlFor="searchDtRadio3">1개월</label>
							<input
								name="searchDtRadio"
								type="radio"
								id="searchDtRadio4"
								value={90}
								onClick={(event) =>
									setDate(
										(event.target as HTMLInputElement).value
									)
								}
							/>
							<label htmlFor="searchDtRadio4">3개월</label>
						</S.searchRow>
						<S.searchRow>
							<select
								name="searchKey"
								id="searchKey"
								onChange={(e) =>
									setSearchOptions({
										...searchOptions,
										searchKey: e.target.value,
									})
								}>
								<option value="">= 선 택 =</option>
								<option value="0">제목</option>
								<option value="1">내용</option>
								<option value="5">제목+내용</option>
							</select>
							<input
								type="text"
								id="searchWord"
								onChange={(e) =>
									setSearchOptions({
										...searchOptions,
										searchWord: e.target.value,
									})
								}
							/>
							<S.searchButton onClick={() => search()}>
								<S.headerText>검색하기</S.headerText>
							</S.searchButton>
							<S.filterButton
								onClick={() => {
									setOpenFilter(!isOpenFilter);
								}}
								className={`${isApplied && "applied"}`}>
								{isOpenFilter ? (
									<Minus
										width="2rem"
										height="2rem"
										fill="white"
									/>
								) : (
									<Plus
										width="2rem"
										height="2rem"
										fill="white"
									/>
								)}
							</S.filterButton>
						</S.searchRow>
						{/* 필터영역 */}
						<S.filterArea className={`${isOpenFilter && "expend"}`}>
							<S.filterWrap>
								{filterList.map((filter: any, fi: number) => {
									return (
										<li key={fi}>
											<h2>{filter.title}</h2>
											<ul>
												<Filter
													fi={fi}
													filter={filter} // 필터 아이템 리스트
													option={filter.option}
													setOption={filter.setOption}
												/>
											</ul>
										</li>
									);
								})}
							</S.filterWrap>
							<S.flexRowEnd>
								<button onClick={() => filter()}>적용</button>
								<button onClick={() => filterRefresh()}>
									전체 해제
								</button>
							</S.flexRowEnd>
						</S.filterArea>
					</S.searchArea>
					{/* 버튼 영역 */}
					<S.asideButtons>
						<button
							onClick={() => {
								viewerCheck(() =>
									window.open(
										"/MANA002002",
										"_blank",
										"width=600, height=610, left=100, top=100"
									)
								);
							}}>
							자주 묻는 질문 등록
						</button>
					</S.asideButtons>
					{/* 테이블 영역 */}
					<S.table01Wrap>
						<S.table01>
							<thead>
								<tr>
									<th>번호</th>
									<th>분류</th>
									<th>제목</th>
									<th>내용</th>
									<th>등록 일시</th>
									<th>노출 순서</th>
									<th>노출 여부</th>
									<th>관리</th>
								</tr>
							</thead>
							<tbody>
								{list && list.length > 0 ? (
									<>
										{list.map((item, i) => (
											<tr key={i}>
												<td>{item.bfIdx}</td>
												<td>
													{categoryText(
														item.categoryCode
													)}
												</td>
												<td>{item.questionText}</td>
												<td>{item.answerText}</td>
												<td>
													{Util.dateFormat(
														item.regDt
													)}
												</td>
												<td>{item.orderNumber}</td>
												<td>
													{displayYnText(
														item.displayYn
													)}
												</td>
												<td>
													<S.tableButtonArea>
														<S.tableButton
															onClick={() => {
																window.open(
																	"/MANA002002?bfIdx=" +
																		item.bfIdx,
																	"_blank",
																	"width=600, height=610, left=100, top=100"
																);
															}}>
															<S.headerText>
																수정
															</S.headerText>
														</S.tableButton>
														<S.tableButton
															onClick={() =>
																displayYnPatch(
																	item.bfIdx
																)
															}>
															<S.headerText>
																미노출
															</S.headerText>
														</S.tableButton>
													</S.tableButtonArea>
												</td>
											</tr>
										))}
									</>
								) : (
									<tr>
										<td
											colSpan={999}
											style={{ textAlign: "center" }}>
											데이터가 없습니다.
										</td>
									</tr>
								)}
							</tbody>
						</S.table01>
					</S.table01Wrap>
					{/* 페이지네이션 영역 */}
					<Pagination
						totalItems={totalItems} // 데이터 총 개수
						currentPage={
							page && parseInt(page) > 0 ? parseInt(page) : 1
						} // 현재 페이지
						itemCountPerPage={listSize} // 페이지당 보여줄 데이터 개수
					/>
				</div>
			}
		/>
	);
};
