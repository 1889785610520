// 보물 등록/수정
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useSearchParams, useLocation } from "react-router-dom";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { LoginAtom } from "../Recoil/Atoms/PopupState";
import { UserAtom, LoadingAtom, MenuAtom } from "../Recoil/Atoms/UserState";

import * as S from "../Styles/Style";
import Api from "../libs/api";
import Util from "../Store/Util";

export default () => {
	const location = useLocation();
	const menu = useRecoilValue(MenuAtom);
	let agmIdx: number;
	const user = useRecoilValue(UserAtom);
	const setOpenLogin = useSetRecoilState<boolean>(LoginAtom);
	const setLoading = useSetRecoilState<boolean>(LoadingAtom);
	const apiUrl = process.env.REACT_APP_API_URL;
	const imgUrl = process.env.REACT_APP_IMG_URL;
	const token = localStorage.getItem("token");
	const [searchParams, setSearchParams] = useSearchParams();
	const pmIdx = searchParams.get("pmIdx"); // 부모창에서 받아온 보물번호
	const groupYn = searchParams.get("groupYn"); // 일괄등록 된 보물 여부
	const editAble = searchParams.get("editAble"); // 수정 가능 여부

	const [registType, setRegistType] = useState("B");
	const [categoryCode, setCategoryCode] = useState("");
	const [name, setName] = useState("");
	const [modelNumber, setModelNumber] = useState("");
	const [presentOptions, setPresentOptions] = useState("");
	const [imageUrl, setImageUrl] = useState<{
		image: string;
		file: File | null;
	}>({ image: "", file: null });
	const [limit, setLimit] = useState<boolean>(false);
	const [limitCount, setLimitCount] = useState("");
	const [gradeCode, setGradeCode] = useState("");
	const [displayPrice, setDisplayPrice] = useState("");
	const [realPrice, setRealPrice] = useState("");
	const [buyUrl, setBuyUrl] = useState("");
	const [linkUrl, setLinkUrl] = useState("");
	const [description, setDescription] = useState("");
	const [displayYn, setDisplayYn] = useState(1);
	const [prevDisplayDt, setPrevDisplayDt] = useState<string>("");
	const [displayDt, setDisplayDt] = useState<Date>();
	const [notifyYn, setNotifyYn] = useState(1);
	const [useYn, setUseYn] = useState(1);
	const [memo, setMemo] = useState("");

	const [isOpenTooltip, setOpenTooltip] = useState(false);

	useEffect(() => {
		user.isLogin && GetData();
	}, [user]);
	// 수정인 경우 보물 상세정보 가져오기
	const GetData = async () => {
		if (pmIdx == undefined) return false;
		setLoading(true);
		await Api.get(`present/presentDetail/${pmIdx}`)
			.then((res: any) => {
				if (res.data == null) {
					const code = res.response.status;
					if (code == 401) {
						setOpenLogin(true);
					}
				} else {
					const data = res.data.data;
					console.log(res.data.data);
					setRegistType(data.registType);
					setCategoryCode(data.categoryCode);
					setName(data.name);
					setModelNumber(data.modelNumber);
					setPresentOptions(data.presentOptions);
					let copiedImage = imageUrl;
					copiedImage.image = imgUrl + data.imageUrl;
					setImageUrl(copiedImage);
					setLimit(data.limitCount !== "N" && data.limitCount !== "");
					setLimitCount(
						data.limitCount == "N" ? "" : data.limitCount
					);
					setGradeCode(data.gradeCode);
					setDisplayPrice(Util.addComma(data.displayPrice));
					setRealPrice(Util.addComma(data.realPrice));
					setBuyUrl(data.buyUrl);
					setLinkUrl(data.linkUrl);
					setDescription(data.description);
					setPrevDisplayDt(data.displayDt);
					if (Util.onlyNum(Util.getNowString()) < data.displayDt) {
						setDisplayYn(2);
						setDisplayDt(new Date(Util.dateFormat(data.displayDt)));
					} else {
						setDisplayYn(data.displayYn);
					}
					setNotifyYn(data.notifyYn);
					setUseYn(data.useYn);
					setMemo(data.memo);
				}
			})
			.catch((error) => {
				console.log(error.response.data, "wwwwww");
			});
		setLoading(false);
	};

	const uploadImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files !== null) {
			const file = e.target.files[0];
			if (file) {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => {
					setImageUrl({
						image: reader.result as string,
						file: file,
					});
				};
			}
		}
	};

	// 등록/수정하기 버튼 클릭 이벤트
	const onSubmit = async (e: any) => {
		e.preventDefault();

		viewerCheck(async () => {
			let url;
			const formData = new FormData();

			formData.append("registType", registType);
			formData.append("categoryCode", categoryCode);
			formData.append("name", name);
			modelNumber !== "" && formData.append("modelNumber", modelNumber); // 선택
			presentOptions !== "" &&
				formData.append("presentOptions", presentOptions); // 선택
			!limit || limitCount == ""
				? formData.append("limitCount", "N")
				: formData.append("limitCount", Util.onlyNum(limitCount)); // 수량제한이 있는 경우만
			formData.append("displayPrice", Util.onlyNum(displayPrice));
			formData.append("realPrice", Util.onlyNum(realPrice));
			buyUrl !== "" && formData.append("buyUrl", buyUrl); // 선택
			linkUrl !== "" && formData.append("linkUrl", linkUrl); // 선택
			formData.append("description", description);
			formData.append(
				"displayYn",
				displayYn == 2 ? "1" : displayYn.toString()
			);
			displayYn == 2
				? e.target.displayDt.value &&
				  formData.append(
						"displayDt",
						Util.onlyNum(e.target.displayDt.value) + "00000"
				  )
				: formData.append("displayDt", prevDisplayDt); // 노출 예약인 경우만
			formData.append("notifyYn", notifyYn.toString());
			formData.append("useYn", useYn.toString());
			memo !== "" && formData.append("memo", memo); // 선택
			formData.append("typeCode", "2"); // 뽑기상품 2 , 기프티콘상품 100 , 실물아닌상품(포인트.박스) 1

			let imageExist: boolean = true;
			if (pmIdx == undefined) {
				url = "present/presentUpload";
				if (imageUrl.file == null) {
					imageExist = false;
				} else {
					formData.append("imageUrl", imageUrl.file);
				}
			} else {
				url = "present/presentUpdate";
				formData.append("pmIdx", pmIdx);
				if (imageUrl.image == "") {
					imageExist = false;
				} else {
					if (imageUrl.file) {
						formData.append("imageUrl", imageUrl.file);
					}
				}
			}
			if (!imageExist) {
				alert("이미지가 등록되지 않았습니다.");
				return false;
			}

			for (let key of formData.keys()) {
				console.log(key, ":", formData.get(key));
			}

			if (
				window.confirm(
					`해당 내용을 ${
						pmIdx == undefined ? "등록" : "수정"
					}하시겠습니까?`
				)
			) {
				setLoading(true);
				await axios
					.post(apiUrl + url, formData, {
						headers: {
							Authorization: token,
							"content-type": "multipart/form-data",
						},
					})
					.then((res) => {
						window.opener.location.reload();
						window.close();
					})
					.catch((error) => {
						console.log(error);
						alert("등록 실패");
					});
				setLoading(false);
			}
		});
	};
	// 상품 등급 확인
	const presentGrade = async (displayPrice: string) => {
		if (Number(displayPrice) < 50000 || Number(displayPrice) > 200000000) {
			alert("금액이 잘못 입력되었습니다.");
			setDisplayPrice("");
			return;
		} else if (displayPrice == "") {
			return;
		}
		await Api.get("present/presentGrade", {
			displayPrice: displayPrice,
			realPrice: 0,
		})
			.then((res: any) => {
				if (res.data == undefined) {
					console.log(res.response.data.console.error);
				} else {
					setGradeCode(res.data.data.grade);
				}
			})
			.catch((error) => {
				console.log(error.response.data, "wwwwww");
			});
	};
	// 툴팁 영역 외 클릭 시 닫기
	const tooltipRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		function handleFocus(e: MouseEvent): void {
			if (
				tooltipRef.current &&
				!tooltipRef?.current?.contains(e.target as Node)
			) {
				setOpenTooltip(false);
			}
		}
		document.addEventListener("mouseup", handleFocus);
		return () => {
			document.removeEventListener("mouseup", handleFocus);
		};
	}, [tooltipRef]);
	// 권한 확인(뷰어 제한) 후 액션
	function viewerCheck(action: () => void) {
		menu.map((m) => {
			m.sub.map((s) => {
				if (s.link.slice(0, 8) == location.pathname.slice(0, 8)) {
					agmIdx = s.agmIdx;
				}
			});
		});
		Util.viewerCheck(user.grade, Number(agmIdx), () => action());
	}

	return (
		<>
			<S.popupPage>
				<h1>
					<S.headerText>
						보물 {pmIdx == undefined ? "등록" : "상세정보"}
					</S.headerText>
				</h1>
				{/* 테이블 영역 */}
				<form onSubmit={onSubmit}>
					<h2>보물 기본 정보</h2>
					<S.table02>
						<tbody>
							<tr>
								<th colSpan={2}>보물 구분*</th>
							</tr>
							<tr>
								<td colSpan={2}>
									<S.normalRadioArea>
										<S.normalRadio>
											<input
												type="radio"
												name="registType"
												id="registTypeB"
												checked={registType == "B"}
												onChange={() =>
													setRegistType("B")
												}
												disabled={
													pmIdx !== null &&
													pmIdx !== undefined &&
													registType !== "B"
												}
											/>
											<label htmlFor="registTypeB" />
										</S.normalRadio>
										<S.normalLabel htmlFor="registTypeB">
											일반
										</S.normalLabel>
										<S.normalRadio>
											<input
												type="radio"
												name="registType"
												id="registTypeC"
												checked={registType == "C"}
												onChange={() =>
													setRegistType("C")
												}
												disabled={
													pmIdx !== null &&
													pmIdx !== undefined &&
													registType !== "C"
												}
											/>
											<label htmlFor="registTypeC" />
										</S.normalRadio>
										<S.normalLabel htmlFor="registTypeC">
											합성
										</S.normalLabel>
										<S.normalRadio>
											<input
												type="radio"
												name="registType"
												id="registTypeE"
												checked={registType == "E"}
												onChange={() =>
													setRegistType("E")
												}
												disabled={
													pmIdx !== null &&
													pmIdx !== undefined &&
													registType !== "E"
												}
											/>
											<label htmlFor="registTypeE" />
										</S.normalRadio>
										<S.normalLabel htmlFor="registTypeE">
											이벤트
										</S.normalLabel>
									</S.normalRadioArea>
								</td>
							</tr>
							<tr>
								<th>보물 분류*</th>
								<th>보물명*</th>
							</tr>
							<tr>
								<td>
									<div>
										<select
											name="categoryCode"
											id="categoryCode"
											value={categoryCode}
											onChange={(e) =>
												setCategoryCode(e.target.value)
											}
											disabled={!editAble}
											required>
											<option value="">= 선택 =</option>
											<option value="1">
												가구/인테리어
											</option>
											<option value="2">
												스포츠/레저
											</option>
											<option value="3">기프티콘</option>
											<option value="4">식품</option>
											<option value="5">
												디지털/가전
											</option>
											<option value="6">
												장난감/완구
											</option>
											<option value="7">명품</option>
											<option value="8">패션잡화</option>
											<option value="9">생활/건강</option>
											<option value="10">
												화장품/미용
											</option>
											<option value="11">
												지류상품권
											</option>
										</select>
									</div>
								</td>
								<td>
									<input
										type="text"
										name="name"
										id="name"
										value={name}
										onChange={(e) =>
											setName(e.target.value)
										}
										disabled={!editAble}
										required
									/>
								</td>
							</tr>
							<tr>
								<th>모델번호</th>
								<th>옵션</th>
							</tr>
							<tr>
								<td>
									<input
										type="text"
										name="modelNumber"
										id="modelNumber"
										value={modelNumber}
										onChange={(e) =>
											setModelNumber(e.target.value)
										}
										disabled={!editAble}
									/>
								</td>
								<td>
									<input
										type="text"
										name="presentOptions"
										id="presentOptions"
										value={presentOptions}
										onChange={(e) =>
											setPresentOptions(e.target.value)
										}
										disabled={!editAble}
									/>
								</td>
							</tr>
							<tr>
								<th colSpan={2}>보물 이미지* (450x340)</th>
							</tr>
							<tr>
								<td colSpan={2} className="imageArea">
									<S.flexRowBetween>
										<React.Fragment>
											{imageUrl.image == "" ? (
												<S.blankImageArea>
													<input
														id="imageUrl"
														name="imageUrl"
														type="file"
														accept=".png, .jpeg, .jpg"
														onChange={(e) =>
															uploadImage(e)
														}
														disabled={!editAble}
													/>
													<label htmlFor={"imageUrl"}>
														파일 선택
													</label>
												</S.blankImageArea>
											) : (
												<S.filledImagesArea
													onClick={() => {
														localStorage.setItem(
															"sendData",
															JSON.stringify(
																imageUrl.image
															)
														);
														window.open(
															"/PREV001001",
															"_blank",
															"width=800, height=800, left=100, top=100"
														);
													}}>
													<img
														src={
															imageUrl.image
																? imageUrl.image
																: ""
														}
														alt={""}
													/>
													<button
														type="button"
														onClick={(e) => {
															e.stopPropagation();
															setImageUrl({
																image: "",
																file: null,
															});
														}}
														disabled={
															!editAble
														}></button>
												</S.filledImagesArea>
											)}
										</React.Fragment>
									</S.flexRowBetween>
								</td>
							</tr>
						</tbody>
					</S.table02>
					<h2>판매정보</h2>
					<S.table02>
						<tbody>
							<tr>
								<th>수량제한*</th>
								<th>보물등급*</th>
							</tr>
							<tr>
								<td>
									<S.normalRadioArea>
										<S.normalRadio>
											<input
												type="radio"
												name="limit"
												id="limit0"
												checked={limit == false}
												onChange={() => setLimit(false)}
												disabled={!editAble && limit}
											/>
											<label htmlFor="limit0" />
										</S.normalRadio>
										<S.normalLabel htmlFor="limit0">
											없음
										</S.normalLabel>
										<S.normalRadio>
											<input
												type="radio"
												name="limit"
												id="limit1"
												checked={limit == true}
												onChange={() => setLimit(true)}
												disabled={!editAble && !limit}
											/>
											<label htmlFor="limit1" />
										</S.normalRadio>
										<S.normalLabel htmlFor="limit1">
											있음
										</S.normalLabel>
										<input
											type="text"
											className="radioInput"
											onWheel={(e) =>
												(e.target as HTMLElement).blur()
											}
											name="limitCount"
											id="limitCount"
											autoComplete="off"
											placeholder="0"
											value={limitCount}
											onChange={(e) =>
												setLimitCount(
													Util.onChangeComma(
														e.target.value
													)
												)
											}
											required={limit}
											readOnly={!limit}
											disabled={!editAble}
										/>
										<S.bodyText>개</S.bodyText>
									</S.normalRadioArea>
								</td>
								<td>
									<div>
										<input
											type="text"
											name="gradeCode"
											id="gradeCode"
											value={gradeCode}
											readOnly
										/>
									</div>
								</td>
							</tr>
							<tr>
								<th>정가*</th>
								<th>실구매가*</th>
							</tr>
							<tr>
								<td>
									<div>
										<input
											type="text"
											onWheel={(e) =>
												(e.target as HTMLElement).blur()
											}
											id="displayPrice"
											autoComplete="off"
											placeholder="0"
											value={displayPrice}
											onChange={(e) =>
												setDisplayPrice(
													Util.onChangeComma(
														e.target.value
													)
												)
											}
											onBlur={(e) => {
												presentGrade(
													Util.onlyNum(e.target.value)
												);
											}}
											disabled={
												!(
													pmIdx == null ||
													pmIdx == undefined
												)
											}
											required
										/>
										<S.bodyText>원</S.bodyText>
									</div>
								</td>
								<td>
									<div>
										<input
											type="text"
											onWheel={(e) =>
												(e.target as HTMLElement).blur()
											}
											id="realPrice"
											autoComplete="off"
											placeholder="0"
											value={realPrice}
											onChange={(e) =>
												setRealPrice(
													Util.onChangeComma(
														e.target.value
													)
												)
											}
											disabled={!editAble}
											required
										/>
										<S.bodyText>원</S.bodyText>
									</div>
								</td>
							</tr>
							<tr>
								<th>구매 url</th>
								<th>상세페이지 url</th>
							</tr>
							<tr>
								<td>
									<input
										type="text"
										name="buyUrl"
										id="buyUrl"
										value={buyUrl}
										onChange={(e) =>
											setBuyUrl(e.target.value)
										}
										disabled={!editAble}
									/>
								</td>
								<td>
									<input
										type="text"
										name="linkUrl"
										id="linkUrl"
										value={linkUrl}
										onChange={(e) =>
											setLinkUrl(e.target.value)
										}
										disabled={!editAble}
									/>
								</td>
							</tr>
						</tbody>
					</S.table02>
					<h2>
						상세 설명*
						<S.tooltipButton
							type="button"
							onClick={() => setOpenTooltip(true)}>
							<S.headerText>i</S.headerText>
							<div
								ref={tooltipRef}
								className="tooltip"
								style={{
									display: isOpenTooltip ? "block" : "none",
								}}>
								<S.headerText>상세 설명 입력 방법</S.headerText>
								<S.bodyText>
									{`<h1>제목</h1>
									<p>내용<br/>중간에 줄바꿈</p>
									<br/>
									<h1>제목</h1>
									<p>내용</p>
									`}
								</S.bodyText>
							</div>
						</S.tooltipButton>
					</h2>
					<S.table02>
						<tbody>
							<tr>
								<td colSpan={2}>
									<S.textAreaWrap>
										<textarea
											name="description"
											id="description"
											rows={5}
											value={description}
											onChange={(e) =>
												setDescription(e.target.value)
											}
											disabled={!editAble}
											required></textarea>
									</S.textAreaWrap>
								</td>
							</tr>
						</tbody>
					</S.table02>
					<h2>노출 여부</h2>
					<S.table02>
						<tbody>
							<tr>
								<th colSpan={2}>보물 노출*</th>
							</tr>
							<tr>
								<td colSpan={2}>
									<S.normalRadioArea>
										<S.normalRadio>
											<input
												type="radio"
												name="displayYn"
												id="displayYn1"
												checked={displayYn == 1}
												onChange={() => setDisplayYn(1)}
												disabled={
													(groupYn == "1" ||
														!editAble) &&
													displayYn !== 1
												}
											/>
											<label htmlFor="displayYn1" />
										</S.normalRadio>
										<S.normalLabel htmlFor="displayYn1">
											노출
										</S.normalLabel>
										<S.normalRadio>
											<input
												type="radio"
												name="displayYn"
												id="displayYn0"
												checked={displayYn == 0}
												onChange={() => setDisplayYn(0)}
												disabled={
													(groupYn == "1" ||
														!editAble) &&
													displayYn !== 0
												}
											/>
											<label htmlFor="displayYn0" />
										</S.normalRadio>
										<S.normalLabel htmlFor="displayYn0">
											미노출
										</S.normalLabel>
										<S.normalRadio>
											<input
												type="radio"
												name="displayYn"
												id="displayYn2"
												checked={displayYn == 2}
												onChange={() => setDisplayYn(2)}
												disabled={
													(groupYn == "1" ||
														!editAble) &&
													displayYn !== 2
												}
											/>
											<label htmlFor="displayYn2" />
										</S.normalRadio>
										<S.normalLabel htmlFor="displayYn2">
											노출 예약
										</S.normalLabel>
										<input
											type="datetime-local"
											max={"9999-12-31T12:59"}
											name="displayDt"
											id="displayDt"
											value={
												displayDt
													? Util.inputDateFormat(
															displayDt
													  )
													: ""
											}
											onChange={(e) =>
												setDisplayDt(
													new Date(e.target.value)
												)
											}
											className="radioInput"
											required={displayYn == 2}
											readOnly={displayYn != 2}
											disabled={
												groupYn == "1" || !editAble
											}
										/>
									</S.normalRadioArea>
								</td>
							</tr>
							<tr>
								<th>뉴스룸 노출*</th>
								<th>제외 여부*</th>
							</tr>
							<tr>
								<td>
									<S.normalRadioArea>
										<S.normalRadio>
											<input
												type="radio"
												name="notifyYn"
												id="notifyYn1"
												checked={notifyYn == 1}
												onChange={() => setNotifyYn(1)}
												disabled={
													!editAble && notifyYn !== 1
												}
											/>
											<label htmlFor="notifyYn1" />
										</S.normalRadio>
										<S.normalLabel htmlFor="notifyYn1">
											노출
										</S.normalLabel>
										<S.normalRadio>
											<input
												type="radio"
												name="notifyYn"
												id="notifyYn0"
												checked={notifyYn == 0}
												onChange={() => setNotifyYn(0)}
												disabled={
													!editAble && notifyYn !== 0
												}
											/>
											<label htmlFor="notifyYn0" />
										</S.normalRadio>
										<S.normalLabel htmlFor="notifyYn0">
											미노출
										</S.normalLabel>
									</S.normalRadioArea>
								</td>
								<td>
									<S.normalRadioArea>
										<S.normalRadio>
											<input
												type="radio"
												name="useYn"
												id="useYn0"
												checked={useYn == 0}
												onChange={() => setUseYn(0)}
												disabled={
													!editAble && useYn !== 0
												}
											/>
											<label htmlFor="useYn0" />
										</S.normalRadio>
										<S.normalLabel htmlFor="useYn0">
											제외
										</S.normalLabel>
										<S.normalRadio>
											<input
												type="radio"
												name="useYn"
												id="useYn1"
												checked={useYn == 1}
												onChange={() => setUseYn(1)}
												disabled={
													!editAble && useYn !== 1
												}
											/>
											<label htmlFor="useYn1" />
										</S.normalRadio>
										<S.normalLabel htmlFor="useYn1">
											미제외
										</S.normalLabel>
									</S.normalRadioArea>
								</td>
							</tr>
						</tbody>
					</S.table02>
					<h2>관리자 메모</h2>
					<S.table02>
						<tbody>
							<tr>
								<td colSpan={2}>
									<S.textAreaWrap>
										<textarea
											name="memo"
											id="memo"
											cols={30}
											rows={2}
											value={memo}
											onChange={(e) =>
												setMemo(e.target.value)
											}
											disabled={!editAble}></textarea>
									</S.textAreaWrap>
								</td>
							</tr>
						</tbody>
					</S.table02>
					<S.blank2 />
					{editAble && (
						<S.flexRowCenter>
							<S.filledButton type="submit">
								{pmIdx == undefined ? "등록" : "수정"}하기
							</S.filledButton>
						</S.flexRowCenter>
					)}
				</form>
			</S.popupPage>
		</>
	);
};
