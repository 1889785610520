// 공통 스타일
import { createGlobalStyle } from "styled-components";

import * as S from "./Style";

import IBMPlexSansKR from "../Fonts/IBMPlexSansKR.otf";
import IBMPlexSansKRSemiBold from "../Fonts/IBMPlexSansKRSemiBold.otf";
import Jalnan2 from "../Fonts/Jalnan2.otf";

const GlobalStyle = createGlobalStyle`
	/* MainTitle font */
	@font-face {
		font-family: 'IBMPlexSansKR';
		src: local('IBMPlexSansKR'), local('IBMPlexSansKR');
		font-style: normal;
		src: url(${IBMPlexSansKR}) format('truetype');
	}
	/* Body font */
	@font-face {
		font-family: 'IBMPlexSansKRSemiBold';
		src: local('IBMPlexSansKRSemiBold'), local('IBMPlexSansKRSemiBold');
		font-style: normal;
		src: url(${IBMPlexSansKRSemiBold}) format('truetype');
	}
	@font-face {
		font-family: 'Jalnan2';
		src: local('Jalnan2'), local('Jalnan2');
		font-style: normal;
		src: url(${Jalnan2}) format('truetype');
	}

    *{
        box-sizing: border-box;
        font-size: 10px;
        font-family: 'IBMPlexSansKR';
		color: ${S.color.gray05};
    }
    html, body, div, span, h1, h2, h3, h4, h5, h6, p, 
    a, dl, dt, dd, ol, ul, li, form, label, table{
        margin: 0;
        padding: 0;
        border: 0;
        vertical-align: baseline;
    }
	html,body {
		height: 100%;
	}
	body {
	    -ms-overflow-style: none;
		
	}
	a{
        text-decoration: none;
        color: inherit;
		cursor: pointer;
		&:hover {
			opacity: 0.6;
		}
    }
    ol, ul{
        list-style: none;
    }
    button {
        border: 0;
        background: transparent;
        cursor: pointer;
		&:hover {
			opacity: 0.6;
		}
    }
	p, span, button, input, textarea, select {
		white-space: pre-line;
		outline: none;
		border: 0;
	}
	textarea {
		resize: none;
	}
	p, span {
		text-align: left;
	}
	input::placeholder,textarea::placeholder {
		color: ${S.color.gray03};
	}
	/* 자동완성 시 배경색 변경 없애기 */
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-text-fill-color: #000;
		-webkit-box-shadow: 0 0 0px 1000px #fff inset;
		box-shadow: 0 0 0px 1000px #fff inset;
		/* transition: background-color 5000s ease-in-out 0s; */
	}
	input:autofill,
	input:autofill:hover,
	input:autofill:focus,
	input:autofill:active {
		-webkit-text-fill-color: #000;
		-webkit-box-shadow: 0 0 0px 1000px #fff inset;
		box-shadow: 0 0 0px 1000px #fff inset;
		/* transition: background-color 5000s ease-in-out 0s; */
	}
	// input number 화살표 제거
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
	}
	input[type='number'] {
	-moz-appearance: textfield;
	}
	table {
		border-collapse: collapse;
		.iconArea {
			svg {
				width: 1.5rem;
				height: 1.5rem;
				margin: 0 0.2rem;
			}
		}
	}
	table th.wrap,
	table td.wrap {
		white-space: pre;
	}
	table th.left,
	table td.left {
		text-align: left;
	}
	
	.block {
		display: block;
	}
`;

export default GlobalStyle;
