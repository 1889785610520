// 보물 일괄 수정
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSetRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { LoginAtom } from "../Recoil/Atoms/PopupState";
import { UserAtom, LoadingAtom, MenuAtom } from "../Recoil/Atoms/UserState";

import * as S from "../Styles/Style";
import Api from "../libs/api";
import Util from "../Store/Util";

const TableWrap = styled.div`
	width: 100%;
	overflow-x: scroll;
	overflow-y: scroll;
`;
const Table = styled.table`
	table-layout: fixed;
	width: 1%;
	th,
	td {
		border: 1px solid ${S.color.gray02};
		font-size: 1.2rem;
	}
	th {
		width: 8rem;
		background-color: ${S.color.gray02};
		padding: 0 1rem;
		border-bottom: 1px solid white;
	}
	tr:last-child th {
		border-bottom: 1px solid ${S.color.gray02};
	}
	td {
		width: 20rem;
		text-align: center;
		span {
			font-size: 1.2rem;
		}
	}
	img {
		max-width: 100%;
		/* width: 100%; */
	}
`;

interface list {
	displayDt: string;
	displayPrice: number;
	name: string;
	pmIdx: string;
	imageUrl: string;
	giftData: string;
}

export default () => {
	const location = useLocation();
	const menu = useRecoilValue(MenuAtom);
	let agmIdx: number;
	const user = useRecoilValue(UserAtom);
	const setOpenLogin = useSetRecoilState<boolean>(LoginAtom);
	const setLoading = useSetRecoilState<boolean>(LoadingAtom);
	const imgUrl = process.env.REACT_APP_IMG_URL;
	const sendData = localStorage.getItem("sendData");
	const regDt = JSON.parse(sendData ? sendData : "");

	const [prevDisplayDt, setPrevDisplayDt] = useState<string>(""); // 기존 노출 예약일
	const [displayDt, setDisplayDt] = useState<string>(""); // 변경할 노출 예약일
	const [list, setList] = useState<list[] | null>([]);

	useEffect(() => {
		user.isLogin && GetData();
	}, [user]);
	// 정보 불러오기
	const GetData = async () => {
		setLoading(true);
		await Api.get("present/presentGroup", { regDt: regDt })
			.then((res: any) => {
				console.log(regDt, res);
				if (res.data == null) {
					const code = res.response.status;
					if (code == 401) {
						setOpenLogin(true);
					}
				} else {
					const displayDt = Util.dateFormat(
						res.data.data[0].displayDt
					);
					setPrevDisplayDt(Util.inputDateFormat(new Date(displayDt)));
					setDisplayDt(Util.inputDateFormat(new Date(displayDt)));
					setList(res.data.data);
				}
			})
			.catch((error) => {
				console.log(error.response.data, "wwwwww");
			});
		setLoading(false);
	};
	// 수정하기 버튼 클릭 이벤트
	const save = async () => {
		viewerCheck(async () => {
			if (Util.getNowString() > Util.onlyNum(prevDisplayDt)) {
				alert("잘못된 요청입니다.");
				window.close();
				return;
			}
			if (window.confirm("해당 내용을 수정하시겠습니까?")) {
				setLoading(true);
				await Api.patch("present/presentBulkUpdate", {
					displayDt: Util.onlyNum(displayDt),
					regDt: regDt,
				})
					.then((res: any) => {
						if (res.data.statusCode == 200) {
							window.opener.location.reload();
							window.close();
						} else {
							alert("수정 실패");
						}
					})
					.catch((error) => {
						console.log(error.response.data, "wwwwww");
					});
				setLoading(false);
			}
		});
	};
	// 권한 확인(뷰어 제한) 후 액션
	function viewerCheck(action: () => void) {
		menu.map((m) => {
			m.sub.map((s) => {
				if (s.link.slice(0, 8) == location.pathname.slice(0, 8)) {
					agmIdx = s.agmIdx;
				}
			});
		});
		Util.viewerCheck(user.grade, Number(agmIdx), () => action());
	}

	return (
		<>
			<S.popupPage>
				<h1>
					<S.headerText>보물 일괄 수정</S.headerText>
				</h1>
				<S.blank2 />
				<S.table02>
					<tbody>
						<tr>
							<th>보물 노출 예약*</th>
						</tr>
						<tr>
							<td>
								<input
									type="datetime-local"
									max={"9999-12-31T12:59"}
									name="displayDt"
									id="displayDt"
									value={displayDt}
									onChange={(e) =>
										setDisplayDt(e.target.value)
									}
									required
								/>
							</td>
						</tr>
					</tbody>
				</S.table02>
				<S.blank2 />
				<TableWrap>
					<Table>
						<thead>
							<tr>
								<th rowSpan={3}>보물 정보</th>
								{list?.map((item, index) => {
									return (
										<td key={index}>
											<S.headerText>
												{item.pmIdx}
											</S.headerText>
										</td>
									);
								})}
							</tr>
							<tr>
								{list?.map((item, index) => {
									return <td key={index}>{item.name}</td>;
								})}
							</tr>
							<tr>
								{list?.map((item, index) => {
									return (
										<td key={index}>
											{Util.addComma(item.displayPrice)}
										</td>
									);
								})}
							</tr>
						</thead>
						<tbody>
							<tr>
								<th>보물 이미지</th>
								{list?.map((item, index) => {
									return (
										<td key={index}>
											<img
												// https://cdn.go-pang.com/indonesia/present/7923.png
												src={imgUrl + item.imageUrl}
												alt=""
											/>
										</td>
									);
								})}
							</tr>
							{/* <tr>
								<th>Contents</th>
								{list?.map((item, index) => {
									return (
										<td key={index}>
											<img
												src={
													imgUrl + item.typeCImageUrl
												}
												alt=""
											/>
										</td>
									);
								})}
							</tr> */}
						</tbody>
					</Table>
				</TableWrap>
				<S.blank2 />
				<S.flexRowCenter>
					<S.filledButton onClick={() => save()}>
						수정하기
					</S.filledButton>
				</S.flexRowCenter>
			</S.popupPage>
		</>
	);
};
