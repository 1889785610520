// 보물목록
import * as xlsx from "xlsx";
import { useState, useEffect, useRef } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { LoginAtom } from "../Recoil/Atoms/PopupState";
import { UserAtom, LoadingAtom, MenuAtom } from "../Recoil/Atoms/UserState";

import * as S from "../Styles/Style";
import Api from "../libs/api";
import Util from "../Store/Util";
import Page from "../Components/Page";
import Pagination from "../Components/Pagination";
import Filter from "../Components/Filter";

// 이미지
import { ReactComponent as Plus } from "../Images/plus.svg";
import { ReactComponent as Minus } from "../Images/minus.svg";
import { NumberLiteralType } from "typescript";

interface list {
	address1: string;
	address2: string;
	addressName: string;
	category: string;
	courierCompanyCommon: string;
	deliveryCompanyCode: string;
	deliveryState: string;
	invoiceNumber: string;
	mbohIdx: string;
	mmIdx: number;
	modDt: string;
	name: string;
	nick: string;
	pdmIdx: string;
	pmIdx: string;
	pumIdx: string;
	regDt: string;
	tXid: null;
	tel: string;
}

export default () => {
	const location = useLocation();
	const menu = useRecoilValue(MenuAtom);
	let agmIdx: number;
	const user = useRecoilValue(UserAtom);
	const setOpenLogin = useSetRecoilState<boolean>(LoginAtom);
	const setLoading = useSetRecoilState<boolean>(LoadingAtom);
	const [isOpenFilter, setOpenFilter] = useState<boolean>(false);
	const [isApplied, setIsApplied] = useState<boolean>(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const page = searchParams.get("page");
	const listSize = 20;
	const [totalItems, setTotalItems] = useState<number>(0);
	const [list, setList] = useState<list[] | null>([]);
	const now = new Date(Util.getNow());
	// 검색 옵션(1개월 기본)
	const searchOptionsRef = useRef<{}>({
		searchStrDt: Util.daysAgo(now, 7),
		searchEndDt: Util.getNowString().slice(0, 8),
	});
	const [searchOptions, setSearchOptions] = useState({});
	// 검색 일자
	const [searchStrDt, setSearchStrDt] = useState<string>("");
	const [searchEndDt, setSearchEndDt] = useState<string>(
		Util.inputDateFormat(now, "date")
	);
	// 검색어
	const [searchKey1, setSearchKey1] = useState<string>("");
	const [searchKey2, setSearchKey2] = useState<string>("");
	const [searchWord1, setSearchWord1] = useState<string>("");
	const [searchWord2, setSearchWord2] = useState<string>("");
	// 필터 옵션
	const filterOptionsRef = useRef({});
	const [categoryCode, setCategoryCode] = useState<string[]>([]);

	const filterList = [
		{
			title: "보물 분류",
			name: "categoryCode",
			items: [
				{ text: "가구/인테리어", value: 1 },
				{ text: "스포츠/레저", value: 2 },
				{ text: "기프티콘", value: 3 },
				{ text: "식품", value: 4 },
				{ text: "디지털/가전", value: 5 },
				{ text: "장난감/완구", value: 6 },
				{ text: "명품", value: 7 },
				{ text: "패션잡화", value: 8 },
				{ text: "생활/건강", value: 9 },
				{ text: "화장품/미용", value: 10 },
				{ text: "지류상품권", value: 11 },
			],
			option: categoryCode,
			setOption: setCategoryCode,
		},
	];
	// 탭
	const [activeTab, setActiveTab] = useState<string | null>("");
	// 1페이지로 이동되면 리셋
	const pageResset = (tab?: string) => {
		if (page == "1") {
			user.isLogin && GetList(tab == undefined ? activeTab : tab);
		} else {
			setSearchParams({ page: "1" });
		}
	};
	// 1주일 기본
	useEffect(() => {
		setDate(7);
	}, []);
	useEffect(() => {
		window.scrollTo(0, 0); // 페이지 이동 시 스크롤 위치 맨 위로 초기화
		user.isLogin && GetList(activeTab);
	}, [user, page]);
	// 엑셀 추출
	const excelList = useRef<list[]>([]);
	const excelDownload = (excelList: any[]) => {
		let list: any[] = [];
		excelList.map((item, index) => {
			list.push({
				"배송 번호": item.pdmIdx,
				"보물 분류": item.category,
				"보물 번호": item.pmIdx,
				유니크보물번호: item.pumIdx,
				보물명: item.name,
				"회원 번호": item.mmIdx,
				닉네임: item.nick,
				"배송지 정보": `${item.address1}${item.address2}`,
				연락처: item.tel,
				"배송 상태": item.deliveryState,
				"배송 신청일시": Util.dateFormat(item.regDt),
				"배송 상태 변경 일시": Util.dateFormat(item.modDt),
				택배사: item.deliveryCompanyCode,
				송장번호: item.invoiceNumber,
				결제번호: item.tXid,
			});
		});
		Util.excelDownload(list);
	};
	// 목록 불러오기
	const GetList = async (
		deliveryState?: string | null,
		isExcel?: boolean
	) => {
		console.log(filterOptionsRef.current);
		setLoading(true);
		await Api.get("delivery/deliveryListSelect", {
			...searchOptionsRef.current,
			...filterOptionsRef.current,
			page: page,
			listSize: isExcel ? 99999 : listSize,
			deliveryState: deliveryState,
		})
			.then((res: any) => {
				if (res.data == null) {
					const code = res.response.status;
					if (code == 401) {
						setOpenLogin(true);
					}
				} else {
					if (isExcel) {
						excelList.current = res.data.data.data;
						excelDownload(res.data.data.data);
					} else {
						console.log(res.data.data.data);
						setList(res.data.data.data);
						setTotalItems(res.data.data.totalCnt);
					}
				}
			})
			.catch((error) => {
				console.log(error.response.data, "wwwwww");
			});
		setLoading(false);
	};
	// 배송 상세보기 팝업 오픈
	const openPopup = (pdmIdx: string) => {
		window.open(
			"/MANA005002?pdmIdx=" + pdmIdx,
			"_blank",
			"width=700, height=750, left=100, top=100"
		);
	};

	// 공통
	// 일자
	const setDate = (value: any) => {
		const year = now.getFullYear(); // 년
		const month = now.getMonth(); // 월
		const day = now.getDate(); // 일

		if (value == "") {
			setSearchStrDt("");
		} else {
			const start = new Date(
				year,
				month,
				day - value
			).toLocaleDateString();
			const startDt = new Date(start);

			setSearchStrDt(Util.inputDateFormat(startDt, "date"));
		}
		setSearchEndDt(Util.inputDateFormat(now, "date"));
	};
	useEffect(() => {
		setSearchOptions({
			...searchOptions,
			searchStrDt: Util.onlyNum(searchStrDt),
			searchEndDt: Util.onlyNum(searchEndDt),
		});
	}, [searchStrDt, searchEndDt]);
	useEffect(() => {
		if (searchKey1 !== "" && searchWord1 !== "") {
			if (searchKey2 !== "" && searchWord2 !== "") {
				setSearchOptions({
					...searchOptions,
					searchKey: [searchKey1, searchKey2].join(),
					searchWord: [searchWord1, searchWord2].join(),
				});
			} else {
				setSearchOptions({
					...searchOptions,
					searchKey: searchKey1,
					searchWord: searchWord1,
				});
			}
		} else {
			setSearchOptions({
				...searchOptions,
				searchKey: null,
				searchWord: null,
			});
		}
	}, [searchKey1, searchWord1, searchKey2, searchWord2]);
	// 검색
	const search = () => {
		searchOptionsRef.current = {
			...searchOptionsRef.current,
			...searchOptions,
		};
		pageResset();
	};
	// 필터 선택 취소
	const filterRefresh = () => {
		setCategoryCode([]);
	};
	// 필터 적용
	const filter = () => {
		filterOptionsRef.current = {
			category: categoryCode.join(),
		};
		pageResset();
		setIsApplied(categoryCode.length > 0);
	};
	// 권한 확인(뷰어 제한) 후 액션
	const viewerCheck = (action: () => void) => {
		menu.map((m) => {
			m.sub.map((s) => {
				if (s.link == location.pathname) {
					agmIdx = s.agmIdx;
				}
			});
		});
		Util.viewerCheck(user.grade, agmIdx, () => action());
	};
	return (
		<>
			<Page
				title="배송 목록"
				contents={
					<div>
						{/* 검색 영역 */}
						<S.searchArea>
							<S.searchRow>
								<input
									type="date"
									max={"9999-12-31"}
									name="searchStrDt"
									id="searchStrDt"
									value={searchStrDt}
									onChange={(e) => {
										setSearchStrDt(e.target.value);
									}}
								/>
								<p> - </p>
								<input
									type="date"
									max={"9999-12-31"}
									name="searchEndDt"
									id="searchEndDt"
									value={searchEndDt}
									onChange={(e) => {
										setSearchEndDt(e.target.value);
									}}
								/>
								<input
									name="searchDtRadio"
									type="radio"
									id="searchDtRadio1"
									value={""}
									onClick={(event) =>
										setDate(
											(event.target as HTMLInputElement)
												.value
										)
									}
								/>
								<label htmlFor="searchDtRadio1">전체</label>
								<input
									name="searchDtRadio"
									type="radio"
									id="searchDtRadio2"
									value={0}
									onClick={(event) =>
										setDate(
											(event.target as HTMLInputElement)
												.value
										)
									}
								/>
								<label htmlFor="searchDtRadio2">금일</label>
								<input
									name="searchDtRadio"
									type="radio"
									id="searchDtRadio3"
									value={7}
									defaultChecked
									onClick={(event) =>
										setDate(
											(event.target as HTMLInputElement)
												.value
										)
									}
								/>
								<label htmlFor="searchDtRadio3">1주일</label>
								<input
									name="searchDtRadio"
									type="radio"
									id="searchDtRadio4"
									value={30}
									onClick={(event) =>
										setDate(
											(event.target as HTMLInputElement)
												.value
										)
									}
								/>
								<label htmlFor="searchDtRadio4">1개월</label>
								<input
									name="searchDtRadio"
									type="radio"
									id="searchDtRadio5"
									value={90}
									onClick={(event) =>
										setDate(
											(event.target as HTMLInputElement)
												.value
										)
									}
								/>
								<label htmlFor="searchDtRadio5">3개월</label>
							</S.searchRow>
							<S.searchRow>
								<select
									name="searchKey"
									id="searchKey1"
									onChange={(e) =>
										setSearchKey1(e.target.value)
									}>
									<option value="">= 선 택 =</option>
									<option value="1">회원번호</option>
									<option value="2">닉네임</option>
									<option value="3">보물명</option>
									<option value="4">보물번호</option>
									<option value="5">배송번호</option>
									<option value="6">송장번호</option>
								</select>
								<input
									type="text"
									id="searchWord1"
									onChange={(e) =>
										setSearchWord1(e.target.value)
									}
								/>
								<select
									name="searchKey"
									id="searchKey2"
									onChange={(e) =>
										setSearchKey2(e.target.value)
									}
									disabled={
										searchKey1 == "" || searchWord1 == ""
									}>
									<option value="">= 선 택 =</option>
									<option value="1">회원번호</option>
									<option value="2">닉네임</option>
									<option value="3">보물명</option>
									<option value="4">보물번호</option>
									<option value="5">배송번호</option>
									<option value="6">송장번호</option>
								</select>
								<input
									type="text"
									id="searchWord2"
									onChange={(e) =>
										setSearchWord2(e.target.value)
									}
									disabled={
										searchKey1 == "" || searchWord1 == ""
									}
								/>
								<S.searchButton onClick={() => search()}>
									<S.headerText>검색하기</S.headerText>
								</S.searchButton>
								<S.searchButton
									onClick={() => {
										viewerCheck(() => {
											GetList(activeTab, true);
										});
									}}
									color={S.color.primaryVariant}>
									<S.headerText>엑셀 다운로드</S.headerText>
								</S.searchButton>
								<S.filterButton
									onClick={() => {
										setOpenFilter(!isOpenFilter);
									}}
									className={`${isApplied && "applied"}`}>
									{isOpenFilter ? (
										<Minus
											width="2rem"
											height="2rem"
											fill="white"
										/>
									) : (
										<Plus
											width="2rem"
											height="2rem"
											fill="white"
										/>
									)}
								</S.filterButton>
							</S.searchRow>
							{/* 필터영역 */}
							<S.filterArea
								className={`${isOpenFilter && "expend"}`}>
								<S.filterWrap>
									{filterList.map(
										(filter: any, fi: number) => {
											return (
												<li key={fi}>
													<h2>{filter.title}</h2>
													<ul>
														<Filter
															fi={fi}
															filter={filter} // 필터 아이템 리스트
															option={
																filter.option
															}
															setOption={
																filter.setOption
															}
														/>
													</ul>
												</li>
											);
										}
									)}
								</S.filterWrap>
								<S.flexRowEnd>
									<button onClick={() => filter()}>
										적용
									</button>
									<button onClick={() => filterRefresh()}>
										전체 해제
									</button>
								</S.flexRowEnd>
							</S.filterArea>
						</S.searchArea>
						{/* 버튼 영역 */}
						<S.asideButtons>
							<button
								onClick={() => {
									viewerCheck(() => alert("준비중입니다."));
								}}>
								배송 KEY 등록
							</button>
						</S.asideButtons>
						{/* 탭 영역 */}
						<S.tabArea>
							<button
								className="tab active"
								onClick={(e) => {
									Util.tab({ e });
									setActiveTab("");
									pageResset("");
								}}>
								전체
							</button>
							<button
								className="tab"
								onClick={(e) => {
									Util.tab({ e });
									setActiveTab("01");
									pageResset("01");
								}}>
								결제 진행 중
							</button>
							<button
								className="tab"
								onClick={(e) => {
									Util.tab({ e });
									setActiveTab("-01");
									pageResset("-01");
								}}>
								결제 실패
							</button>
							<button
								className="tab"
								onClick={(e) => {
									Util.tab({ e });
									setActiveTab("00");
									pageResset("00");
								}}>
								배송 신청
							</button>
							<button
								className="tab"
								onClick={(e) => {
									Util.tab({ e });
									setActiveTab("10");
									pageResset("10");
								}}>
								배송 준비
							</button>
							<button
								className="tab"
								onClick={(e) => {
									Util.tab({ e });
									setActiveTab("20");
									pageResset("20");
								}}>
								배송 중
							</button>
							<button
								className="tab"
								onClick={(e) => {
									Util.tab({ e });
									setActiveTab("30");
									pageResset("30");
								}}>
								배송 완료
							</button>
							<button
								className="tab"
								onClick={(e) => {
									Util.tab({ e });
									setActiveTab("-10");
									pageResset("-10");
								}}>
								배송 취소
							</button>
							<button
								className="tab"
								onClick={(e) => {
									Util.tab({ e });
									setActiveTab("-11");
									pageResset("-11");
								}}>
								배송 불가
							</button>
							<button
								className="tab"
								onClick={(e) => {
									Util.tab({ e });
									setActiveTab("-100");
									pageResset("-100");
								}}>
								배송 지연
							</button>
						</S.tabArea>
						{/* 테이블 영역 */}
						<S.table01Wrap>
							<S.table01>
								<thead>
									<tr>
										<th>배송 번호</th>
										<th>보물 분류</th>
										<th>보물 정보</th>
										<th>회원 정보</th>
										<th>연락처</th>
										<th>배송 상태</th>
										<th>배송 신청 및 변경 일시</th>
										<th>택배사</th>
										<th>송장번호</th>
										<th>상세정보</th>
									</tr>
								</thead>
								<tbody>
									{list && list.length > 0 ? (
										<>
											{list.map((item, i) => (
												<tr key={i}>
													<td>{item.pdmIdx}</td>
													<td>{item.category}</td>
													<td
														className="wrap left"
														style={{
															maxWidth: "20rem",
														}}>
														[{item.pmIdx} |{" "}
														{item.pumIdx}]{`\n`}
														{item.name}
													</td>
													<td className="wrap left">
														[{item.mmIdx}]{`\n`}
														{item.nick}
													</td>
													<td>{item.tel}</td>
													<td>
														{item.deliveryState}
													</td>
													<td className="wrap">
														{Util.dateFormat(
															item.regDt
														)}
														{`\n`}
														{Util.dateFormat(
															item.modDt
														)}
													</td>
													<td>
														{
															item.deliveryCompanyCode
														}
													</td>
													<td>
														{item.invoiceNumber}
													</td>
													<td>
														<S.tableButton
															onClick={() =>
																openPopup(
																	item.pdmIdx
																)
															}>
															<S.headerText>
																자세히 보기
															</S.headerText>
														</S.tableButton>
													</td>
												</tr>
											))}
										</>
									) : (
										<tr>
											<td
												colSpan={999}
												style={{ textAlign: "center" }}>
												데이터가 없습니다.
											</td>
										</tr>
									)}
								</tbody>
							</S.table01>
						</S.table01Wrap>
						{/* 페이지네이션 영역 */}
						<Pagination
							totalItems={totalItems} // 데이터 총 개수
							currentPage={
								page && parseInt(page) > 0 ? parseInt(page) : 1
							} // 현재 페이지
							itemCountPerPage={listSize} // 페이지당 보여줄 데이터 개수
						/>
					</div>
				}
			/>
		</>
	);
};
