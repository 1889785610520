// 1:1 문의 상세정보
import React, { useState, useEffect, useRef } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { useSetRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { LoginAtom } from "../Recoil/Atoms/PopupState";
import { UserAtom, LoadingAtom, MenuAtom } from "../Recoil/Atoms/UserState";

import * as S from "../Styles/Style";
import Api from "../libs/api";
import Util from "../Store/Util";

// 이미지
import axios from "axios";

const BubbleArea = styled.div`
	min-height: 20rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start !important;
	justify-content: flex-start;
	display: flex;
	height: 100%;
	overflow-y: scroll;
	padding-right: 1rem;
	div {
		width: fit-content;
		min-width: 30%;
		max-width: 80%;
		background-color: ${S.color.gray02};
		border-radius: 0.5rem 2rem 1.5rem 2rem;
		margin-top: 1rem;
		margin-bottom: 1.5rem;
		padding: 1.5rem;
		position: relative;
		display: flex;
		flex-direction: column;
		/* 수정 버튼 */
		button {
			color: ${S.color.primaryLight};
			position: absolute;
			right: 0.2rem;
			top: 0.2rem;
		}
		p {
			font-family: "IBMPlexSansKRSemiBold";
			word-break: break-all;
			font-size: 1.2rem;
		}
		ul {
			display: flex;
			li {
				width: 4rem;
				height: 4rem;
				background-color: white;
				margin-right: 0.5rem;
				overflow: hidden;
				margin-top: 1rem;
				img {
					width: 1%;
					min-width: 100%;
					min-height: 100%;
				}
			}
		}
		span {
			position: absolute;
			top: calc(100% + 0.1rem);
			right: 0.5rem;
			color: ${S.color.gray03};
		}
	}
	div.answer {
		background-color: ${S.color.gray04};
		border-radius: 2rem 0.5rem 2rem 1.5rem;
		margin-left: auto;
		p {
			color: white;
		}
		span {
			left: 0.5rem;
			right: unset;
		}
	}
`;

interface qnaInfo {
	bqIdx: number;
	bq_file1: string;
	bq_file2: string;
	bq_file3: string;
	bq_questionSubject: string;
	bq_questionText: string;
	categoryCode: string;
	email: string;
	mmIdx: 1;
	name: string;
	nick: string;
	progressCode: string;
	regDt: string;
	tel: string;
}
interface qnaList {
	bqIdx: number;
	bqcIdx: string;
	comment: string;
	file1: string;
	file2: string;
	file3: string;
	regDt: string;
	regIdx: number;
	regType: number;
	viewCount: number;
}
export default () => {
	const location = useLocation();
	const menu = useRecoilValue(MenuAtom);
	let agmIdx: number;
	const user = useRecoilValue(UserAtom);
	const setOpenLogin = useSetRecoilState<boolean>(LoginAtom);
	const setLoading = useSetRecoilState<boolean>(LoadingAtom);
	const apiUrl = process.env.REACT_APP_API_URL;
	const imgUrl = process.env.REACT_APP_IMG_URL;
	const token = localStorage.getItem("token");
	const [searchParams, setSearchParams] = useSearchParams();
	const messageBoxRef = useRef<HTMLDivElement | null>(null);
	const bqIdx = searchParams.get("bqIdx");
	const [qnaInfo, setQnaInfo] = useState<qnaInfo>();
	const [qnaList, setQnaList] = useState<qnaList[]>();
	const [bqcIdx, setBqcIdx] = useState<string | null>(null);
	const [comment, setComment] = useState<string>("");
	const [imageList, setImageList] = useState<
		{
			image: string;
			file: File | null;
		}[]
	>([
		{ image: "", file: null },
		{ image: "", file: null },
		{ image: "", file: null },
	]);
	// 맨 아래로
	useEffect(() => {
		if (messageBoxRef.current) {
			messageBoxRef.current.scrollTop =
				messageBoxRef.current?.scrollHeight;
		}
	}, [qnaList]);
	useEffect(() => {
		user.isLogin && GetData();
	}, [user]);
	// 문의 상세 정보 불러오기
	const GetData = async () => {
		setLoading(true);
		if (bqIdx == undefined) return false;
		await Api.get(`board/qna/view/${bqIdx}`)
			.then((res: any) => {
				if (res.data == null) {
					const code = res.response.status;
					if (code == 401) {
						setOpenLogin(true);
					}
				} else {
					console.log(res.data.data);
					if (res.data.statusCode == 200) {
						setQnaInfo(res.data.data.qnaInfo[0]);
						setQnaList(res.data.data.qnaList);
					}
				}
			})
			.catch((error) => {
				console.log(error.response.data, "wwwwww");
			});
		setLoading(false);
	};
	// 업로드된 이미지 불러오기
	const renderImageList = (file: string[]) => {
		return (
			<>
				{file?.map((item, index) => {
					if (item !== "") {
						return (
							<li
								key={index}
								onClick={() => {
									localStorage.setItem(
										"sendData",
										JSON.stringify(imgUrl + item)
									);
									window.open(
										"/PREV001001",
										"_blank",
										"width=800, height=800, left=100, top=100"
									);
								}}
							>
								<img src={imgUrl + item} alt="" />
							</li>
						);
					}
				})}
			</>
		);
	};

	// 이미지 업로드
	const uploadImage = async (
		e: React.ChangeEvent<HTMLInputElement>,
		index: number
	) => {
		if (e.target.files !== null) {
			const file = e.target.files[0];
			if (file) {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => {
					setImageList(
						imageList.map((item, i) =>
							i === index
								? {
										...item,
										image: reader.result as string,
										file: file,
								  }
								: item
						)
					);
				};
			}
		}
	};

	// 수정 버튼 클릭
	const editReply = (item: qnaList) => {
		setBqcIdx(item.bqcIdx);
		setComment(item.comment);
		setImageList([
			{ image: item.file1 ? imgUrl + item.file1 : "", file: null },
			{ image: item.file2 ? imgUrl + item.file2 : "", file: null },
			{ image: item.file3 ? imgUrl + item.file3 : "", file: null },
		]);
	};

	// 답변 완료 처리
	const progressCodeUpdate = (progressCode: string) => {
		if (
			window.confirm(
				`${
					progressCode == "1"
						? "답변을 완료처리 "
						: "답변 완료를 답변 중으로 변경"
				}하시겠습니까?`
			)
		) {
			Api.patch("board/progressCodeUpdate", {
				bqIdx: qnaInfo?.bqIdx,
				progressCode: progressCode,
				mmIdx: qnaInfo?.mmIdx,
			})
				.then((res: any) => {
					if (res.data == null) {
						const code = res.response.status;
						if (code == 401) {
							setOpenLogin(true);
						}
					} else {
						console.log(res.data.statusCode);
						if (res.data.statusCode == 200) {
							alert("완료되었습니다.");
							window.location.reload();
							window.opener.location.reload();
						}
					}
				})
				.catch((error) => {
					console.log(error.response.data, "wwwwww");
				});
		}
	};

	// 답변 등록하기
	const onSubmit = async (e: any) => {
		e.preventDefault();

		viewerCheck(async () => {
			const formData = new FormData();
			let method;
			let url;

			console.log(imageList);
			// 등록/수정 구분
			if (bqcIdx == null) {
				method = "post";
				url = "board/replyInsert";
				formData.append("bqIdx", bqIdx || "");
			} else {
				method = "patch";
				url = "board/replyUpdate";
				formData.append("bqcIdx", bqcIdx || "");
			}
			formData.append("comment", comment);
			let delNo: number[] = [];
			imageList.map((item, index) => {
				if (item.image == "") {
					// 기존 파일 삭제
					delNo.push(index + 1);
				} else {
					if (item.file !== null) {
						// 추가 및 수정
						formData.append(`answer${index + 1}`, item.file);
					}
					// 기존 파일 유지
				}
			});
			formData.append(`delNo`, delNo.join());

			for (let key of formData.keys()) {
				console.log(key, ":", formData.get(key));
			}

			if (
				window.confirm(
					`답변을 ${bqcIdx == null ? "등록" : "수정"} 하시겠습니까?`
				)
			) {
				setLoading(true);
				await axios({
					method: method,
					url: apiUrl + url,
					data: formData,
					headers: {
						Authorization: token,
						"content-type": "multipart/form-data",
					},
				})
					.then((res) => {
						console.log(res);
						if (res.data.statusCode == 200) {
							// 답변중으로 변경
							Api.patch("board/progressCodeUpdate", {
								bqIdx: qnaInfo?.bqIdx,
								progressCode: 2,
								mmIdx: qnaInfo?.mmIdx,
							}).then((res: any) => {
								alert("완료되었습니다.");
								window.location.reload();
								window.opener.location.reload();
							});
						} else if (res.data.statusCode == -201) {
							alert(
								"사용자가 문의를 읽은 상태로\n수정하실 수 없습니다."
							);
						}
					})
					.catch((error) => {
						console.log(error);
						alert("등록 실패");
					});
				setLoading(false);
			}
		});
	};
	// 권한 확인(뷰어 제한) 후 액션
	function viewerCheck(action: () => void) {
		menu.map((m) => {
			m.sub.map((s) => {
				if (s.link.slice(0, 8) == location.pathname.slice(0, 8)) {
					agmIdx = s.agmIdx;
				}
			});
		});
		Util.viewerCheck(user.grade, Number(agmIdx), () => action());
	}

	return (
		<>
			<S.popupPage>
				<h1>
					<S.headerText>1:1 문의</S.headerText>
				</h1>
				{/* 테이블 영역 */}
				<S.blank1 />
				<h2>문의 내용</h2>
				<S.table03 style={{ flexGrow: 1, height: "1px" }}>
					<tbody>
						<tr>
							<th>문의번호</th>
							<td>
								<input
									type="text"
									value={bqIdx || "-"}
									readOnly
								/>
							</td>
							<th>분류</th>
							<td>
								<input type="text" value={""} readOnly />
							</td>
							<th>이름</th>
							<td>
								<input
									type="text"
									value={qnaInfo?.name || "-"}
									readOnly
								/>
							</td>
						</tr>
						<tr>
							<th>회원 번호</th>
							<td>
								<input
									type="text"
									value={qnaInfo?.mmIdx || "-"}
									readOnly
								/>
							</td>
							<th>닉네임</th>
							<td>
								<input
									type="text"
									value={qnaInfo?.nick || "-"}
									readOnly
								/>
							</td>
							<th>이메일</th>
							<td>
								<input
									type="text"
									value={qnaInfo?.email || "-"}
									readOnly
								/>
							</td>
						</tr>
						<tr>
							<th>제목</th>
							<td colSpan={3}>
								<input
									type="text"
									value={qnaInfo?.bq_questionSubject || "-"}
									readOnly
								/>
							</td>
							<th>연락처</th>
							<td>
								<input
									type="text"
									value={qnaInfo?.tel || "-"}
									readOnly
								/>
							</td>
						</tr>
						<tr style={{ height: "100%" }}>
							<td colSpan={6} style={{ padding: 0 }}>
								<BubbleArea ref={messageBoxRef}>
									{/* 최초 질문 */}
									<div className="question">
										<p>{qnaInfo?.bq_questionText}</p>
										<ul>
											{renderImageList([
												qnaInfo?.bq_file1 || "",
												qnaInfo?.bq_file2 || "",
												qnaInfo?.bq_file3 || "",
											])}
										</ul>
										<span>
											{Util.dateFormat(
												qnaInfo?.regDt || ""
											)}
										</span>
									</div>
									{/* 문답 리스트 */}
									{qnaList?.map((item, index) => {
										return (
											<div
												key={index}
												className={
													item.regType == 0
														? "question"
														: "answer"
												}
											>
												{item.regType == 1 &&
													item.viewCount == 0 &&
													qnaInfo?.progressCode !==
														"1" && (
														<button
															onClick={() =>
																viewerCheck(
																	() =>
																		editReply(
																			item
																		)
																)
															}
														>
															수정
														</button>
													)}
												<p>{item.comment}</p>
												<ul>
													{renderImageList([
														item.file1,
														item.file2,
														item.file3,
													])}
												</ul>
												<span>
													{Util.dateFormat(
														item.regDt
													)}
												</span>
											</div>
										);
									})}
								</BubbleArea>
							</td>
						</tr>
					</tbody>
				</S.table03>
				<S.blank3 />
				<form onSubmit={onSubmit}>
					<h2>답변 작성</h2>
					<S.table02>
						<tbody>
							<tr>
								<th>문의 답변</th>
							</tr>
							<tr>
								<td colSpan={3}>
									<S.textAreaWrap>
										<textarea
											name="comment"
											id="comment"
											cols={30}
											rows={3}
											value={comment}
											onChange={(e) =>
												setComment(e.target.value)
											}
											placeholder="답변을 입력해 주세요."
											required
											readOnly={
												qnaInfo?.progressCode == "1"
											}
										></textarea>
									</S.textAreaWrap>
								</td>
							</tr>
							<tr>
								<th>파일첨부</th>
							</tr>
							<tr>
								<td colSpan={3} className="imageArea">
									<S.flexRowBetween>
										{imageList.map((item, index) => {
											return (
												<React.Fragment key={index}>
													{imageList[index].image ==
													"" ? (
														<S.blankImageArea
															className={
																qnaInfo?.progressCode ==
																"1"
																	? "disabled"
																	: ""
															}
														>
															<input
																id={`answer${
																	index + 1
																}`}
																name="image"
																type="file"
																accept=".png, .jpeg, .jpg"
																onChange={(e) =>
																	uploadImage(
																		e,
																		index
																	)
																}
																disabled={
																	qnaInfo?.progressCode ==
																	"1"
																}
															/>
															<label
																htmlFor={`answer${
																	index + 1
																}`}
															>
																파일 선택
															</label>
														</S.blankImageArea>
													) : (
														<S.filledImagesArea
															onClick={() => {
																localStorage.setItem(
																	"sendData",
																	JSON.stringify(
																		item.image
																	)
																);
																window.open(
																	"/PREV001001",
																	"_blank",
																	"width=800, height=800, left=100, top=100"
																);
															}}
														>
															<img
																src={
																	item.image
																		? item.image
																		: ""
																}
																alt={`answer${
																	index + 1
																}`}
															/>
															<button
																onClick={(
																	e
																) => {
																	e.stopPropagation();
																	setImageList(
																		imageList.map(
																			(
																				el,
																				i
																			) =>
																				i ===
																				index
																					? {
																							...el,
																							image: "",
																							file: null,
																					  }
																					: el
																		)
																	);
																}}
															></button>
														</S.filledImagesArea>
													)}
												</React.Fragment>
											);
										})}
									</S.flexRowBetween>
								</td>
							</tr>
						</tbody>
					</S.table02>
					<S.blank2 />
					<S.flexRowCenter>
						{bqcIdx == null ? (
							<S.filledButton
								type="button"
								onClick={() => {
									viewerCheck(
										() =>
											qnaInfo?.progressCode !== "1"
												? progressCodeUpdate("1") // 답변 완료 처리
												: progressCodeUpdate("2") // 답변 중 처리
									);
								}}
							>
								답변{" "}
								{qnaInfo?.progressCode !== "1"
									? "완료 처리"
									: "중 으로 변경"}
							</S.filledButton>
						) : (
							<S.filledButton
								type="button"
								onClick={() => window.location.reload()}
							>
								답변 수정 취소
							</S.filledButton>
						)}
						{qnaInfo?.progressCode !== "1" && (
							<S.filledButton type="submit">
								답변 {bqcIdx == null ? "등록" : "수정"}하기
							</S.filledButton>
						)}
					</S.flexRowCenter>
				</form>
			</S.popupPage>
		</>
	);
};
