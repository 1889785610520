// 보물 상자
import { useState, useEffect, useRef } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { LoginAtom } from "../Recoil/Atoms/PopupState";
import { UserAtom, LoadingAtom, MenuAtom } from "../Recoil/Atoms/UserState";

import * as S from "../Styles/Style";
import Api from "../libs/api";
import Util from "../Store/Util";
import Page from "../Components/Page";
import Pagination from "../Components/Pagination";
import Filter from "../Components/Filter";

// 이미지
import { ReactComponent as Plus } from "../Images/plus.svg";
import { ReactComponent as Minus } from "../Images/minus.svg";

interface list {
	bmIdx: number;
	boxComponentCnt: number;
	boxDesc: string;
	boxImageUrl: string;
	boxPrice: number;
	boxSaleEndDt: string;
	boxSaleStartDt: string;
	boxType: string;
	displayYn: number;
	modDt: string;
	modIdx: number;
	modIp: string;
	name: string;
	orderNo: number;
	regDt: string;
	regIdx: number;
	regIp: string;
	useYn: number;
}

export default () => {
	const location = useLocation();
	const menu = useRecoilValue(MenuAtom);
	let agmIdx: number;
	const user = useRecoilValue(UserAtom);
	const setOpenLogin = useSetRecoilState<boolean>(LoginAtom);
	const setLoading = useSetRecoilState<boolean>(LoadingAtom);
	const [isOpenFilter, setOpenFilter] = useState<boolean>(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const page = searchParams.get("page");
	const listSize = 20;
	const [totalItems, setTotalItems] = useState<number>(0);
	const [list, setList] = useState<list[] | null>([]);
	// 검색 옵션
	const searchOptionsRef = useRef({});
	const [searchOptions, setSearchOptions] = useState({});
	// 필터 옵션
	const filterOptionsRef = useRef({});
	const [useYn, setUseYn] = useState<string[]>([]);
	const [isApplied, setIsApplied] = useState<boolean>(false);
	const filterList = [
		{
			title: "노출 여부",
			name: "useYn",
			items: [
				{ text: "노출", value: "1" },
				{ text: "미노출", value: "0" },
			],
			option: useYn,
			setOption: setUseYn,
		},
	];

	// 1페이지로 이동되면 리셋
	const pageResset = (tab?: string) => {
		if (page == "1") {
			user.isLogin && GetList();
		} else {
			setSearchParams({ page: "1" });
		}
	};
	useEffect(() => {
		window.scrollTo(0, 0); // 페이지 이동 시 스크롤 위치 맨 위로 초기화
		user.isLogin && GetList();
	}, [page, user]);

	// 목록 불러오기
	const GetList = async () => {
		setLoading(true);
		await Api.get("box/boxList", {
			...searchOptionsRef.current,
			...filterOptionsRef.current,
			page: page,
			listSize: listSize,
		})
			.then((res: any) => {
				if (res.data == null) {
					const code = res.response.status;
					if (code == 401) {
						setOpenLogin(true);
					}
				} else {
					console.log(res.data.data);
					setList(res.data.data.data);
					setTotalItems(res.data.data.cnt);
				}
			})
			.catch((error) => {
				console.log(error.response.data, "wwwwww");
			});
		setLoading(false);
	};
	// 상자명
	const boxName = (bmIdx: number | null) => {
		switch (bmIdx) {
			case 1:
				return "이벤트상자";
			case 2:
				return "골드상자";
			case 3:
				return "무료상자";
			default:
				return "-";
		}
	};
	// 노출 여부
	const displayYnText = (displayYn: number | null) => {
		switch (displayYn) {
			case 1:
				return "노출";
			case 0:
				return "미노출";
			default:
				return "-";
		}
	};

	// 공통
	// 검색
	const search = () => {
		searchOptionsRef.current = {
			...searchOptionsRef.current,
			...searchOptions,
		};
		pageResset();
	};
	// 필터 선택 취소
	const filterRefresh = () => {
		setUseYn([]);
	};
	// 필터 적용
	const filter = () => {
		filterOptionsRef.current = { useYn: useYn.join() };
		pageResset();
		setIsApplied(useYn.length > 0);
	};
	// 권한 확인(뷰어 제한) 후 액션
	const viewerCheck = (action: () => void) => {
		menu.map((m) => {
			m.sub.map((s) => {
				if (s.link == location.pathname) {
					agmIdx = s.agmIdx;
				}
			});
		});
		Util.viewerCheck(user.grade, agmIdx, () => action());
	};

	return (
		<Page
			title="보물 상자"
			contents={
				<div>
					{/* 검색 영역 */}
					<S.searchArea>
						<S.searchRow>
							<input
								type="text"
								id="searchWord"
								onChange={(e) =>
									setSearchOptions({
										...searchOptions,
										searchWord: e.target.value,
									})
								}
							/>
							<S.searchButton onClick={() => search()}>
								<S.headerText>검색하기</S.headerText>
							</S.searchButton>
							<S.filterButton
								onClick={() => {
									setOpenFilter(!isOpenFilter);
								}}
								className={`${isApplied && "applied"}`}
							>
								{isOpenFilter ? (
									<Minus
										width="2rem"
										height="2rem"
										fill="white"
									/>
								) : (
									<Plus
										width="2rem"
										height="2rem"
										fill="white"
									/>
								)}
							</S.filterButton>
						</S.searchRow>
						{/* 필터영역 */}
						<S.filterArea className={`${isOpenFilter && "expend"}`}>
							<S.filterWrap>
								{filterList.map((filter: any, fi: number) => {
									return (
										<li key={fi}>
											<h2>{filter.title}</h2>
											<ul>
												<Filter
													fi={fi}
													filter={filter} // 필터 아이템 리스트
													option={filter.option}
													setOption={filter.setOption}
												/>
											</ul>
										</li>
									);
								})}
							</S.filterWrap>
							<S.flexRowEnd>
								<button onClick={() => filter()}>적용</button>
								<button onClick={() => filterRefresh()}>
									전체 해제
								</button>
							</S.flexRowEnd>
						</S.filterArea>
					</S.searchArea>
					{/* 테이블 영역 */}
					<S.table01Wrap>
						<S.table01>
							<thead>
								<tr>
									<th>번호</th>
									<th>상자명</th>
									<th>상자 가격(P)</th>
									<th>상자 이미지</th>
									<th>등록일시</th>
									<th>노출 여부</th>
									<th>관리</th>
								</tr>
							</thead>
							<tbody>
								{list && list.length > 0 ? (
									<>
										{list.map((item, i) => (
											<tr key={i}>
												<td>{item.bmIdx}</td>
												<td>{boxName(item.bmIdx)}</td>
												<td>
													{Util.addComma(
														item.boxPrice
													)}
												</td>
												<td>
													{item.boxImageUrl
														? "Y"
														: "N"}
												</td>
												<td>
													{Util.dateFormat(
														item.regDt
													)}
												</td>
												<td>
													{displayYnText(
														item.displayYn
													)}
												</td>
												<td>
													<S.tableButton>
														<S.headerText>
															자세히 보기
														</S.headerText>
													</S.tableButton>
												</td>
											</tr>
										))}
									</>
								) : (
									<tr>
										<td
											colSpan={999}
											style={{ textAlign: "center" }}
										>
											데이터가 없습니다.
										</td>
									</tr>
								)}
							</tbody>
						</S.table01>
					</S.table01Wrap>
					{/* 페이지네이션 영역 */}
					<Pagination
						totalItems={totalItems} // 데이터 총 개수
						currentPage={
							page && parseInt(page) > 0 ? parseInt(page) : 1
						} // 현재 페이지
						itemCountPerPage={listSize} // 페이지당 보여줄 데이터 개수
					/>
				</div>
			}
		/>
	);
};
