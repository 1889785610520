// 결제 내역 목록
import { useState, useEffect, useRef } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { useSetRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { LoginAtom } from "../Recoil/Atoms/PopupState";
import { UserAtom, LoadingAtom, MenuAtom } from "../Recoil/Atoms/UserState";

import * as S from "../Styles/Style";
import Api from "../libs/api";
import Util from "../Store/Util";
import Page from "../Components/Page";
import Pagination from "../Components/Pagination";
import Filter from "../Components/Filter";

// 이미지
import { ReactComponent as Plus } from "../Images/plus.svg";
import { ReactComponent as Minus } from "../Images/minus.svg";

const RankWrap = styled(S.table01Wrap)`
	display: flex;
	background-color: ${S.color.gray00};
	padding: 2rem 1rem;
	flex-grow: unset;
	margin-bottom: 2rem;
	&::-webkit-scrollbar {
		display: none;
	}
	& > div {
		min-width: calc(50% - 1.5rem);
		margin-right: 3rem;
	}
	h1 {
		flex-grow: 1;
		font-size: 1.4rem;
		margin-right: 1rem;
	}
	table {
		background-color: white;
		th {
			background-color: ${S.color.gray01};
		}
	}
	div:last-child table {
		margin-right: 0;
	}
`;

interface list {
	mmIdx: string;
	nick: string;
	payMethod: string;
	paymentCash: string;
	paymentProgress: string;
	ptIdx: string;
	quantity: string;
	regDt: string;
	tXid: string;
	type: string;
}

interface rank {
	mmIdx: string;
	nick: string;
	paymentCash?: string;
	cnt?: string;
}

export default () => {
	const location = useLocation();
	const menu = useRecoilValue(MenuAtom);
	let agmIdx: number;
	const user = useRecoilValue(UserAtom);
	const setOpenLogin = useSetRecoilState<boolean>(LoginAtom);
	const setLoading = useSetRecoilState<boolean>(LoadingAtom);
	const [searchParams, setSearchParams] = useSearchParams();
	const page = searchParams.get("page");
	const listSize = 20;
	const [totalItems, setTotalItems] = useState<number>(0);
	const [list, setList] = useState<list[] | null>([]);
	const [stateCnt, setStateCnt] = useState<any>();
	const now = new Date(Util.getNow());
	// 검색 옵션(금일 기본)
	const searchOptionsRef = useRef<{}>({
		searchStrDt: Util.daysAgo(now, 0),
		searchEndDt: Util.getNowString().slice(0, 8),
	});
	const [searchOptions, setSearchOptions] = useState({});
	// 검색 일자
	const [searchStrDt, setSearchStrDt] = useState<string>("");
	const [searchEndDt, setSearchEndDt] = useState<string>(
		Util.inputDateFormat(now, "date")
	);
	// 필터 옵션
	const filterOptionsRef = useRef({});
	const [isOpenFilter, setOpenFilter] = useState<boolean>(false);
	const [isApplied, setIsApplied] = useState<boolean>(false);
	const [paymentProductType, setPaymentProductType] = useState<string[]>([]);
	const [payMethod, setPayMethod] = useState<string[]>([]);
	const [transactionState, setTransactionState] = useState<string[]>([]);
	// 순위
	const [isVisibleRank, setVisibleRank] = useState<boolean>(false);
	const [payRank, setPayRank] = useState<rank[] | null>([]);
	const [openRank, setOpenRank] = useState<rank[] | null>([]);

	const filterList = [
		{
			title: "유형",
			name: "paymentProductType",
			items: [
				{ text: "상자", value: 100 },
				{ text: "배송비", value: 200 },
			],
			option: paymentProductType,
			setOption: setPaymentProductType,
		},
		{
			title: "결제 수단",
			name: "payMethod",
			items: [
				{ text: "가상계좌", value: "02" },
				{ text: "QRIS", value: "08" },
				{ text: "전자지갑", value: "05" },
				{ text: "신용카드", value: "01" },
			],
			option: payMethod,
			setOption: setPayMethod,
		},
		{
			title: "구분",
			name: "transactionState",
			items: [
				{ text: "결제 중", value: "1" },
				{ text: "결제 실패", value: "-2" },
				{ text: "결제 성공", value: "2" },
			],
			option: transactionState,
			setOption: setTransactionState,
		},
	];
	// 1페이지로 이동되면 리셋
	const pageResset = () => {
		if (page == "1") {
			user.isLogin && GetList();
		} else {
			setSearchParams({ page: "1" });
		}
	};
	useEffect(() => {
		setSearchStrDt(Util.inputDateFormat(new Date(), "date"));
	}, []);
	useEffect(() => {
		window.scrollTo(0, 0); // 페이지 이동 시 스크롤 위치 맨 위로 초기화
		user.isLogin && GetList();
	}, [user, page]);
	// 목록 불러오기
	const GetList = async () => {
		setLoading(true);
		console.log(searchOptionsRef.current);
		await Api.get("payment/paymentListSelect", {
			...searchOptionsRef.current,
			...filterOptionsRef.current,
			page: page,
			listSize: listSize,
		})
			.then((res: any) => {
				console.log(res.data);
				if (res.data == null) {
					const code = res.response.status;
					if (code == 401) {
						setOpenLogin(true);
					}
				} else {
					setList(res.data.data.data);
					setStateCnt(res.data.data.totalPayment);
					setTotalItems(res.data.data.totalCnt);
				}
			})
			.catch((error) => {
				console.log(error.response, "wwwwww");
			});
		setLoading(false);
	};
	// 순위 정보 불러오기
	const rankSelect = async (type: number, searchDate: number | null) => {
		setLoading(true);
		await Api.get("payment/rankSelect", { searchDate: searchDate })
			.then((res: any) => {
				if (res.data == null) {
					const code = res.response.status;
					if (code == 401) {
						setOpenLogin(true);
					}
				} else {
					if (type == 0) {
						return setPayRank(res.data.data.payRank);
					} else if (type == 1) {
						return setOpenRank(res.data.data.openRank);
					} else if (type == 2) {
						setPayRank(res.data.data.payRank);
						setOpenRank(res.data.data.openRank);
					}
				}
			})
			.catch((error) => {
				console.log(error.response, "wwwwww");
			});
		setLoading(false);
	};

	// 공통
	// 일자
	const setDate = (value: any) => {
		const year = now.getFullYear(); // 년
		const month = now.getMonth(); // 월
		const day = now.getDate(); // 일

		if (value == "") {
			setSearchStrDt("");
		} else {
			const start = new Date(
				year,
				month,
				day - value
			).toLocaleDateString();
			const startDt = new Date(start);

			setSearchStrDt(Util.inputDateFormat(startDt, "date"));
		}
		setSearchEndDt(Util.inputDateFormat(now, "date"));
	};
	useEffect(() => {
		setSearchOptions({
			...searchOptions,
			searchStrDt: Util.onlyNum(searchStrDt),
			searchEndDt: Util.onlyNum(searchEndDt),
		});
	}, [searchStrDt, searchEndDt]);
	// 검색
	const search = () => {
		searchOptionsRef.current = {
			...searchOptionsRef.current,
			...searchOptions,
		};
		pageResset();
	};
	// 필터 선택 취소
	const filterRefresh = () => {
		setPaymentProductType([]);
		setPayMethod([]);
		setTransactionState([]);
	};
	// 필터 적용
	const filter = () => {
		filterOptionsRef.current = {
			paymentProductType: paymentProductType.join(),
			payMethod: payMethod.join(),
			transactionState: transactionState.join(),
		};
		pageResset();
		setIsApplied(
			paymentProductType.length > 0 ||
				payMethod.length > 0 ||
				transactionState.length > 0
		);
	};
	// 권한 확인(뷰어 제한) 후 액션
	const viewerCheck = (action: () => void) => {
		menu.map((m) => {
			m.sub.map((s) => {
				if (s.link == location.pathname) {
					agmIdx = s.agmIdx;
				}
			});
		});
		Util.viewerCheck(user.grade, agmIdx, () => action());
	};
	return (
		<>
			<Page
				title="결제 내역 목록"
				contents={
					<div>
						{/* 검색 영역 */}
						<S.searchArea>
							<S.searchRow>
								<input
									type="date"
									max={"9999-12-31"}
									name="searchStrDt"
									id="searchStrDt"
									value={searchStrDt}
									onChange={(e) => {
										setSearchStrDt(e.target.value);
									}}
								/>
								<p> - </p>
								<input
									type="date"
									max={"9999-12-31"}
									name="searchEndDt"
									id="searchEndDt"
									value={searchEndDt}
									onChange={(e) => {
										setSearchEndDt(e.target.value);
									}}
								/>
								<input
									name="searchDtRadio"
									type="radio"
									id="searchDtRadio1"
									value={""}
									onClick={(event) =>
										setDate(
											(event.target as HTMLInputElement)
												.value
										)
									}
								/>
								<label htmlFor="searchDtRadio1">전체</label>
								<input
									name="searchDtRadio"
									type="radio"
									id="searchDtRadio2"
									value={0}
									defaultChecked
									onClick={(event) =>
										setDate(
											(event.target as HTMLInputElement)
												.value
										)
									}
								/>
								<label htmlFor="searchDtRadio2">금일</label>
								<input
									name="searchDtRadio"
									type="radio"
									id="searchDtRadio3"
									value={7}
									onClick={(event) =>
										setDate(
											(event.target as HTMLInputElement)
												.value
										)
									}
								/>
								<label htmlFor="searchDtRadio3">1주일</label>
								<input
									name="searchDtRadio"
									type="radio"
									id="searchDtRadio4"
									value={30}
									onClick={(event) =>
										setDate(
											(event.target as HTMLInputElement)
												.value
										)
									}
								/>
								<label htmlFor="searchDtRadio4">1개월</label>
								<input
									name="searchDtRadio"
									type="radio"
									id="searchDtRadio5"
									value={90}
									onClick={(event) =>
										setDate(
											(event.target as HTMLInputElement)
												.value
										)
									}
								/>
								<label htmlFor="searchDtRadio5">3개월</label>
							</S.searchRow>
							<S.searchRow>
								<select
									name="searchKey"
									id="searchKey"
									onChange={(e) =>
										setSearchOptions({
											...searchOptions,
											searchKey: e.target.value,
										})
									}>
									<option value="">= 선 택 =</option>
									<option value="1">회원번호</option>
									<option value="2">닉네임</option>
									<option value="3">영수증번호</option>
								</select>
								<input
									type="text"
									id="searchWord"
									onChange={(e) =>
										setSearchOptions({
											...searchOptions,
											searchWord: e.target.value,
										})
									}
								/>
								<S.searchButton onClick={() => search()}>
									<S.headerText>검색하기</S.headerText>
								</S.searchButton>
								<S.searchButton
									onClick={() => {
										viewerCheck(() => {
											if (
												window.confirm(
													"엑셀을 다운 받으시겠습니까?"
												)
											) {
												alert("엑셀다운로드 개발 전");
											}
										});
									}}
									color={S.color.primaryVariant}>
									<S.headerText>엑셀 다운로드</S.headerText>
								</S.searchButton>
								<S.filterButton
									onClick={() => {
										setOpenFilter(!isOpenFilter);
									}}
									className={`${isApplied && "applied"}`}>
									{isOpenFilter ? (
										<Minus
											width="2rem"
											height="2rem"
											fill="white"
										/>
									) : (
										<Plus
											width="2rem"
											height="2rem"
											fill="white"
										/>
									)}
								</S.filterButton>
							</S.searchRow>
							{/* 필터영역 */}
							<S.filterArea
								className={`${isOpenFilter && "expend"}`}>
								<S.filterWrap>
									{filterList.map(
										(filter: any, fi: number) => {
											return (
												<li key={fi}>
													<h2>{filter.title}</h2>
													<ul>
														<Filter
															fi={fi}
															filter={filter} // 필터 아이템 리스트
															option={
																filter.option
															}
															setOption={
																filter.setOption
															}
														/>
													</ul>
												</li>
											);
										}
									)}
								</S.filterWrap>
								<S.flexRowEnd>
									<button onClick={() => filter()}>
										적용
									</button>
									<button onClick={() => filterRefresh()}>
										전체 해제
									</button>
								</S.flexRowEnd>
							</S.filterArea>
						</S.searchArea>
						{/* 버튼 영역 */}
						<S.asideButtons>
							<button
								className={`expandable ${
									isVisibleRank && "expended"
								}`}
								onClick={() => {
									if (isVisibleRank) {
										setVisibleRank(false);
									} else {
										rankSelect(2, 1);
										setVisibleRank(true);
									}
								}}>
								상자 순위
							</button>
						</S.asideButtons>
						{/* 상자 순위 테이블 영역 */}
						{isVisibleRank && (
							<RankWrap>
								<div>
									<S.normalRadioArea>
										<h1>구매 순위</h1>
										<S.normalRadio>
											<input
												type="radio"
												name="buyDate"
												id="buyDate"
												onChange={(e) =>
													rankSelect(0, null)
												}
											/>
											<label htmlFor="buyDate" />
										</S.normalRadio>
										<S.normalLabel>전체</S.normalLabel>
										<S.normalRadio>
											<input
												type="radio"
												name="buyDate"
												id="buyDate0"
												defaultChecked={true}
												onChange={(e) =>
													rankSelect(0, 1)
												}
											/>
											<label htmlFor="buyDate0" />
										</S.normalRadio>
										<S.normalLabel>금일</S.normalLabel>
										<S.normalRadio>
											<input
												type="radio"
												name="buyDate"
												id="buyDate1"
												onChange={(e) =>
													rankSelect(0, 2)
												}
											/>
											<label htmlFor="buyDate1" />
										</S.normalRadio>
										<S.normalLabel>1개월</S.normalLabel>
										<S.normalRadio>
											<input
												type="radio"
												name="buyDate"
												id="buyDate2"
												onChange={(e) =>
													rankSelect(0, 3)
												}
											/>
											<label htmlFor="buyDate2" />
										</S.normalRadio>
										<S.normalLabel>3개월</S.normalLabel>
									</S.normalRadioArea>
									<S.blank1 />
									<S.table01>
										<thead>
											<tr>
												<th>순위</th>
												<th>회원번호</th>
												<th>회원닉네임</th>
												<th>결제 금액(Rp)</th>
											</tr>
										</thead>
										<tbody>
											{payRank && payRank.length > 0 ? (
												<>
													{payRank?.map(
														(item, index) => (
															<tr key={index}>
																<td>
																	{index + 1}
																</td>
																<td>
																	{item.mmIdx}
																</td>
																<td>
																	{item.nick}
																</td>
																<td>
																	{Util.addComma(
																		Number(
																			item.paymentCash ||
																				0
																		)
																	)}
																</td>
															</tr>
														)
													)}
												</>
											) : (
												<tr>
													<td
														colSpan={999}
														style={{
															textAlign: "center",
														}}>
														데이터가 없습니다.
													</td>
												</tr>
											)}
										</tbody>
									</S.table01>
								</div>
								<div>
									<S.normalRadioArea>
										<h1>오픈 순위</h1>
										<S.normalRadio>
											<input
												type="radio"
												name="openDate"
												id="openDate"
												onChange={(e) =>
													rankSelect(1, null)
												}
											/>
											<label htmlFor="openDate" />
										</S.normalRadio>
										<S.normalLabel>전체</S.normalLabel>
										<S.normalRadio>
											<input
												type="radio"
												name="openDate"
												id="openDate0"
												defaultChecked={true}
												onChange={(e) =>
													rankSelect(1, 1)
												}
											/>
											<label htmlFor="openDate0" />
										</S.normalRadio>
										<S.normalLabel>금일</S.normalLabel>
										<S.normalRadio>
											<input
												type="radio"
												name="openDate"
												id="openDate1"
												onChange={(e) =>
													rankSelect(1, 2)
												}
											/>
											<label htmlFor="openDate1" />
										</S.normalRadio>
										<S.normalLabel>1개월</S.normalLabel>
										<S.normalRadio>
											<input
												type="radio"
												name="openDate"
												id="openDate2"
												onChange={(e) =>
													rankSelect(1, 3)
												}
											/>
											<label htmlFor="openDate2" />
										</S.normalRadio>
										<S.normalLabel>3개월</S.normalLabel>
									</S.normalRadioArea>
									<S.blank1 />
									<S.table01>
										<thead>
											<tr>
												<th>순위</th>
												<th>회원번호</th>
												<th>회원닉네임</th>
												<th>오픈 횟수(회)</th>
											</tr>
										</thead>
										<tbody>
											{openRank && openRank.length > 0 ? (
												<>
													{openRank?.map(
														(item, index) => (
															<tr key={index}>
																<td>
																	{index + 1}
																</td>
																<td>
																	{item.mmIdx}
																</td>
																<td>
																	{item.nick}
																</td>
																<td>
																	{Util.addComma(
																		Number(
																			item.cnt ||
																				0
																		)
																	)}
																</td>
															</tr>
														)
													)}
												</>
											) : (
												<tr>
													<td
														colSpan={999}
														style={{
															textAlign: "center",
														}}>
														데이터가 없습니다.
													</td>
												</tr>
											)}
										</tbody>
									</S.table01>
								</div>
							</RankWrap>
						)}
						{/* 통계 영역 */}
						<S.statsArea>
							<p>
								결제 금액:
								<S.headerText>
									{Util.addComma(stateCnt)}
								</S.headerText>
								Rp
							</p>
						</S.statsArea>
						{/* 테이블 영역 */}
						<S.table01Wrap>
							<S.table01>
								<thead>
									<tr>
										<th>결제 번호</th>
										<th>회원번호</th>
										<th>닉네임</th>
										<th>구분</th>
										<th>유형</th>
										<th>금액(Rp)</th>
										<th>수량(개)</th>
										<th>결제 수단</th>
										<th>결제 일시</th>
										<th>영수증 번호</th>
									</tr>
								</thead>
								<tbody>
									{list && list.length > 0 ? (
										<>
											{list.map((item, i) => (
												<tr key={i}>
													<td>{item.ptIdx}</td>
													<td
														className="Clickable"
														onClick={() => {
															window.open(
																"/MEMB001002?mmIdx=" +
																	item.mmIdx,
																"_blank",
																"width=1000, height=800, left=100, top=100"
															);
														}}>
														{item.mmIdx}
													</td>
													<td>{item.nick}</td>
													<td>
														{item.paymentProgress}
													</td>
													<td>
														{item.type ?? "배송비"}
													</td>
													<td>
														{Util.addComma(
															Number(
																item.paymentCash
															)
														)}
													</td>
													<td>
														{item.quantity == "0"
															? "-"
															: item.quantity}
													</td>
													<td>{item.payMethod}</td>
													<td>
														{Util.dateFormat(
															item.regDt
														)}
													</td>
													<td>{item.tXid}</td>
												</tr>
											))}
										</>
									) : (
										<tr>
											<td
												colSpan={999}
												style={{ textAlign: "center" }}>
												데이터가 없습니다.
											</td>
										</tr>
									)}
								</tbody>
							</S.table01>
						</S.table01Wrap>
						{/* 페이지네이션 영역 */}
						<Pagination
							totalItems={totalItems} // 데이터 총 개수
							currentPage={
								page && parseInt(page) > 0 ? parseInt(page) : 1
							} // 현재 페이지
							itemCountPerPage={listSize} // 페이지당 보여줄 데이터 개수
						/>
					</div>
				}
			/>
		</>
	);
};
