// 자주 묻는 질문 등록/수정
import { useState, useEffect } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { LoginAtom } from "../Recoil/Atoms/PopupState";
import { UserAtom, LoadingAtom, MenuAtom } from "../Recoil/Atoms/UserState";

import * as S from "../Styles/Style";
import Api from "../libs/api";
import Util from "../Store/Util";

// 이미지
import { ReactComponent as Search } from "../Images/search.svg";

export default () => {
	const user = useRecoilValue(UserAtom);
	const menu = useRecoilValue(MenuAtom);
	let agmIdx: number;
	const setOpenLogin = useSetRecoilState<boolean>(LoginAtom);
	const setLoading = useSetRecoilState<boolean>(LoadingAtom);
	const [searchParams, setSearchParams] = useSearchParams();
	const bfIdx = searchParams.get("bfIdx"); // 부모창에서 받아온 보물번호

	const [categoryCode, setCategoryCode] = useState("");
	const [questionText, setQuestionText] = useState<string>("");
	const [answerText, setAnswerText] = useState<string>("");
	const [displayYn, setDisplayYn] = useState(1);
	const [orderNumber, setOrderNumber] = useState<number | "">("");

	useEffect(() => {
		user.isLogin && GetData();
	}, [user]);
	// 수정인 경우 FAQ 상세정보 가져오기
	const GetData = async () => {
		if (bfIdx == undefined) return false;
		setLoading(true);
		await Api.get(`board/faq/view/${bfIdx}`)
			.then((res: any) => {
				if (res.data == null) {
					const code = res.response.status;
					if (code == 401) {
						setOpenLogin(true);
					}
				} else {
					const data = res.data.data;
					console.log(res.data.data);
					setCategoryCode(data.categoryCode);
					setQuestionText(data.questionText);
					setAnswerText(data.answerText);
					setDisplayYn(data.displayYn);
					setOrderNumber(data.orderNumber);
				}
			})
			.catch((error) => {
				console.log(error.response.data, "wwwwww");
			});
		setLoading(false);
	};
	// 등록하기 버튼 클릭 이벤트
	const onSubmit = async (e: any) => {
		e.preventDefault();
		viewerCheck(async () => {
			let parms = {
				categoryCode: categoryCode,
				questionText: questionText,
				answerText: answerText,
				orderNumber: orderNumber,
				displayYn: displayYn.toString(),
				// openDt: ,
				bfIdx: bfIdx,
			};
			let result = (res: any) => {
				const data = res.data;
				if (data == undefined) {
					if (res.response.data.error == -400) {
						alert(`-400`);
					}
				} else if (data.statusCode == 200 || data.statusCode == 201) {
					alert("완료되었습니다.");
					window.opener.location.reload();
					window.close();
				}
			};
			console.log(displayYn);
			if (
				window.confirm(
					`해당 내용을 ${
						bfIdx == undefined ? "등록" : "수정"
					}하시겠습니까?`
				)
			) {
				setLoading(true);
				if (bfIdx == undefined) {
					await Api.post("board/faq/create", parms)
						.then((res: any) => result(res))
						.catch((error) => {});
				} else {
					await Api.patch("board/faq/update", parms)
						.then((res: any) => result(res))
						.catch((error) => {});
				}
				setLoading(false);
			}
		});
	};

	// 권한 확인(뷰어 제한) 후 액션
	const viewerCheck = (action: () => void) => {
		menu.map((m) => {
			m.sub.map((s) => {
				// 팝업의 뷰어체크는 부모창의 정보를 가져옴
				if (s.link == window.opener.location.pathname) {
					agmIdx = s.agmIdx;
				}
			});
		});
		Util.viewerCheck(user.grade, agmIdx, () => action());
	};

	return (
		<>
			<S.popupPage>
				<h1>
					<S.headerText>
						자주 묻는 질문 {bfIdx == undefined ? "등록" : "수정"}
					</S.headerText>
				</h1>
				{/* 테이블 영역 */}
				<form onSubmit={onSubmit}>
					<S.table02>
						<tbody>
							<tr>
								<th>등록일</th>
							</tr>
							<tr>
								<td colSpan={2}>
									<div>
										<input
											type="datetime-local"
											max={"9999-12-31T12:59"}
											name="displayDt"
											id="displayDt"
											value={Util.inputDateFormat(
												new Date()
											)}
											readOnly
										/>
									</div>
								</td>
							</tr>
							<tr>
								<th>분류*</th>
							</tr>
							<tr>
								<td colSpan={2}>
									<div>
										<select
											name="categoryCode"
											id="categoryCode"
											value={categoryCode}
											onChange={(e) =>
												setCategoryCode(e.target.value)
											}
											required>
											<option value="">= 선택 =</option>
											<option value="1">이용</option>
											<option value="2">결제</option>
											<option value="4">배송</option>
											<option value="7">회원</option>
											<option value="6">기타</option>
										</select>
									</div>
								</td>
							</tr>
							<tr>
								<th>제목*</th>
							</tr>
							<tr>
								<td colSpan={2}>
									<input
										type="text"
										name="questionText"
										id="questionText"
										value={questionText}
										onChange={(e) =>
											setQuestionText(e.target.value)
										}
										required
									/>
								</td>
							</tr>
							<tr>
								<th>내용*</th>
							</tr>
							<tr>
								<td colSpan={2}>
									<S.textAreaWrap>
										<textarea
											name="answerText"
											id="answerText"
											rows={5}
											value={answerText}
											onChange={(e) =>
												setAnswerText(e.target.value)
											}
											required></textarea>
									</S.textAreaWrap>
								</td>
							</tr>
							<tr>
								<th>노출 여부</th>
								<th>노출 순서</th>
							</tr>
							<tr>
								<td>
									<S.normalRadioArea>
										<S.normalRadio>
											<input
												type="radio"
												name="displayYn"
												id="displayYn1"
												checked={displayYn == 1}
												onChange={() => setDisplayYn(1)}
											/>
											<label htmlFor="displayYn1" />
										</S.normalRadio>
										<S.normalLabel htmlFor="displayYn1">
											노출
										</S.normalLabel>
										<S.normalRadio>
											<input
												type="radio"
												name="displayYn"
												id="displayYn0"
												checked={displayYn == 0}
												onChange={() => setDisplayYn(0)}
											/>
											<label htmlFor="displayYn0" />
										</S.normalRadio>
										<S.normalLabel htmlFor="displayYn0">
											미노출
										</S.normalLabel>
									</S.normalRadioArea>
								</td>
								<td>
									<input
										type="number"
										name="orderNumber"
										id="orderNumber"
										value={orderNumber}
										onChange={(e) =>
											setOrderNumber(
												Util.toNumber(e.target.value)
											)
										}
									/>
								</td>
							</tr>
						</tbody>
					</S.table02>
					<S.blank2 />
					<S.flexRowCenter>
						<S.filledButton type="submit">
							{bfIdx == undefined ? "등록" : "수정"}하기
						</S.filledButton>
					</S.flexRowCenter>
				</form>
			</S.popupPage>
		</>
	);
};
