// 배송 상세보기
import React, { useState, useEffect, useRef } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { useSetRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { LoginAtom } from "../Recoil/Atoms/PopupState";
import { UserAtom, LoadingAtom, MenuAtom } from "../Recoil/Atoms/UserState";

import * as S from "../Styles/Style";
import Api from "../libs/api";
import Util from "../Store/Util";

const DeliveryRadio = styled.div`
	button {
		color: ${S.color.red};
		border: 1px solid ${S.color.red};
		padding: 0.5rem 1rem;
		margin-right: 0.5rem;
		display: inline-block;
	}
	button:disabled {
		color: ${S.color.gray04};
		border-color: ${S.color.gray03};
		background-color: ${S.color.gray01};
	}
`;

interface deliveryInfo {
	address1: string;
	address2: string;
	buyProductPrice: number;
	delay: number;
	deliveryBuyUrl: string;
	deliveryCompanyCode: string; //
	deliveryMessage: string;
	deliveryPrice: number;
	deliveryStateCode: string;
	invoiceNumber: string; //
	mbohIdx: string;
	mmIdx: number;
	name: string;
	nick: string;
	paymentStateCode: string; //
	pdmIdx: string;
	pmIdx: string;
	recipientName: string;
	remark: string;
	tel: string;
	useCash: number;
	usePoint: number;
	regDt: string;
	tXid: string;
}
export default () => {
	const user = useRecoilValue(UserAtom);
	const setOpenLogin = useSetRecoilState<boolean>(LoginAtom);
	const setLoading = useSetRecoilState<boolean>(LoadingAtom);
	const imgUrl = process.env.REACT_APP_IMG_URL;
	const [searchParams, setSearchParams] = useSearchParams();
	const pdmIdx = searchParams.get("pdmIdx");
	const [deliveryInfo, setDeliveryInfo] = useState<deliveryInfo>();
	// 변경 가능한 값
	const [deliveryState, setDeliveryState] = useState<string>("");
	const [deliveryDelay, setDeliveyDelay] = useState<boolean>(false);
	const [deliveryCompanyCode, setDeliveryCompanyCode] = useState<string>("");
	const [invoiceNumber, setInvoiceNumber] = useState<string>("");
	const [deliveryPrice, setDeliveryPrice] = useState<string>("");
	const [buyProductPrice, setBuyProductPrice] = useState<string>("");
	const [deliveryBuyUrl, setDeliveryBuyUrl] = useState<string>("");
	const [remark, setRemark] = useState<string>("");
	useEffect(() => {
		user.isLogin && GetData();
	}, [user]);
	// 배송 상세 정보 불러오기
	const GetData = async () => {
		setLoading(true);
		if (pdmIdx == undefined) return false;
		await Api.get(`delivery/deliveryDetailSelect/${pdmIdx}`)
			.then((res: any) => {
				if (res.data == null) {
					const code = res.response.status;
					if (code == 401) {
						setOpenLogin(true);
					}
				} else {
					if (res.data.statusCode == 200) {
						const data = res.data.data;
						console.log(data);
						setDeliveyDelay(
							(data.deliveryStateCode == "00" ||
								data.deliveryStateCode == "10" ||
								data.deliveryStateCode == "20") &&
								Util.daysAgo(new Date(), 10) >
									data.regDt.substr(0, 8)
						);
						setDeliveryInfo(data);
						setDeliveryState(data.deliveryStateCode);
						setDeliveryCompanyCode(data.deliveryCompanyCode);
						setInvoiceNumber(data.invoiceNumber);
						setDeliveryPrice(Util.addComma(data.deliveryPrice));
						setBuyProductPrice(Util.addComma(data.buyProductPrice));
						setDeliveryBuyUrl(data.deliveryBuyUrl);
						setRemark(data.remark);
					}
				}
			})
			.catch((error) => {
				console.log(error.response.data, "wwwwww");
			});
		setLoading(false);
	};
	// 배송취소/배송불가 처리
	const deliveryOut = async (type: number) => {
		if (
			window.confirm(
				`배송${type == 1 ? "취소" : "불가"} 상태로 변경하시겠습니까?`
			)
		) {
			setLoading(true);
			if (pdmIdx == undefined) return false;
			console.log(type, deliveryInfo?.pdmIdx);
			await Api.patch(`delivery/deliveryOut`, {
				changeState: type,
				pdmIdx: deliveryInfo?.pdmIdx,
				mmIdx: deliveryInfo?.mmIdx,
			})
				.then((res: any) => {
					if (res.data == null) {
						const code = res.response.status;
						if (code == 401) {
							setOpenLogin(true);
						} else {
							alert(res.response.data.message);
						}
					} else {
						console.log(res.data.data);
						if (res.data.statusCode == 200) {
							window.opener.location.reload();
							window.location.reload();
						}
					}
				})
				.catch((error) => {
					console.log(error.response.data, "wwwwww");
				});
			setLoading(false);
		}
	};
	// 배송 저장
	const deliveryUpdate = async () => {
		if (window.confirm(`해당 내용을 저장하시겠습니까?`)) {
			setLoading(true);
			await Api.patch(`delivery/deliveryUpdate`, {
				deliveryStateCode:
					deliveryInfo?.deliveryStateCode == deliveryState
						? null
						: deliveryState,
				deliveryCompanyCode: deliveryCompanyCode,
				invoiceNumber: invoiceNumber,
				deliveryBuyUrl: deliveryBuyUrl,
				buyProductPrice: Util.onlyNum(buyProductPrice),
				deliveryPrice: Util.onlyNum(deliveryPrice),
				remark: remark,
				pdmIdx: pdmIdx,
			})
				.then((res: any) => {
					if (res.data == null) {
						const code = res.response.status;
						if (code == 401) {
							setOpenLogin(true);
						}
					} else {
						console.log(res.data.data);
						if (res.data.statusCode == 200) {
							alert("저장되었습니다.");
							window.opener.location.reload();
							window.location.reload();
						}
					}
				})
				.catch((error) => {
					console.log(error.response.data, "wwwwww");
				});
			setLoading(false);
		}
	};

	return (
		<>
			<S.popupPage>
				<h1>
					<S.headerText>배송 상세보기[{pdmIdx}]</S.headerText>
				</h1>
				<S.blank2 />
				{/* 배송취소, 배송불가 */}
				<DeliveryRadio>
					<button
						onClick={() => deliveryOut(1)}
						disabled={
							!(
								deliveryInfo?.deliveryStateCode == "10" ||
								deliveryInfo?.deliveryStateCode == "20"
							)
						}>
						배송취소
					</button>
					<button
						onClick={() => deliveryOut(2)}
						disabled={
							!(
								deliveryInfo?.deliveryStateCode == "10" ||
								deliveryInfo?.deliveryStateCode == "20"
							)
						}>
						배송불가
					</button>
				</DeliveryRadio>
				{/* 테이블 영역 */}
				<S.table03 style={{ marginTop: "1rem" }}>
					<tbody>
						<tr>
							<th>결제 상태</th>
							<td colSpan={3}>
								<S.normalRadioArea>
									<S.normalRadio>
										<input
											type="radio"
											name="paymentState"
											id="paymentState1"
											checked={
												deliveryInfo?.paymentStateCode ==
												"1"
											}
											readOnly
										/>
										<label htmlFor="" />
									</S.normalRadio>
									<S.normalLabel htmlFor="">
										결제진행중
									</S.normalLabel>
									<S.normalRadio>
										<input
											type="radio"
											name="paymentState"
											id="paymentState3"
											checked={
												deliveryInfo?.paymentStateCode ==
												"-2"
											}
											readOnly
										/>
										<label htmlFor="paymentState3" />
									</S.normalRadio>
									<S.normalLabel htmlFor="paymentState3">
										결제실패
									</S.normalLabel>
									<S.normalRadio>
										<input
											type="radio"
											name="paymentState"
											id="paymentState2"
											checked={
												deliveryInfo?.paymentStateCode ==
													"2" ||
												deliveryInfo?.paymentStateCode ==
													null
											}
											readOnly
										/>
										<label htmlFor="paymentState2" />
									</S.normalRadio>
									<S.normalLabel htmlFor="paymentState2">
										결제완료
									</S.normalLabel>
								</S.normalRadioArea>
							</td>
						</tr>
						<tr>
							<th>배송상태</th>
							<td colSpan={3}>
								<S.normalRadioArea>
									<S.normalRadio>
										<input
											type="radio"
											name="deliveryState"
											id="deliveryState1"
											checked={deliveryState == "-10"}
											readOnly
										/>
										<label htmlFor="" />
									</S.normalRadio>
									<S.normalLabel htmlFor="">
										배송취소
									</S.normalLabel>
									<S.normalRadio>
										<input
											type="radio"
											name="deliveryState"
											id="deliveryState3"
											checked={deliveryState == "00"}
											readOnly
										/>
										<label htmlFor="" />
									</S.normalRadio>
									<S.normalLabel htmlFor="">
										배송신청
									</S.normalLabel>
									<S.normalRadio>
										<input
											type="radio"
											name="deliveryState"
											id="deliveryState4"
											checked={deliveryState == "10"}
											onChange={() =>
												setDeliveryState("10")
											}
										/>
										<label htmlFor="deliveryState4" />
									</S.normalRadio>
									<S.normalLabel htmlFor="deliveryState4">
										배송준비
									</S.normalLabel>
									<S.normalRadio>
										<input
											type="radio"
											name="deliveryState"
											id="deliveryState5"
											checked={deliveryState == "20"}
											onChange={() =>
												setDeliveryState("20")
											}
										/>
										<label htmlFor="deliveryState5" />
									</S.normalRadio>
									<S.normalLabel htmlFor="deliveryState5">
										배송중
									</S.normalLabel>
									<S.normalRadio>
										<input
											type="radio"
											name="deliveryState"
											id="deliveryState6"
											checked={deliveryState == "30"}
											readOnly
										/>
										<label htmlFor="" />
									</S.normalRadio>
									<S.normalLabel htmlFor="">
										배송완료
									</S.normalLabel>
									<S.normalRadio>
										<input
											type="radio"
											name="deliveryState"
											id="deliveryState1"
											checked={deliveryState == "-11"}
											readOnly
										/>
										<label htmlFor="" />
									</S.normalRadio>
									<S.normalLabel htmlFor="">
										배송불가
									</S.normalLabel>
									{/* 배송지연 */}
									<S.normalRadio>
										<input
											type="checkbox"
											name="deliveryDelay"
											id="deliveryDelay"
											// TODO: 배송 신청으로부터 배송완료까지 10일 초과 시 배송 지연 체크박스 활성화
											checked={deliveryDelay}
											readOnly
										/>
										<label
											className="checkbox"
											htmlFor=""
										/>
									</S.normalRadio>
									<S.normalLabel htmlFor="">
										배송지연
									</S.normalLabel>
								</S.normalRadioArea>
							</td>
						</tr>
						<tr>
							<th>회원 정보</th>
							<td>
								[{deliveryInfo?.mmIdx}] {deliveryInfo?.nick}
							</td>
							<th>택배사</th>
							<td>
								<div>
									{/* <select name="" id="">
										<option value="">= 선택 =</option>
									</select> */}
									<input
										type="text"
										placeholder="택배사"
										value={deliveryCompanyCode}
										onChange={(e) =>
											setDeliveryCompanyCode(
												e.target.value
											)
										}
									/>
								</div>
							</td>
						</tr>
						<tr>
							<th>수령인 정보</th>
							<td>
								{deliveryInfo?.recipientName}ㅣ
								{deliveryInfo?.tel}
							</td>
							<th>송장 번호</th>
							<td>
								<div>
									<input
										type="text"
										name=""
										id=""
										placeholder="송장 번호"
										value={invoiceNumber}
										onChange={(e) =>
											setInvoiceNumber(e.target.value)
										}
									/>
								</div>
							</td>
						</tr>
						<tr>
							<th>배송지 정보</th>
							<td>
								{deliveryInfo?.address1}{" "}
								{deliveryInfo?.address2}
							</td>
						</tr>
						<tr>
							<th>배송 신청 사항</th>
							<td>{deliveryInfo?.deliveryMessage}</td>
						</tr>
					</tbody>
				</S.table03>
				<S.table03>
					<tbody>
						<tr>
							<th>결제 번호</th>
							<td colSpan={3}>{deliveryInfo?.tXid}</td>
						</tr>
						<tr>
							<th>배송비 결제</th>
							<td>
								포인트:{" "}
								{Util.addComma(deliveryInfo?.usePoint ?? 0)}P /
								결제:{" "}
								{Util.addComma(deliveryInfo?.useCash ?? 0)}Rp
							</td>
							<th>배송비</th>
							<td>
								<div>
									<input
										type="text"
										name=""
										id=""
										placeholder="0"
										value={deliveryPrice}
										onChange={(e) =>
											setDeliveryPrice(
												Util.onChangeComma(
													e.target.value
												)
											)
										}
									/>
									<span>Rp</span>
								</div>
							</td>
						</tr>
						<tr>
							<th>구입비</th>
							<td>
								<div>
									<input
										type="text"
										name=""
										id=""
										placeholder="0"
										value={buyProductPrice}
										onChange={(e) =>
											setBuyProductPrice(
												Util.onChangeComma(
													e.target.value
												)
											)
										}
									/>
									<span>Rp</span>
								</div>
							</td>
							<th>구입처</th>
							<td>
								<div>
									<input
										type="text"
										name=""
										id=""
										placeholder="구입처"
										value={deliveryBuyUrl}
										onChange={(e) =>
											setDeliveryBuyUrl(e.target.value)
										}
									/>
								</div>
							</td>
						</tr>
					</tbody>
				</S.table03>
				<S.table03>
					<tbody>
						<tr>
							<th>보물 정보</th>
							<td className="wrap">
								[{deliveryInfo?.mbohIdx}ㅣ{deliveryInfo?.pmIdx}]
								{"\n"}
								{deliveryInfo?.name}
							</td>
						</tr>
					</tbody>
				</S.table03>
				<S.table03>
					<tbody>
						<tr>
							<th className="wrap">
								비고{"\n"}*관리자만 확인 가능
							</th>
							<td>
								<div>
									<textarea
										name=""
										id=""
										cols={30}
										rows={7}
										value={remark}
										onChange={(e) =>
											setRemark(e.target.value)
										}></textarea>
								</div>
							</td>
						</tr>
					</tbody>
				</S.table03>
				<S.blank2 />
				<S.flexRowCenter>
					<S.filledButton onClick={() => deliveryUpdate()}>
						배송 저장
					</S.filledButton>
				</S.flexRowCenter>
			</S.popupPage>
		</>
	);
};
