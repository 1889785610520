// 회원 상세정보 이력
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useSearchParams, useLocation } from "react-router-dom";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { UserAtom, LoadingAtom, MenuAtom } from "../Recoil/Atoms/UserState";

import * as S from "../Styles/Style";
import Api from "../libs/api";
import Util from "../Store/Util";
import Pagination from "../Components/Pagination";

// 이미지
import retate from "../Images/rotate.png";

interface listData {
	mmIdx: string;
	// 닉네임 변경 이력
	afterNick: string;
	beforeNick: string;
	regDt: string;
	// 접속 이력
	mlhIdx: string;
	loginIp: string;
	loginDt: string;
	// 제재 이력
	mshIdx: string;
	stateCode: string;
	sanctionStDt: string;
	sanctionEndDt: string;
	reason: string;
}

export default () => {
	const user = useRecoilValue(UserAtom);
	const [searchParams, setSearchParams] = useSearchParams();
	const parmsMmIdx = searchParams.get("mmIdx"); // 부모창에서 받아온 회원번호
	const parmsNick = searchParams.get("nick"); // 부모창에서 받아온 닉네임
	const parmsType = searchParams.get("type"); // 부모창에서 받아온 구분값
	const page = searchParams.get("page");

	const mmIdx = useRef<string>("");
	const nick = useRef<string>("");
	const type = useRef<string>("");

	const now = new Date(Util.getNow());
	// 검색 옵션(1개월 기본)
	const searchOptionsRef = useRef<{}>({
		searchStrDt: Util.daysAgo(now, 30),
		searchEndDt: Util.getNowString().slice(0, 8),
	});
	const [searchOptions, setSearchOptions] = useState({});
	// 검색 일자
	const [searchStrDt, setSearchStrDt] = useState<string>("");
	const [searchEndDt, setSearchEndDt] = useState<string>(
		Util.inputDateFormat(now, "date")
	);
	// 타입 정보
	const [activeData, setActiveData] = useState<{
		type: string;
		text: string;
		searchType: number;
		thList: string[];
		url: string;
	}>();
	const [listData, setListData] = useState<listData[]>();
	const listSize = 20;
	const [totalItems, setTotalItems] = useState<number>(0);
	const tabData = [
		{
			type: "0",
			text: "닉네임 변경",
			searchType: 0,
			thList: ["변경 닉네임", "이전 닉네임", "변경 일시"],
			url: "member/memberNickChangeHistory",
		},
		{
			type: "1",
			text: "접속",
			searchType: 1,
			thList: ["번호", "접속 IP", "접속일시"],
			url: "member/memberLoginHistory",
		},
		{
			type: "2",
			text: "제재",
			searchType: 0,
			thList: ["번호", "제재 종류", "제재 기간", "제재 사유"],
			url: "member/memberSanctionList",
		},
	];
	useEffect(() => {
		if (parmsMmIdx) {
			mmIdx.current = parmsMmIdx;
		}
		if (parmsNick) {
			nick.current = parmsNick;
		}
		if (parmsType) {
			type.current = parmsType;
		}
		let date = Util.dateFormat(Util.daysAgo(now, 30) + "000000000");
		setSearchStrDt(Util.inputDateFormat(new Date(date), "date"));
		setActiveData(tabData.filter((item) => item.type === type.current)[0]);
	}, []);
	// 1페이지로 이동되면 리셋
	const pageResset = () => {
		if (page == "1") {
			user.isLogin && GetList();
		} else {
			setSearchParams({ page: "1" });
		}
	};
	useEffect(() => {
		window.scrollTo(0, 0); // 페이지 이동 시 스크롤 위치 맨 위로 초기화
		user.isLogin && GetList();
		console.log("totalItems : ", totalItems);
	}, [page, user]);
	// 이력 정보 가져오기
	const GetList = async () => {
		console.log(searchOptionsRef.current);
		await Api.get(`${activeData?.url}`, {
			...searchOptionsRef.current,
			mmIdx: mmIdx.current,
			page: page,
			listSize: listSize,
		}).then((res: any) => {
			if (res.data) {
				console.log(res.data.data);
				setListData(res.data.data.data);
				setTotalItems(res.data.data.totalCnt);
			}
		});
	};

	// 상태
	const stateText = (state: string | null) => {
		switch (state) {
			case "-10":
				return "임시 제한";
			case "-30":
				return "영구 제한";
			default:
				return "-";
		}
	};
	// 닉네임 초기화
	const nickReset = async () => {
		if (
			window.confirm(
				`${nick.current} [${mmIdx.current}] 님의 닉네임을 초기화 하시겠습니까?`
			)
		) {
			await Api.patch(`member/memberNickReset/${mmIdx.current}`)
				.then((res) => {
					console.log(res);
					alert("초기화되었습니다.");
					window.location.reload();
				})
				.catch((error) => console.log(error));
		}
	};
	// 공통
	// 일자
	const setDate = (value: any) => {
		const year = now.getFullYear(); // 년
		const month = now.getMonth(); // 월
		const day = now.getDate(); // 일

		if (value == "") {
			setSearchStrDt("");
		} else {
			const start = new Date(
				year,
				month,
				day - value
			).toLocaleDateString();
			const startDt = new Date(start);

			setSearchStrDt(Util.inputDateFormat(startDt, "date"));
		}
		setSearchEndDt(Util.inputDateFormat(now, "date"));
	};
	useEffect(() => {
		setSearchOptions({
			...searchOptions,
			searchStrDt: Util.onlyNum(searchStrDt),
			searchEndDt: Util.onlyNum(searchEndDt),
		});
	}, [searchStrDt, searchEndDt]);
	// 검색
	const search = () => {
		searchOptionsRef.current = {
			...searchOptionsRef.current,
			...searchOptions,
		};
		pageResset();
	};
	return (
		<S.popupPage>
			<h1>
				<S.headerText>
					{`${nick.current}(${mmIdx.current}) ${activeData?.text}`}{" "}
					이력
				</S.headerText>
			</h1>
			<S.blank3 />
			{/* 검색 영역 */}
			{activeData?.searchType == 1 && (
				<S.searchArea>
					<S.searchRow>
						<input
							type="date"
							max={"9999-12-31"}
							name="searchStrDt"
							id="searchStrDt"
							value={searchStrDt}
							onChange={(e) => {
								setSearchStrDt(e.target.value);
							}}
						/>
						<p> - </p>
						<input
							type="date"
							max={"9999-12-31"}
							name="searchEndDt"
							id="searchEndDt"
							value={searchEndDt}
							onChange={(e) => {
								setSearchEndDt(e.target.value);
							}}
						/>
						<input
							name="searchDtRadio"
							type="radio"
							id="searchDtRadio1"
							value={""}
							onClick={(event) =>
								setDate(
									(event.target as HTMLInputElement).value
								)
							}
						/>
						<label htmlFor="searchDtRadio1">전체</label>
						<input
							name="searchDtRadio"
							type="radio"
							id="searchDtRadio2"
							value={7}
							onClick={(event) =>
								setDate(
									(event.target as HTMLInputElement).value
								)
							}
						/>
						<label htmlFor="searchDtRadio2">1주일</label>
						<input
							name="searchDtRadio"
							type="radio"
							id="searchDtRadio3"
							value={30}
							defaultChecked
							onClick={(event) =>
								setDate(
									(event.target as HTMLInputElement).value
								)
							}
						/>
						<label htmlFor="searchDtRadio3">1개월</label>
						<input
							name="searchDtRadio"
							type="radio"
							id="searchDtRadio4"
							value={90}
							onClick={(event) =>
								setDate(
									(event.target as HTMLInputElement).value
								)
							}
						/>
						<label htmlFor="searchDtRadio4">3개월</label>
						<S.searchButton onClick={() => search()}>
							<S.headerText>검색하기</S.headerText>
						</S.searchButton>
						<S.searchButton
							onClick={() => {}}
							color={S.color.primaryVariant}>
							<S.headerText>엑셀 다운로드</S.headerText>
						</S.searchButton>
					</S.searchRow>
				</S.searchArea>
			)}
			{/* 버튼 영역 */}
			{activeData?.type == "0" && (
				<S.asideButtons>
					<button onClick={() => nickReset()}>닉네임 초기화</button>
				</S.asideButtons>
			)}
			{/* 테이블 영역 */}
			<S.table01>
				<thead>
					<tr>
						{activeData?.thList?.map((item, index) => {
							return <th key={index}>{item}</th>;
						})}
					</tr>
				</thead>
				<tbody>
					{listData && listData.length > 0 ? (
						<>
							{listData?.map((item, index) => {
								return (
									<React.Fragment key={index}>
										{type.current === "0" && (
											<tr>
												<td>{item?.afterNick}</td>
												<td>{item?.beforeNick}</td>
												<td>
													{Util.dateFormat(
														item?.regDt
													)}
												</td>
											</tr>
										)}
										{type.current === "1" && (
											<tr>
												<td>{item?.mlhIdx}</td>
												<td>{item?.loginIp}</td>
												<td>
													{Util.dateFormat(
														item?.loginDt
													)}
												</td>
											</tr>
										)}
										{type.current === "2" && (
											<tr>
												<td>{item.mshIdx}</td>
												<td>
													{stateText(item.stateCode)}
												</td>
												<td>
													{Util.dateFormat(
														item.sanctionStDt
													)}{" "}
													~{" "}
													{Util.dateFormat(
														item.sanctionEndDt
													)}
												</td>
												<td>{item.reason}</td>
											</tr>
										)}
									</React.Fragment>
								);
							})}
						</>
					) : (
						<tr>
							<td colSpan={999} style={{ textAlign: "center" }}>
								데이터가 없습니다.
							</td>
						</tr>
					)}
				</tbody>
			</S.table01>
			{/* 페이지네이션 영역 */}
			<Pagination
				totalItems={totalItems} // 데이터 총 개수
				currentPage={page && parseInt(page) > 0 ? parseInt(page) : 1} // 현재 페이지
				itemCountPerPage={listSize} // 페이지당 보여줄 데이터 개수
			/>
		</S.popupPage>
	);
};
