// 제재하기
import { useState, useEffect } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { LoginAtom } from "../Recoil/Atoms/PopupState";
import { UserAtom, LoadingAtom } from "../Recoil/Atoms/UserState";

import * as S from "../Styles/Style";
import Api from "../libs/api";
import Util from "../Store/Util";

// 이미지
import { ReactComponent as Search } from "../Images/search.svg";

export default () => {
	const setLoading = useSetRecoilState<boolean>(LoadingAtom);
	const [submitMmIdx, setSubmitMmIdx] = useState<string>("");
	const [submitNick, setSubmitNick] = useState<string>("");
	const [submitReason, setSubmitReason] = useState<string>("");
	const [sanctionType, setSanctionType] = useState<string>();
	const [sanctionEndDt, setSanctionEndDt] = useState<string>();

	// 입력한 데이터 업데이트
	const handleSubmitData = (name: string, value: string) => {
		// 회원번호/닉네임
		if (value == "") {
			setSubmitMmIdx("");
			setSubmitNick("");
		} else {
			if (name === "mmIdx") {
				setSubmitMmIdx(value);
			} else if (name === "nick") {
				setSubmitNick(value);
			} else {
			}
		}
	};
	// 회원번호/닉네임 으로 정보 가져오기
	const getMemberInfo = async (type: string) => {
		const division = type === "mmIdx" ? submitMmIdx : submitNick;
		if (division == undefined || division == "") return;
		setLoading(true);
		await Api.get("member/select", {
			[type]: division,
		})
			.then((res: any) => {
				console.log(res);
				const datas = res.data.data[0];
				if (datas == undefined) {
					alert("해당 내용의 검색 결과가 없습니다.");
				} else {
					setSubmitMmIdx(datas.mmIdx);
					setSubmitNick(datas.nick);
				}
			})
			.catch((error) => {
				alert("해당 내용의 검색 결과가 없습니다.");
			});
		setLoading(false);
	};

	// 등록하기 버튼 클릭 이벤트
	const onSubmit = async (e: any) => {
		e.preventDefault();
		// 제재 종류 미선택
		console.log(sanctionType);
		if (sanctionType === undefined) {
			alert("필수 항목을 모두 입력해 주세요.");
			return;
		}
		// 제재 종류 구분
		const sanctionTypeText = () => {
			switch (sanctionType) {
				case "-10":
					return "임시 제한";
				case "-30":
					return "영구 제한";
			}
		};
		if (
			window.confirm(
				`${submitMmIdx} ${submitNick}의 계정을 ${sanctionTypeText()} 하시겠습니까?`
			)
		) {
			setLoading(true);

			await Api.patch("member/memberSanction", {
				mmIdx: submitMmIdx,
				sanction: sanctionType,
				// sanctionStDt: , // 현재시간으로 자동 저장
				sanctionEndDt: Util.onlyNum(sanctionEndDt ?? ""),
				reason: submitReason,
			})
				.then((res: any) => {
					const data = res.data;
					console.log(res);
					if (data == undefined) {
						if (res.response.data.error == -400) {
							alert(`-400`);
						}
					} else if (
						data.statusCode == 200 ||
						data.statusCode == 201
					) {
						alert("완료되었습니다.");
						window.opener.location.reload();
						window.close();
					}
				})
				.catch((error) => {});
			setLoading(false);
		}
	};

	return (
		<>
			<S.popupPage>
				<h1>
					<S.headerText>제재하기</S.headerText>
				</h1>
				{/* 테이블 영역 */}
				<form
					onSubmit={onSubmit}
					style={{
						flexGrow: 1,
						display: "flex",
						flexDirection: "column",
					}}>
					<div style={{ flexGrow: 1 }}>
						<S.table03>
							<tbody>
								<tr>
									<th>회원번호*</th>
									<td>
										<div>
											<input
												type="text"
												value={
													Util.toNumber(
														String(submitMmIdx)
													) ?? ""
												}
												placeholder="회원번호"
												onFocus={() => {
													setSubmitMmIdx("");
													setSubmitNick("");
												}}
												onBlur={(e) =>
													getMemberInfo("mmIdx")
												}
												onChange={(e) =>
													handleSubmitData(
														"mmIdx",
														e.target.value
													)
												}
												required
											/>
											<Search fill={S.color.gray03} />
										</div>
									</td>
									<th>회원 닉네임*</th>
									<td>
										<div>
											<input
												type="text"
												value={submitNick}
												placeholder="회원 닉네임"
												onFocus={() => {
													setSubmitMmIdx("");
													setSubmitNick("");
												}}
												onBlur={(e) =>
													getMemberInfo("nick")
												}
												onChange={(e) =>
													handleSubmitData(
														"nick",
														e.target.value
													)
												}
												required
											/>
											<Search
												fill={S.color.gray03}
												onClick={(e) =>
													getMemberInfo("nick")
												}
											/>
										</div>
									</td>
								</tr>
								<tr>
									<th>제재 종류*</th>
									<td colSpan={3}>
										<S.normalRadioArea>
											<S.normalRadio>
												<input
													type="radio"
													name="displayYn"
													id="displayYn2"
													value={"-10"}
													checked={
														sanctionType === "-10"
													}
													onChange={(e) =>
														setSanctionType(
															e.target.value
														)
													}
												/>
												<label htmlFor="displayYn2" />
											</S.normalRadio>
											<S.normalLabel htmlFor="displayYn2">
												임시 제한
											</S.normalLabel>
											<S.bodyText color={S.color.gray03}>
												~{"  "}
											</S.bodyText>
											<input
												type="date"
												max={"9999-12-31"}
												name="displayDt"
												id="displayDt"
												value={
													sanctionEndDt
														? Util.inputDateFormat(
																new Date(
																	sanctionEndDt
																),
																"date"
														  )
														: ""
												}
												onChange={(e) => {
													setSanctionEndDt(
														e.target.value
													);
												}}
												style={{ flexGrow: 0 }}
												className="radioInput"
												required={true}
												readOnly={false}
												disabled={
													sanctionType !== "-10"
												}
											/>
											<S.normalRadio>
												<input
													type="radio"
													name="displayYn"
													id="displayYn0"
													checked={
														sanctionType === "-30"
													}
													onChange={() =>
														setSanctionType("-30")
													}
												/>
												<label htmlFor="displayYn0" />
											</S.normalRadio>
											<S.normalLabel htmlFor="displayYn0">
												영구 제한
											</S.normalLabel>
										</S.normalRadioArea>
									</td>
								</tr>
								<tr>
									<th>사유</th>
									<td colSpan={3}>
										<div>
											<textarea
												name=""
												id=""
												cols={30}
												rows={2}
												placeholder="제재 사유를 입력해 주세요.
														(관리자만 확인 가능)"
												value={submitReason ?? ""}
												maxLength={100}
												onChange={(e) =>
													setSubmitReason(
														e.target.value
													)
												}></textarea>
										</div>
									</td>
								</tr>
							</tbody>
						</S.table03>
					</div>
					<S.blank2 />
					<S.flexRowCenter>
						<S.filledButton type="submit">제재하기</S.filledButton>
					</S.flexRowCenter>
				</form>
			</S.popupPage>
		</>
	);
};
